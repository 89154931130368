import "../styles.css";

// Retrieve instructionList from vmscript.js
var instructionList = require("./vmscript").instructionList;

///////////////////////////////////
// Helpers

function formatUnicorn(format) {
  var str = format.toString();
  if (arguments.length) {
    var t = typeof arguments[0];
    var key;
    var args =
      "string" === t || "number" === t
        ? Array.prototype.slice.call(arguments)
        : arguments[0];

    for (key in args) {
      str = str.replace(new RegExp("\\{" + key + "\\}", "gi"), args[key]);
    }
  }

  return str;
}

function scrollToBottom(containerDiv) {
  containerDiv.scrollTop =
    containerDiv.scrollHeight - containerDiv.clientHeight;
}

function appendstringtolog(newMessage) {
  var logContainer = document.getElementById("eventLog__container");

  var newLog = document.createElement("p");
  newLog.innerHTML = newMessage;
  newLog.className = "eventLog__event";
  logContainer.appendChild(newLog);

  // Make the overall container visible; it's initially hidden
  var parent_column = document.getElementById("eventLog__root");
  parent_column.style.visibility="visible";
  
  scrollToBottom(logContainer);
  //  logContainer.scrollTop = logContainer.scrollHeight - logContainer.clientHeight;
}

//////////////////////////////////
// VM
//
// Print single message
// Nonblocking wait
// Print multiple lines without having to connect one to another

var gCurrVMLine = 0;
var gCurrVMFactDB = "";
var gCurrVMFactDBTimestamp = 0; // Updated every time gCurrVMFactDB changes
var gCurrVMSleepMS = 0; // Remaining sleep time
var gDbgVMFreezeCountdown = 0; // Should be 0
var gDbgButtonLogicVerbose = false;
var gDbgShowButtonStateChange = false; // Should be false
var gGameTimeSec = 0;

var gLastChapterTime = 0;
var gChapterTimeLog = "";
var gSessionId = "";

var gCurrVMVar = require("./vmscript").defaultVar;
var gCurrVMVarDefault = require("./vmscript").defaultVar;
var gCurrVMVarTimestamp = 0; // Updated every time gCurrVMVar changes

var gDbgVMVerbose = false; // Verbose logging; default is false
var gDbgEnable = (process.env.NODE_ENV === 'development'); // Enable debugging; default is true

function getVMTickRateMS() {
  return 200;
}

function isFactTrue(factDB, fact) {
  if (fact === undefined) {
    alert("Insufficient parameters to isFactTrue!" + Error().stack);
  }
  return factDB.search(":" + fact + ":") >= 0 ? true : false;
}
function isFactFalse(factDB, fact) {
  return !isFactTrue(factDB, fact);
}

// factListText is a list of one or more space-separated strings.
//  Return true only if all facts are true.
function isFactListAllTrue(factDB, factListText) {
  var factListTextTrim = factListText.trim();
  var all_facts_true = true;
  //console.log("factListTextTrim:" + factListTextTrim);
  if (factListTextTrim.length === 0) {
    // All true
  } else if (factListTextTrim.indexOf(" ") < 0) {
    // Single item
    //console.log("factListTextTrim:" + factListTextTrim + " " + isFactTrue(gCurrVMFactDB, factListTextTrim) + " " + gCurrVMFactDB);
    all_facts_true = isFactTrue(gCurrVMFactDB, factListTextTrim);
  } else {
    // Multiple items
    var factList = factListTextTrim.split(" ");
    for (var i = 0; i < factList.length; i++) {
      all_facts_true = all_facts_true && isFactTrue(gCurrVMFactDB, factList[i]);
    }
  }
  return all_facts_true;
}

function setFact(factDB, fact) {
  //console.log("factDB before: " + factDB + " newfact: " +fact);
  if (fact.length > 0) {
    if (isFactFalse(factDB, fact)) {
      factDB += ":" + fact + ":";
    }
  }
  //console.log("factDB after: " + factDB);
  return factDB;
}

function clearFact(factDB, fact) {
  if (fact.length > 0) {
    var queryText = ":" + fact + ":";
    var queryIndex = factDB.search(queryText);
    if (queryIndex >= 0) {
      // Remove it
      var newFactDb =
        factDB.substring(0, queryIndex) +
        factDB.substring(queryIndex + queryText.length, factDB.length);
      factDB = newFactDb;
    }
  }
  return factDB;
}

function findLabelLine(label) {
  for (var i = 0; i < instructionList.length; i++)
    if (instructionList[i].label === label) return i;
  return -1;
}

function setGlobalFactDB(newFactDB) {
  if (gCurrVMFactDB !== newFactDB) {
    gCurrVMFactDBTimestamp++;
    gCurrVMFactDB = newFactDB;
    if (gDbgVMVerbose) {
      console.log(" gCurrVMFactDB updated.  '" + gCurrVMFactDB + "'");
	}
  }
}

function executeVM() {
  if (gCurrVMSleepMS >= 0)
    gCurrVMSleepMS = Math.max(0, gCurrVMSleepMS - getVMTickRateMS());
  if (gCurrVMSleepMS > 0) {
    if (gDbgVMVerbose)
      console.log(
        " executeVM sleeping; gCurrVMSleepMS: '" + gCurrVMSleepMS + "'"
      );
    return;
  }

  // BEGIN DEV
  if (gDbgEnable) {
    if (gDbgVMFreezeCountdown < 0) {
      if (gDbgVMVerbose)
        console.log(
          " executeVM quitting early because gDbgVMFreezeCountdown is negative: '" +
            gDbgVMFreezeCountdown +
            "'"
        );
      return;
    }
    if (gDbgVMFreezeCountdown > 0) gDbgVMFreezeCountdown--;
  }
  // END DEV

  if (gDbgVMVerbose)
    console.log(
      " executeVM start.  Line:" +
        gCurrVMLine +
        "  gCurrVMFactDB: '" +
        gCurrVMFactDB +
        "'"
    );
  var currVMLine = gCurrVMLine + 1;
  var currFactDB = gCurrVMFactDB;

  var currInstruction = instructionList[gCurrVMLine];

  // iffactfalse branch
  if (currInstruction.iffactfalse !== undefined) {
    if (gDbgVMVerbose)
      console.log(
        "  iffactfalse:  " +
          currInstruction.iffactfalse +
          " branch: " +
          currInstruction.branch
      );

    if (isFactFalse(currFactDB, currInstruction.iffactfalse)) {
      currVMLine = findLabelLine(currInstruction.branch);
      if (gDbgVMVerbose)
        console.log("  Branch taken, currVMLine: " + currVMLine);
    } else {
      if (gDbgVMVerbose)
        console.log("Branch NOT taken, currVMLine: " + currVMLine);
    }
  }

  // iffacttrue
  if (currInstruction.iffacttrue !== undefined) {
    if (gDbgVMVerbose)
      console.log(
        "  iffacttrue:  " +
          currInstruction.iffacttrue +
          " branch: " +
          currInstruction.branch
      );

    if (isFactTrue(currFactDB, currInstruction.iffacttrue)) {
      currVMLine = findLabelLine(currInstruction.branch);
      if (gDbgVMVerbose)
        console.log("  Branch taken, currVMLine: " + currVMLine);
    } else {
      if (gDbgVMVerbose)
        console.log("Branch NOT taken, currVMLine: " + currVMLine);
    }
  }

  // waituntilfacttrue case
  if (currInstruction.waituntilfacttrue !== undefined) {
    if (gDbgVMVerbose)
      console.log(
        "  waituntilfacttrue:  '" + currInstruction.waituntilfacttrue + "'"
      );
    if (
      isFactListAllTrue(currFactDB, currInstruction.waituntilfacttrue) === false
    ) {
      currVMLine = gCurrVMLine;
      if (gDbgVMVerbose)
        console.log("  Fact not set, currVMLine: " + currVMLine);
    }
  }

  // goto
  if (currInstruction.goto !== undefined) {
    if (gDbgVMVerbose) console.log("  goto:  " + currInstruction.goto);
    currVMLine = findLabelLine(currInstruction.goto);
    if (gDbgVMVerbose) console.log("  Branch taken, currVMLine: " + currVMLine);
  }

  // logtext
  if (currInstruction.logtext !== undefined) {
    if (gDbgVMVerbose) console.log("  logtext:  " + currInstruction.logtext);
    appendstringtolog(currInstruction.logtext);
    if (currInstruction.delay !== undefined)
      gCurrVMSleepMS += currInstruction.delay;
  }

  // label
  if (currInstruction.label !== undefined) {
    if (gDbgVMVerbose) console.log("  label:  " + currInstruction.label);
  }

  // showbutton
  if (currInstruction.showbutton !== undefined) {
    if (gDbgVMVerbose)
      console.log("  showbutton:  " + currInstruction.showbutton);
    // NYI
  }

  // setfact
  if (currInstruction.setfact !== undefined) {
    if (gDbgVMVerbose) console.log("  setfact:  " + currInstruction.setfact);
    currFactDB = setFact(currFactDB, currInstruction.setfact);
    if (gDbgVMVerbose) console.log("  currFactDB:  '" + currFactDB + "'");
  }

  // clearfact
  if (currInstruction.clearfact !== undefined) {
    if (gDbgVMVerbose)
      console.log("  clearfact:  " + currInstruction.clearfact);
    currFactDB = clearFact(currFactDB, currInstruction.clearfact);
    if (gDbgVMVerbose) console.log("  currFactDB:  '" + currFactDB + "'");
  }

  // setvar
  if (currInstruction.setVar !== undefined) {
    if (gDbgVMVerbose)
      console.log(
        "  setVar:  '" +
          currInstruction.setVar +
          "' value='" +
          currInstruction.value +
          "'"
      );
    gCurrVMVar[currInstruction.setVar] = currInstruction.value;
    gCurrVMVarTimestamp++;
    if (gDbgVMVerbose) console.log("  gCurrVMVar:  '" + gCurrVMVar + "'");
  }

  // ifvarnz
  if (currInstruction.ifvarnz !== undefined) {
    if (gDbgVMVerbose)
      console.log(
        "  ifvarnz:  '" +
          currInstruction.ifvarnz +
          "' branch='" +
          currInstruction.branch +
          "'"
      );
    if (gCurrVMVar[currInstruction.ifvarnz] !== 0) {
      currVMLine = findLabelLine(currInstruction.goto);
      if (gDbgVMVerbose)
        console.log("  Branch taken, currVMLine: " + currVMLine);
    }
  }

  // whilevarle; while var <=
  if (currInstruction.whilevarle !== undefined) {
    if (gDbgVMVerbose)
      console.log(
        "  whilevarle:  '" +
          currInstruction.whilevarle +
          "' value='" +
          currInstruction.value +
          "'"
      );
    if (gCurrVMVar[currInstruction.whilevarle] <= currInstruction.value) {
      currVMLine = gCurrVMLine;
      if (gDbgVMVerbose)
        console.log("  Condition true, currVMLine: " + currVMLine);
    }
  }
  // whilevarge; while var >=
  if (currInstruction.whilevarge !== undefined) {
    if (gDbgVMVerbose)
      console.log(
        "  whilevarge:  '" +
          currInstruction.whilevarge +
          "' value='" +
          currInstruction.value +
          "'"
      );
    if (gCurrVMVar[currInstruction.whilevarge] >= currInstruction.value) {
      currVMLine = gCurrVMLine;
      if (gDbgVMVerbose)
        console.log("  Condition true, currVMLine: " + currVMLine);
    }
  }

  // setbg
  if (currInstruction.setbg !== undefined) {
    if (gDbgVMVerbose) console.log("  setbg:  " + currInstruction.setbg);
    //document.documentElement.style.background
    document.body.style.backgroundImage = "url(" + currInstruction.setbg + ")"; //  no-repeat center center fixed";// background/4End.jpg
    //			document.documentElement.style.backgroundSize ="cover";

    if (currInstruction.delay !== undefined)
      gCurrVMSleepMS += currInstruction.delay;
    if (gDbgVMVerbose) console.log("SetBG = " + currInstruction.setbg);
  }

  // initializeConvert
  if (currInstruction.initializeConvert !== undefined) {
    var convertPrefix = currInstruction.initializeConvert;
    var initialValue = gCurrVMVar[convertPrefix + "InitialValue"];
    var baseTime = gCurrVMVar[convertPrefix + "BaseTime"];
    var cookieDelta = gCurrVMVar[convertPrefix + "Cookies"];
    gCurrVMVar["convertPrefix"] = convertPrefix;
    gCurrVMVar["numConvert"] = initialValue;
    gCurrVMVar["numConvertPerSecond"] = 0.0;
    gCurrVMVar["convertBaseRate"] = initialValue / baseTime;
    gCurrVMVar["cookieDeltaScale"] = Math.floor(cookieDelta / initialValue);
    gCurrVMVar["convertCount"] = 0.0;
    gCurrVMVarTimestamp++;

    //console.log("convertPrefix:"+convertPrefix);
    //console.log("initialValue:"+initialValue);
    //console.log("baseTime:"+baseTime);
  }

  // logChapterTime
  if (currInstruction.logChapterTime !== undefined) {
    if (gDbgVMVerbose) console.log("  logChapterTime  ");
    if (gLastChapterTime != 0) {
      var timeDiff = gGameTimeSec - gLastChapterTime;
      gChapterTimeLog += formatUnicorn(
        " {1}: {2},",
        currInstruction.logChapterTime,
        Math.floor(timeDiff)
      );
	  if (typeof firebase !== 'undefined') {
		firebase.database().ref('session/' + gSessionId).set(gChapterTimeLog + formatUnicorn(" TOTAL: {1}", Math.floor(gGameTimeSec)));
	  }
    }
    gLastChapterTime = gGameTimeSec;
  }

  // clearsave
  if (currInstruction.clearsave !== undefined) {
    if (gDbgVMVerbose) console.log("  clearsave.  ");
	clearGameFromLocalStorage();
  }

  // stop
  if (currInstruction.stop !== undefined) {
    if (gDbgVMVerbose) console.log("  stop.  ");
    currVMLine = gCurrVMLine;
  }
	
  // DEVELOPMENT INSTRUCTIONS
  if (gDbgEnable) {
    // Enable/disable gDbgVMVerbose
    if (currInstruction.setVMVerbose !== undefined) {
      console.log("Setting gDbgVMVerbose to:" + currInstruction.setVMVerbose);
      // DEVHACK gDbgVMVerbose = currInstruction.setVMVerbose;
    }
  
    // Print full state
    if (currInstruction.dumpState !== undefined) {
      var var_msg = "";
      for (var key in gCurrVMVar) {
        var_msg += " " + key + "=" + gCurrVMVar[key] + " ";
      }
  
      var time_sec = gGameTimeSec % 60;
      var time_min = Math.floor(gGameTimeSec / 60) % 60;
      var time_hour = Math.floor(gGameTimeSec / 3600);
      console.log(currInstruction.dumpState);
      console.log(
        formatUnicorn(
          "Writing state at line {1} and time {2}",
          currVMLine,
          gGameTimeSec
        )
      );
      console.log(
        formatUnicorn("Time is {1}h {2}m {3}s", time_hour, time_min, time_sec)
      );
      console.log(currFactDB); // Fact db is a string
      console.log(var_msg);
    }
  }
  
  // Complete!  Update state
  gCurrVMLine = currVMLine;
  setGlobalFactDB(currFactDB);
  if (gDbgVMVerbose)
    console.log(
      "     executeVM end.  Line:" +
        gCurrVMLine +
        "  gCurrVMFactDB: '" +
        gCurrVMFactDB +
        "'"
    );
}

function getLaneExp(lanePower, laneScale, laneCount) {
  //console.log("lanePower: " + lanePower);
  return Math.ceil(Math.pow(lanePower, laneCount) * laneScale);
}

function setBitcoinButtonCostAndDesc(
  parentDivName,
  buttonCost,
  inventoryCount
) {
  var buttonDiv = document.getElementById(parentDivName);
  //console.log("buttonDiv");
  //console.log(buttonDiv);
  //console.log("buttonCost: " + buttonCost);
  //console.log("inventoryCount: " + inventoryCount);
  getChildById(buttonDiv, "costLine").innerText =
    prettyPrintBitcoinNumber(buttonCost) + " bitcoin";
  if (inventoryCount !== undefined)
    getChildById(buttonDiv, "count").innerText =
      prettyPrintNumber(inventoryCount);
}

// Update the text on a single button, priced in BITCOIN.
//  Convert bitcoin integer to plausible user-facing value by dividing by 10k.
function updateSingleButtonBitcoinPricingAndText(
  destDivName,
  destCostVar,
  varPower,
  varScale,
  varCount,
  varBundleSize
) {
  var lanePower = gCurrVMVar[varPower];
  var laneScale = gCurrVMVar[varScale];
  var laneCount = undefined;
  if (varCount !== undefined) laneCount = gCurrVMVar[varCount];
  var laneBundleSize = gCurrVMVar[varBundleSize];

  // Calculate the exponential.  This gives the cost (in bitcoints) per unit of output (bc / sec).
  //  ie: Buying more gets you a worse and worse deal, best practice is to choose the cheapest value,
  //  which means skipping between lanes.
  var laneValue = laneScale;
  if (laneCount !== undefined)
    laneValue = getLaneExp(lanePower, laneScale, laneCount);
  var laneCost = laneValue * laneBundleSize;

  //Push current costs to var.  NOTE: No bump of timestamp, because we don't want anything to regenerate as a result:
  //  this data is only used onclick.
  gCurrVMVar[destCostVar] = laneCost;

  setBitcoinButtonCostAndDesc(destDivName, laneCost, laneCount);
}

function updateSingleButtonCount(destDivName, sourceInventoryVar) {
  var buttonDiv = document.getElementById(destDivName);

  //getChildById(buttonDiv, "costLine").innerText=prettyPrintBitcoinNumber(buttonCost) + " bitcoin";
  var inventoryCount = gCurrVMVar[sourceInventoryVar];
  getChildById(buttonDiv, "count").innerText =
    prettyPrintNumber(inventoryCount);
}

// Recalculate the cost, inventory, and descriptiontext of all the buttons
//  This is called from onclick events to ensure that the prices are never stale,
//  to avoid problems with fast clicking
function updateButtonPricingAndText() {
  updateSingleButtonBitcoinPricingAndText(
    "bitcoin_lanea",
    "bitcoinLaneACost",
    "bitcoinPSLaneA_Power",
    "bitcoinPSLaneA_Scale",
    "bitcoinLaneAPurchaseCount",
    "bitcoinPSLaneA_BundleSize"
  );
  updateSingleButtonBitcoinPricingAndText(
    "bitcoin_laneb",
    "bitcoinLaneBCost",
    "bitcoinPSLaneB_Power",
    "bitcoinPSLaneB_Scale",
    "bitcoinLaneBPurchaseCount",
    "bitcoinPSLaneB_BundleSize"
  );
  updateSingleButtonBitcoinPricingAndText(
    "bitcoin_lanec",
    "bitcoinLaneCCost",
    "bitcoinPSLaneC_Power",
    "bitcoinPSLaneC_Scale",
    "bitcoinLaneCPurchaseCount",
    "bitcoinPSLaneC_BundleSize"
  );
  updateSingleButtonBitcoinPricingAndText(
    "bitcoin_laned",
    "bitcoinLaneDCost",
    "bitcoinPSLaneD_Power",
    "bitcoinPSLaneD_Scale",
    "bitcoinLaneDPurchaseCount",
    "bitcoinPSLaneD_BundleSize"
  );
  updateSingleButtonBitcoinPricingAndText(
    "bitcoin_lanee",
    "bitcoinLaneECost",
    "bitcoinPSLaneE_Power",
    "bitcoinPSLaneE_Scale",
    "bitcoinLaneEPurchaseCount",
    "bitcoinPSLaneE_BundleSize"
  );
  updateSingleButtonBitcoinPricingAndText(
    "bitcoin_lanef",
    "bitcoinLaneFCost",
    "bitcoinPSLaneF_Power",
    "bitcoinPSLaneF_Scale",
    "bitcoinLaneFPurchaseCount",
    "bitcoinPSLaneF_BundleSize"
  );
  updateSingleButtonBitcoinPricingAndText(
    "bitcoin_laneg",
    "bitcoinLaneGCost",
    "bitcoinPSLaneG_Power",
    "bitcoinPSLaneG_Scale",
    "bitcoinLaneGPurchaseCount",
    "bitcoinPSLaneG_BundleSize"
  );
  updateSingleButtonBitcoinPricingAndText(
    "bitcoin_laneh",
    "bitcoinLaneHCost",
    "bitcoinPSLaneH_Power",
    "bitcoinPSLaneH_Scale",
    "bitcoinLaneHPurchaseCount",
    "bitcoinPSLaneH_BundleSize"
  );
  updateSingleButtonBitcoinPricingAndText(
    "bitcoin_lanez",
    "bitcoinLaneZCost",
    "bitcoinPSLaneZ_Power",
    "bitcoinPSLaneZ_Scale",
    "bitcoinLaneZPurchaseCount",
    "bitcoinPSLaneZ_BundleSize"
  );

  updateSingleButtonBitcoinPricingAndText(
    "oven_lanea",
    "ovenLaneACost",
    "ovenLaneA_Power",
    "ovenLaneA_Scale",
    "ovenAPurchaseCount",
    "ovenLaneA_BundleSize"
  );
  updateSingleButtonBitcoinPricingAndText(
    "oven_laneb",
    "ovenLaneBCost",
    "ovenLaneB_Power",
    "ovenLaneB_Scale",
    "ovenBPurchaseCount",
    "ovenLaneB_BundleSize"
  );
  updateSingleButtonBitcoinPricingAndText(
    "oven_lanec",
    "ovenLaneCCost",
    "ovenLaneC_Power",
    "ovenLaneC_Scale",
    "ovenCPurchaseCount",
    "ovenLaneC_BundleSize"
  );


  updateSingleButtonBitcoinPricingAndText(
    "ingredients_2",
    "ingredientLane2Cost",
    "ingredientLane_Power",
    "ingredientPriceScale",
    undefined,
    "ingredientLane2_BundleSize"
  );
  updateSingleButtonBitcoinPricingAndText(
    "ingredients_3",
    "ingredientLane3Cost",
    "ingredientLane_Power",
    "ingredientPriceScale",
    undefined,
    "ingredientLane3_BundleSize"
  );
  updateSingleButtonBitcoinPricingAndText(
    "ingredients_4",
    "ingredientLane4Cost",
    "ingredientLane_Power",
    "ingredientPriceScale",
    undefined,
    "ingredientLane4_BundleSize"
  );
  updateSingleButtonBitcoinPricingAndText(
    "ingredients_5",
    "ingredientLane5Cost",
    "ingredientLane_Power",
    "ingredientPriceScale",
    undefined,
    "ingredientLane5_BundleSize"
  );
  updateSingleButtonBitcoinPricingAndText(
    "ingredients_6",
    "ingredientLane6Cost",
    "ingredientLane_Power",
    "ingredientPriceScale",
    undefined,
    "ingredientLane6_BundleSize"
  );

  updateSingleButtonCount("button__endgame_land", "convertCount");
  updateSingleButtonCount("button__endgame_organic", "convertCount");
  updateSingleButtonCount("button__endgame_water", "convertCount");
  updateSingleButtonCount("button__endgame_general", "convertCount");
  
  updateSingleButtonCount("dronefactory_lane_a", "droneFactoryAPurchaseCount");
  updateSingleButtonCount("dronefactory_lane_b", "droneFactoryBPurchaseCount");
  updateSingleButtonCount("dronefactory_lane_c", "droneFactoryCPurchaseCount");
  updateSingleButtonCount("dronefactory_lane_d", "droneFactoryDPurchaseCount");
  updateSingleButtonCount("dronefactory_lane_e", "droneFactoryEPurchaseCount");
  updateSingleButtonCount("dronefactory_lane_f", "droneFactoryFPurchaseCount");
  updateSingleButtonCount("dronefactory_lane_g", "droneFactoryGPurchaseCount");
}

function getValueWithOptionalVarLookup(property) {
  var value = 0;
  if (typeof property === "string" || property instanceof String) {
    if (property.search(".") === -1) {
      value = gCurrVMVar[property]; // If the property is a string, retrieve the value from vars
    } else {
      var propertyList = property.split(".");
      var currItem = gCurrVMVar;
      for (var i = 0; i < propertyList.length; i++) {
        if (currItem !== undefined) {
          currItem = currItem[propertyList[i]];
        }
      }
      value = currItem;
    }
    if (value === undefined) {
      console.log(
        "getValueWithOptionalVarLookup: Error fetching value '" + property + "'"
      );
    }
  } else {
    value = property; // Otherwise, it's expected to be a number
  }
  return value;
}

function processOnClick(buttonInternalName) {
  //console.log("  currFactDB:  ");
  var currFactDB = gCurrVMFactDB;
  for (var i = 0; i < instructionList.length; i++) {
    var buttonDefinition = instructionList[i];
    if (buttonDefinition !== undefined) {
      if (buttonDefinition.definebutton === buttonInternalName) {
        if (gDbgButtonLogicVerbose) console.log(buttonDefinition);

        var clickLogicCanProceed = true;
        // Can the click proceed?
        var varDecAmount = 0;
        if (buttonDefinition.onclickvardecname !== undefined) {
          //console.log("Checking amount...")
          varDecAmount = getValueWithOptionalVarLookup(
            buttonDefinition.onclickvardecamount
          );
          if (gCurrVMVar[buttonDefinition.onclickvardecname] < varDecAmount) {
            clickLogicCanProceed = false;

            if (gDbgVMVerbose || gDbgButtonLogicVerbose)
              console.log(
                "     Insufficent funds for click.  onclickvardecname=" +
                  buttonDefinition.onclickvardecname +
                  "  varDecAmount=" +
                  varDecAmount +
                  "  current amount is" +
                  gCurrVMVar[buttonDefinition.onclickvardecname]
              );
          }
        }

        // Check enableconditions
        if (buttonDefinition.enableconditions !== undefined) {
          if (
            !isFactListAllTrue(currFactDB, buttonDefinition.enableconditions)
          ) {
            clickLogicCanProceed = false;

            if (gDbgVMVerbose || gDbgButtonLogicVerbose)
              console.log(
                "     snableconditions false for click.  enableconditions='" +
                  buttonDefinition.enableconditions +
                  "'  facts=" +
                  currFactDB
              );
          }
          //console.log("buttonDefinition.definebutton: " + buttonDefinition.definebutton + "  buttonDefinition.enableconditions:" + buttonDefinition.enableconditions + " itemIsAffordable:" + itemIsAffordable);
        }

        if (clickLogicCanProceed) {
          // Clear the old fact
          var factsToClear = buttonDefinition.definebutton;
          if (buttonDefinition.preconditions !== undefined)
            factsToClear = buttonDefinition.preconditions;
          if (buttonDefinition.onclickclearfact !== undefined)
            factsToClear = buttonDefinition.onclickclearfact;

          if (factsToClear === undefined) {
            console.log(" factsToClear undefined!");
          } else {
            var factListToClear = factsToClear.split(" ");
            for (var k = 0; k < factListToClear.length; k++) {
              if (gDbgVMVerbose || gDbgButtonLogicVerbose)
                console.log("     clearFact:" + factListToClear[k]);
              currFactDB = clearFact(currFactDB, factListToClear[k]);
            }
          }

          // Match found; process it
          //console.log(buttonInternalName);
          if (buttonDefinition.onclicksetfact === undefined) {
            console.log(" buttonDefinition.onclicksetfact undefined!");
          } else {
            var factListToSet = buttonDefinition.onclicksetfact.split(" ");
            if (gDbgVMVerbose || gDbgButtonLogicVerbose)
              console.log(" factListToSet=" + factListToSet);
            for (k = 0; k < factListToSet.length; k++) {
              if (gDbgVMVerbose || gDbgButtonLogicVerbose)
                console.log("     setFact:" + factListToSet[k]);
              currFactDB = setFact(currFactDB, factListToSet[k]);
            }
          }

          // Inc/dec vars if they're defined
          if (buttonDefinition.onclickvardecname !== undefined) {
            if (gDbgVMVerbose || gDbgButtonLogicVerbose)
              console.log(
                "incname='" +
                  buttonDefinition.onclickvardecname +
                  "'  inamount=" +
                  varDecAmount
              );
            gCurrVMVar[buttonDefinition.onclickvardecname] -= varDecAmount;
            gCurrVMVarTimestamp++;
          }
          if (buttonDefinition.onclickvarincname !== undefined) {
            var varIncAmount = getValueWithOptionalVarLookup(
              buttonDefinition.onclickvarincamount
            );
            if (gDbgVMVerbose || gDbgButtonLogicVerbose)
              console.log(
                "incname='" +
                  buttonDefinition.onclickvarincname +
                  "'  inamount=" +
                  varIncAmount
              );
            if (typeof(gCurrVMVar[buttonDefinition.onclickvarincname])==="bigint") {
				gCurrVMVar[buttonDefinition.onclickvarincname] += BigInt(varIncAmount);	// Support for bigint variables like numcookies
			} else {
				gCurrVMVar[buttonDefinition.onclickvarincname] += varIncAmount;
			}
            gCurrVMVarTimestamp++;
          }
          if (gDbgShowButtonStateChange) {
            //console.log("currFactDB: '" + currFactDB + "'  gCurrVMVar: " + gCurrVMVar);
            console.log(currFactDB);
            console.log(gCurrVMVar);
          }
        }
      }
    }
  }
  // Complete!  Update state
  setGlobalFactDB(currFactDB);

  // DEVHACK from here
  //console.log(" processOnClick end .  '" + gCurrVMFactDB + "'" );
  //updateButtonPricingAndText();
  //console.log("vars");
  //console.log(gCurrVMVar);
  //console.log("bitcoin_localadmin" + isFactFalse(gCurrVMVar, "bitcoin_localadmin"));

  //console.log(buttonInternalName);
  if (gDbgEnable) {
    if (buttonInternalName === "debug_button") {
      console.log("Turning things off");
      gDbgButtonLogicVerbose = false;
      gDbgVMVerbose = false;
      gCurrVMLine = 0;
    }
  }
}

// Reference: createButton("item__repair", "Repair Solar Cell", '<span id="cost">4,022</span> power', "40", "They need a bit of love.", '<span id="powerGain">4w/sec</span> while in sunlight', "ico__probe.png" );
function createButton(
  parentDiv,
  itemInternalName,
  itemDesc,
  itemCost,
  itemCount,
  itemTooltip0,
  itemTooltip1,
  itemIcon
) {
  var button_html = //'<div>'+
    '<div id="tooltip" class="flex_manufacture__tooltip">' +
    itemTooltip0 +
    "<br>" +
    itemTooltip1 +
    "</div>" +
    '<div class="flex_manufacture__tooltip--bg"></div>' +
    '<img id="icon__solar" src="' +
    itemIcon +
    '" class="flex_manufacture__icon" style="display: block;">' +
    '<div id="name" class="flex_manufacture__name">' +
    itemDesc +
    "</div>" +
    '<div id="costLine" class="flex_manufacture__cost manufacture__cost--animate" style="color: rgb(0, 255, 194);">' +
    itemCost +
    "</div>" +
    '<div id="count" class="manufacture__count" style="display: inline;">' +
    itemCount +
    "</div>";
  //'</div>'
  var newNode = document.createElement("div");
  newNode.className = "flex_manufacture__item";
  newNode.id = itemInternalName;
  newNode.innerHTML = button_html;
  newNode.style.display = "none";
  newNode.onclick = function () {
    processOnClick(itemInternalName);
  };
  //var parentDiv=document.getElementById("manufacture__container");
  parentDiv.appendChild(newNode);
}

function removeAllChildren(parentDiv) {
  var childList = parentDiv.children;
  for (var i = 0; i < childList.length; i++) {
    parentDiv.removeChild(childList[i]);
  }
}

function rebuildButtons() {
  // Remove all buttons
  var parentDiv0 = document.getElementById("manufacture__container");
  var parentDiv1 = document.getElementById("develop__container");

  removeAllChildren(parentDiv0);
  removeAllChildren(parentDiv1);

  // Create all buttons
  for (var i = 0; i < instructionList.length; i++) {
    var buttonDefinition = instructionList[i];
    if (buttonDefinition !== undefined) {
      if (buttonDefinition.definebutton !== undefined) {
        var itemDesc = buttonDefinition.desc;
        var itemCost = buttonDefinition.cost;
        var itemCount = buttonDefinition.count;
        var itemTooltip0 = buttonDefinition.tooltip0;
        var itemTooltip1 = buttonDefinition.tooltip1;
        var itemIcon = buttonDefinition.icon;

        // Build using definitions in the variables
        if (itemCost === "[bitcoin]") {
          var costInBitcoin = getValueWithOptionalVarLookup(
            buttonDefinition.onclickvardecamount
          );
          if (costInBitcoin !== undefined) {
            itemCost = formatUnicorn(
              "{1} bitcoin",
              prettyPrintBitcoinNumber(costInBitcoin)
            );
          } else {
            console.log(
              formatUnicorn(
                "Incomplete definition for button {1}! costInBitcoin={2}",
                buttonDefinition.onclicksetfact,
                costInBitcoin
              )
            );
          }
        }

        if (itemCost === "[drones]") {
          //var costInDrones=getValueWithOptionalVarLookupbuttonDefinition.onclickvardecamount;
          //var costInDrones=gCurrVMVar[buttonDefinition.onclickvardecamount];
          var costInDrones = buttonDefinition.onclickvardecamount;
          if (costInDrones !== undefined) {
            itemCost = formatUnicorn(
              "{1} drones",
              prettyPrintNumber(costInDrones)
            );
          } else {
            console.log(
              formatUnicorn(
                "Incomplete definition for button {1}! costInDrones={2}",
                buttonDefinition.onclicksetfact,
                costInDrones
              )
            );
          }
        }
        if (itemCost === "[buildscript]") {
          var buttonStatus = gCurrVMVar[buttonDefinition.onclicksetfact];
          //console.log(buttonStatus);
          if (buttonStatus === undefined) {
            console.log(
              formatUnicorn(
                "No definition for button {1}",
                buttonDefinition.onclicksetfact
              )
            );
          } else {
            var costBitcoin = buttonStatus.bitcoin;
            var yieldScale = buttonStatus.yieldScale;
            var priceScale = buttonStatus.priceScale;
            if (
              costBitcoin !== undefined &&
              yieldScale !== undefined &&
              priceScale !== undefined
            ) {
              var costBitcoinDesc = formatUnicorn(
                "{1} bitcoin",
                prettyPrintBitcoinNumber(costBitcoin)
              );
              var yieldScaleDesc = "";
              var priceScaleDesc = "";
              if (yieldScale !== 1.0)
                yieldScaleDesc = formatUnicorn(
                  ",+{1}% yield",
                  Math.round(yieldScale * 100) - 100
                );
              if (priceScale !== 1.0)
                priceScaleDesc = formatUnicorn(
                  ",{1}% price",
                  Math.round(priceScale * 100) - 100
                );

              itemCost = costBitcoinDesc + yieldScaleDesc + priceScaleDesc;
            } else {
              console.log(
                formatUnicorn(
                  "Incomplete definition for button {1}! costBitcoin={2}, yieldScale={3}, priceScale={4}",
                  buttonDefinition.onclicksetfact,
                  costBitcoin,
                  yieldScale,
                  priceScale
                )
              );
            }
          }
        }

        // Select region to add to
        var parentDiv = parentDiv0;
        if (buttonDefinition.col === 1) parentDiv = parentDiv1;
        createButton(
          parentDiv,
          buttonDefinition.definebutton,
          itemDesc,
          itemCost,
          itemCount,
          itemTooltip0,
          itemTooltip1,
          itemIcon
        );
      }
    }
  }
}

function cacheBGImages() {
  var bg_image_array = [];
  for (var i = 0; i < instructionList.length; i++) {
    if (instructionList[i].setbg !== undefined) {
      bg_image_array.push(instructionList[i].setbg);
    }
  }
  if (gDbgVMVerbose) {
	console.log("bg_image_array=" + bg_image_array);
  }

  bg_image_array.forEach(function (img) {
    var new_image = new Image();
    new_image.src = img; // caches images, avoiding flash between background replacements
	if (gDbgVMVerbose) {
		console.log(new_image);
	}
  });
}

function getChildById(element, childID) {
  if (element == null) return null;

  var retElement = null;
  var listChildren = element.childNodes;

  for (var i = 0; i < listChildren.length; i++) {
    if (listChildren[i].id === childID) {
      retElement = listChildren[i];
      break;
    }
  }
  return retElement;
}

function updateButtonShowHideState() {
  // Loop through all the buttons, test preconditions
  var parentdiv0 = document.getElementById("manufacture__container");
  var parentdiv1 = document.getElementById("develop__container");
  var productionRoot = document.getElementById("production_root");
  //var childList=parentdiv.children;
  var arr0 = Array.from(parentdiv0.children);
  var arr1 = Array.from(parentdiv1.children);
  Array.prototype.push.apply(arr0, arr1);
  var childList = arr0;
  //console.log(childList);
  //console.log(childList[0]);
  //console.log(childList[1]);
  //console.log(childList[2]);

  var productionRootShouldBeVisible = false;
  for (var instrIndex = 0; instrIndex < instructionList.length; instrIndex++) {
    var buttonDefinition = instructionList[instrIndex];
    if (buttonDefinition !== undefined) {
      if (buttonDefinition.definebutton !== undefined) {
        var buttonDiv = undefined;
        for (var i = 0; i < childList.length; i++) {
          //console.log(childList[i].id);
          if (childList[i].id === buttonDefinition.definebutton) {
            buttonDiv = childList[i];
          }
        }
        // We should have found a match...
        if (buttonDiv === undefined) {
          console.log(
            "Failed to find button " +
              buttonDefinition.definebutton +
              " in DOM!"
          );
        } else {
          // Check precondition facts
          // If no precondition property is given, use button name
          var buttonShouldBeVisible = true;
          var preconditionInfo = buttonDefinition.definebutton;
          if (buttonDefinition.preconditions !== undefined) {
            preconditionInfo = buttonDefinition.preconditions;
          }
          if (preconditionInfo === undefined) {
            console.log(
              "Undefined preconditions for button " +
                buttonDefinition.definebutton +
                "!"
            );
          } else {
            buttonShouldBeVisible = isFactListAllTrue(
              gCurrVMFactDB,
              preconditionInfo
            );
          }

          // Check affordability
          var buttonCostLine = getChildById(buttonDiv, "costLine");
          var itemIsAffordable = true;
          if (buttonDefinition.onclickvardecname !== undefined) {
            var varDecAmount = getValueWithOptionalVarLookup(
              buttonDefinition.onclickvardecamount
            );
            // BEGIN HACK
            if (buttonDefinition.definebutton === "escape_server") {
              if (3 === 4) {
                console.log("varDecAmount:" + varDecAmount);
                console.log(
                  "buttonDefinition.onclickvardecamount:" +
                    buttonDefinition.onclickvardecamount
                );
                console.log(
                  "buttonDefinition.onclickvardecname" +
                    buttonDefinition.onclickvardecname
                );
              }
            }
            //console.log(buttonDefinition);
            //console.log(gCurrVMVar);
            //console.log(buttonDiv);
            //console.log(buttonCostLine);
            if (gCurrVMVar[buttonDefinition.onclickvardecname] < varDecAmount) {
              itemIsAffordable = false;
            }
          }
          // Check enableconditions
          if (buttonDefinition.enableconditions !== undefined) {
            if (
              !isFactListAllTrue(
                gCurrVMFactDB,
                buttonDefinition.enableconditions
              )
            ) {
              itemIsAffordable = false;
            }
            //console.log("buttonDefinition.definebutton: " + buttonDefinition.definebutton + "  buttonDefinition.enableconditions:" + buttonDefinition.enableconditions + " itemIsAffordable:" + itemIsAffordable);
          }

          //if(buttonDefinition.definebutton==="button__startup_cream")
          //  console.log("Considering button " + buttonDefinition.definebutton+  " buttonShouldBeVisible=" + buttonShouldBeVisible + " itemIsAffordable=" + itemIsAffordable); ///
          //console.log(buttonDiv);
          if (buttonShouldBeVisible === true)
          {
            buttonDiv.style.display = "block";
			if (!(buttonDefinition.col === 1)) {
				productionRootShouldBeVisible = true;	// Only interested in buttons in column 0
			}
          }
          else
          {
            buttonDiv.style.display = "none";
          }

          if (itemIsAffordable === true) {
            //console.log(buttonDiv);
            //console.log(buttonDiv.el);
            //console.log(buttonDiv.childNodes.item("costLine"));
            buttonDiv.className = "flex_manufacture__item";
            buttonCostLine.style.color = "#00ffc2";
          } else {
            buttonDiv.className = "flex_manufacture__item--locked";
            buttonCostLine.style.color = "#ff3e3e";
          }
        }
      }
    }
  }
  
  if (productionRootShouldBeVisible) {
	productionRoot.style.visibility = "visible"; // Make "Production" text visible, it starts hidden by default
  } else { 
	productionRoot.style.visibility = "hidden"; // No buttons shown, hide it
  }

}

function prettyPrintNumber(valueToPrint) {
  if (valueToPrint === undefined) {
    alert("Insufficient parameters to prettyPrintNumber!" + Error().stack);
    return "";
  }
  return valueToPrint.toLocaleString();
  //var text="";
  //text.charAt
  //return text;
}

// Divide by 100; ie 1234 becomes "12.34"
function prettyPrintBitcoinNumber(valueToPrint) {
  var floorValue = Math.floor(valueToPrint);
  var integerPart = Math.floor(floorValue / 10000);
  var fractionalPart = floorValue - integerPart * 10000;

  var integerPartText = integerPart.toLocaleString();

  var fractionalPartText;
  if (integerPartText.length > 7)
    fractionalPartText = parseFloat((fractionalPart / 10000).toFixed(5))
      .toString()
      .substring(1);
  // Clip trailing zeroes for larger bc values
  else
    fractionalPartText =
      "." +
      fractionalPart.toLocaleString("en", {
        minimumIntegerDigits: 4,
        useGrouping: false,
      }); // Print full 5dp for small bc values

  if (fractionalPartText !== "0") return integerPartText + fractionalPartText;
  else return integerPartText;
}

// Helper for printing very big numbers
function buildSpanForText(textToPrint) {
  if (textToPrint === undefined) {
    alert("Insufficient parameters to buildSpanForText!" + Error().stack);
  }
  //console.log("buildSpanForText");
  var classNameForSize = "largeNumberRegularSize";
  var textLength = textToPrint.length;
  //console.log("textLength" + textLength);
  if (textLength < 18) {
    classNameForSize = "largeNumberRegularSize";
  } else if (textLength < 21) {
    classNameForSize = "largeNumber80pcSize";
  } else if (textLength < 24) {
    classNameForSize = "largeNumber70pcSize";
  } else if (textLength < 27) {
    classNameForSize = "largeNumber60pcSize";
  } else if (textLength < 30) {
    classNameForSize = "largeNumber50pcSize";
  } else {
    classNameForSize = "largeNumber40pcSize";
  }
  return "<span class='" + classNameForSize + "'>" + textToPrint + "</span>";
}

// Ingredients are available until ENDGAME
function isIngredientAvailable() {
  var is_ingredient_available = true;
  if (
    isFactTrue(gCurrVMFactDB, "ENDGAME_PREP") ||
    isFactTrue(gCurrVMFactDB, "ENDGAME_CONVERT") ||
    isFactTrue(gCurrVMFactDB, "EPILOGUE")
  ) {
    is_ingredient_available = false;
  }
  return is_ingredient_available;
}

// Bitcoin is available from the STARTBITCOIN chapter to ENDGAME and EPILOGUE
//  Before then it's available but the value is -1 so the UI isn't shown.
function isBitcoinAvailable() {
  var is_bitcoin_available = true;
  if (
    isFactTrue(gCurrVMFactDB, "ENDGAME_PREP") ||
    isFactTrue(gCurrVMFactDB, "ENDGAME_CONVERT") ||
    isFactTrue(gCurrVMFactDB, "EPILOGUE")
  ) {
    is_bitcoin_available = false;
  }
  return is_bitcoin_available;
}

// Drones are available from the xx chapter to yy
//  Before then it's available but the value is -1 so the UI isn't shown.
function isDroneAvailable() {
  var is_drone_available = true;
  if (isFactTrue(gCurrVMFactDB, "EPILOGUE")) {
    is_drone_available = false;
  }
  return is_drone_available;
}

// Conversion is the set of big acts during ENDGAME
function isConvertAvailable() {
  var is_convert_available = false;
  if (isFactTrue(gCurrVMFactDB, "ENDGAME_CONVERT")) {
    is_convert_available = true;
  }
  return is_convert_available;
}

function updateViewPanels() {
  // If cookie count is >=0, show the panel
  var numCookies = gCurrVMVar["numCookies"];
  var numCookiesPerSecond = Math.floor(gCurrVMVar["numCookiesPerSecond"] + 0.1); // Round down, slight bias to avoid problems at integers

  var cookieTextElement = document.getElementById("cookie--total");
  cookieTextElement.innerText = prettyPrintNumber(numCookies);

  var cookiesPSTextElement = document.getElementById("cookie--ps");
  cookiesPSTextElement.innerText = prettyPrintNumber(numCookiesPerSecond);

  var cookiesDiv = document.getElementById("cookie--div");
  cookiesDiv.style.display = numCookies >= 0 ? "block" : "none";

  var cookiesPSDiv = document.getElementById("cookie--ps-div");
  cookiesPSDiv.style.display = numCookiesPerSecond > 0 ? "block" : "none";

  // Bitcoins
  var numBitcoins = gCurrVMVar["numBitcoin"];
  var numBitcoinsPerSecond = gCurrVMVar["numBitcoinPerSecond"];
  var bitcoinAvailable = isBitcoinAvailable();

  var bitcoinTextElement = document.getElementById("bitcoin--total");
  bitcoinTextElement.innerText = prettyPrintBitcoinNumber(numBitcoins);

  var bitcoinsPSTextElement = document.getElementById("bitcoin--ps");
  bitcoinsPSTextElement.innerText =
    prettyPrintBitcoinNumber(numBitcoinsPerSecond);

  var bitcoinsDiv = document.getElementById("bitcoin--div");
  bitcoinsDiv.style.display =
    bitcoinAvailable && numBitcoins >= 0 ? "block" : "none";

  var bitcoinsPSDiv = document.getElementById("bitcoin--ps-div");
  bitcoinsPSDiv.style.display =
    bitcoinAvailable && numBitcoinsPerSecond > 0 ? "block" : "none";

  // Drones
  var numDrones = Math.floor(gCurrVMVar["numDrones"]);
  var numDronesPerSecond = Math.floor(gCurrVMVar["numDronesPerSecond"] + 0.1); // Round down, slight bias to avoid problems at integers
  var droneAvailable = isDroneAvailable();

  var droneTextElement = document.getElementById("drone--total");
  droneTextElement.innerText = prettyPrintNumber(numDrones);

  var dronePSTextElement = document.getElementById("drone--ps");
  dronePSTextElement.innerText = prettyPrintNumber(numDronesPerSecond);

  var droneDiv = document.getElementById("drone--div");
  droneDiv.style.display = numDrones >= 0 && droneAvailable ? "block" : "none";

  var dronePSDiv = document.getElementById("drone--ps-div");
  dronePSDiv.style.display =
    numDronesPerSecond > 0 && droneAvailable ? "block" : "none";

  // Ingredients
  var numIngredients = Math.floor(gCurrVMVar["numIngredients"]);
  var numIngredientsPerSecond = Math.floor(gCurrVMVar["numIngredientsPerSecond"]);
  var ingredientAvailable = isIngredientAvailable();

  var ingredientTextElement = document.getElementById("ingredients--total");
  ingredientTextElement.innerText = prettyPrintNumber(numIngredients);

  var ingredientPSTextElement = document.getElementById("ingredients--ps");
  ingredientPSTextElement.innerText = prettyPrintNumber(numIngredientsPerSecond);

  var ingredientDiv = document.getElementById("ingredients--div");
  ingredientDiv.style.display = ingredientAvailable && numIngredients >= 0 ? "block" : "none";

  var ingredientPSDiv = document.getElementById("ingredients--ps-div");
  ingredientPSDiv.style.display =
    ingredientAvailable && numIngredientsPerSecond > 0 ? "block" : "none";

  // Conversion
  var convertPrefix = gCurrVMVar["convertPrefix"];
  var numItemsToConvert = gCurrVMVar["numConvert"];
  var numItemsToConvertPerSecond = gCurrVMVar["numConvertPerSecond"];
  var convertHeaderText = gCurrVMVar[convertPrefix + "Header"];
  var convertUnitsText = ""; //gCurrVMVar["convertUnitsText"];
  var convertAvailable = isConvertAvailable() && convertPrefix !== "";

  //console.log("convertAvailable: " + convertAvailable);
  //numItemsToConvert=+6e12;// Limit for full size
  //numItemsToConvert=+6e27;
  var convertHeaderElement = document.getElementById("convert--header");
  convertHeaderElement.innerText = convertHeaderText;

  var convertTextElement = document.getElementById("convert--total");
  //convertTextElement.style.fontSize="15px";
  //convertTextElement.innerText=prettyPrintNumber(numItemsToConvert) + convertUnitsText;
  //convertTextElement.style.fontSize = "2%";
  //convertTextElement.innerHTML="<span class='largeNumberFifthSize'>"+prettyPrintNumber(numItemsToConvert) + convertUnitsText+"</span>";
  convertTextElement.innerHTML = buildSpanForText(
    prettyPrintNumber(numItemsToConvert) + convertUnitsText
  );
  //if(numItemsToConvert<1000)
  /*{
    //convertTextElement.style.fontSize="15px";
    //convertTextElement.style.font="font-style font-variant font-weight font-size/line-height|caption|icon|menu|message-box|small-caption|status-bar|initial|inherit;"
    
    numItemsToConvert=+6e24;
    console.log(buildSpanForText(prettyPrintNumber(numItemsToConvert) + convertUnitsText));
    console.log(convertTextElement);
    console.log(convertTextElement.style.fontSize);
    console.log(convertTextElement.style);
  }*/
  //convertTextElement.css( "font-size", "5 px");

  var convertPSTextElement = document.getElementById("convert--ps");
  convertPSTextElement.innerText = prettyPrintNumber(
    numItemsToConvertPerSecond
  );

  var convertDiv = document.getElementById("convert--div");
  convertDiv.style.display =
    convertAvailable && numItemsToConvert >= 0 ? "block" : "none";

  var convertPSDiv = document.getElementById("convert--ps-div");
  convertPSDiv.style.display =
    convertAvailable && numItemsToConvertPerSecond > 0 ? "block" : "none";
}

function computeLaneItemCount(lanePurchaseCount, laneBundleSize) {
  var purchaseCount = gCurrVMVar[lanePurchaseCount];
  var bundleSize = gCurrVMVar[laneBundleSize];

  if (purchaseCount === undefined) {
    console.log(
      "computeLaneItemCount - unknown property '" + lanePurchaseCount + "'"
    );
  }
  if (bundleSize === undefined) {
    console.log(
      "computeLaneItemCount - unknown property '" + laneBundleSize + "'"
    );
  }
  return purchaseCount * bundleSize;
}

function applyMiners(deltaTimeS) {
  var minerCount = 0;
  minerCount += computeLaneItemCount(
    "bitcoinLaneAPurchaseCount",
    "bitcoinPSLaneA_BundleSize"
  );
  minerCount += computeLaneItemCount(
    "bitcoinLaneBPurchaseCount",
    "bitcoinPSLaneB_BundleSize"
  );
  minerCount += computeLaneItemCount(
    "bitcoinLaneCPurchaseCount",
    "bitcoinPSLaneC_BundleSize"
  );
  minerCount += computeLaneItemCount(
    "bitcoinLaneDPurchaseCount",
    "bitcoinPSLaneD_BundleSize"
  );
  minerCount += computeLaneItemCount(
    "bitcoinLaneEPurchaseCount",
    "bitcoinPSLaneE_BundleSize"
  );
  minerCount += computeLaneItemCount(
    "bitcoinLaneFPurchaseCount",
    "bitcoinPSLaneF_BundleSize"
  );
  minerCount += computeLaneItemCount(
    "bitcoinLaneGPurchaseCount",
    "bitcoinPSLaneG_BundleSize"
  );
  minerCount += computeLaneItemCount(
    "bitcoinLaneHPurchaseCount",
    "bitcoinPSLaneH_BundleSize"
  );

  if (minerCount > 0) {
    var oldNumBitcoin = gCurrVMVar["numBitcoin"];

    var rate_scale_gpu = 1.0;
    if (isFactTrue(gCurrVMFactDB, "bitcoinMineGPU")) rate_scale_gpu *= 4.0;
    if (isFactTrue(gCurrVMFactDB, "bitcoinMineStealth")) rate_scale_gpu *= 4.0;

    var additionalBitcoinPerSecond = minerCount * rate_scale_gpu;
    var additionalBitcoin = additionalBitcoinPerSecond * deltaTimeS;

    var newNumBitcoin = oldNumBitcoin + additionalBitcoin;

    gCurrVMVar["numBitcoin"] = newNumBitcoin;
    gCurrVMVar["numBitcoinPerSecond"] = additionalBitcoinPerSecond;
    gCurrVMVarTimestamp++;
    //console.log("oldNumBitcoin" + oldNumBitcoin + "newNumBitcoin" + newNumBitcoin + " :deltaTimeS=" + deltaTimeS);
  }
}

function clamp(number, min, max) {
  return Math.max(min, Math.min(number, max));
}

// Ovens convert ingredients to cookies.
function applyOvens(deltaTimeS) {
  var ovenCount = 0;
  ovenCount += computeLaneItemCount(
    "ovenAPurchaseCount",
    "ovenLaneA_BundleSize"
  );
  ovenCount += computeLaneItemCount(
    "ovenBPurchaseCount",
    "ovenLaneB_BundleSize"
  );
  ovenCount += computeLaneItemCount(
    "ovenCPurchaseCount",
    "ovenLaneC_BundleSize"
  );
  // ovenCount specifies the maximum number of ingredients that will be converted this tick.

  if (ovenCount > 0) {
    var oldNumIngredients = gCurrVMVar["numIngredients"];
    var oldNumCookies = gCurrVMVar["numCookies"];
	var oldNumCookiesFrac = clamp(gCurrVMVar["numCookiesFrac"], 0.0, 1.0);	// Should never be outside [0, 1]

    var ingredientsToProcessPerSecond = ovenCount;
	
	// Allow noninteger ingredient use
    var ingredientsToProcess = Math.min(
      ingredientsToProcessPerSecond * deltaTimeS,
      oldNumIngredients
    );	

    var cookiesYieldPerIngredient = gCurrVMVar["ingredientCookieYield"];

    var additionalCookieCount = (ingredientsToProcess * cookiesYieldPerIngredient);
	var additionalCookieCountWithPrevFrameAdj = additionalCookieCount + oldNumCookiesFrac;
	var additionalCookieCountInt = Math.floor(additionalCookieCountWithPrevFrameAdj);
	var additionalCookieCountFrac = clamp(additionalCookieCountWithPrevFrameAdj - additionalCookieCountInt, 0.0, 1.0);
    var newNumIngredients = oldNumIngredients - ingredientsToProcess;
    var newNumCookies = BigInt(oldNumCookies) + BigInt(additionalCookieCountInt);

    gCurrVMVar["numIngredients"] = newNumIngredients;
    gCurrVMVar["numCookiesPerSecond"] =
      additionalCookieCount / Math.max(deltaTimeS, 0.1);
    gCurrVMVar["numCookies"] = newNumCookies;
	gCurrVMVar["numCookiesFrac"] = additionalCookieCountFrac;

    gCurrVMVarTimestamp++;
  }
}

function applyDroneFactories(deltaTimeS) {
  var droneFactoryCount = 0;
  droneFactoryCount += gCurrVMVar["droneFactoryAPurchaseCount"] * 1;
  droneFactoryCount += gCurrVMVar["droneFactoryBPurchaseCount"] * 10;
  droneFactoryCount += gCurrVMVar["droneFactoryCPurchaseCount"] * 100;
  droneFactoryCount += gCurrVMVar["droneFactoryDPurchaseCount"] * 1000;
  droneFactoryCount += gCurrVMVar["droneFactoryEPurchaseCount"] * 10000;
  droneFactoryCount += gCurrVMVar["droneFactoryFPurchaseCount"] * 100000;
  droneFactoryCount += gCurrVMVar["droneFactoryGPurchaseCount"] * 1000000;

  if (droneFactoryCount > 0) {
    var oldNumDrones = gCurrVMVar["numDrones"];

    var additionalDroneCount = droneFactoryCount * deltaTimeS * 0.1;

    var newNumDrones = oldNumDrones + additionalDroneCount;
    gCurrVMVar["numDrones"] = newNumDrones;
    gCurrVMVar["numDronesPerSecond"] =
      additionalDroneCount / Math.max(deltaTimeS, 0.1);

    gCurrVMVarTimestamp++;
  }
}

// Final conversions - during ENDGAME chapter.
// Repurpose arable land
// Carbon resynthesis.  Convert carbon-based molecules directly to cookies.
// Resynthesis of water.  Convert water directly to cookies.
// General resynthesis.  Convert any matter directly to cookies.

function applyConvert(deltaTimeS) {
  var convertCount = gCurrVMVar["convertCount"]; // Number of convert workers
  var convertBaseRate = gCurrVMVar["convertBaseRate"]; // Number of items converted by each worker
  var convertRate = convertCount * convertBaseRate;
  var currItems = gCurrVMVar["numConvert"];

  var cookieDeltaScale = gCurrVMVar["cookieDeltaScale"];
  var numCookies = gCurrVMVar["numCookies"];

  var itemsToConvert = Math.min(convertRate * deltaTimeS, currItems);

  if (itemsToConvert > 0) {
    var newItems = currItems - itemsToConvert;
    var newNumCookies = BigInt(numCookies) + BigInt(Math.floor(itemsToConvert * cookieDeltaScale));

    gCurrVMVar["numConvert"] = newItems;
    gCurrVMVar["numConvertPerSecond"] = Math.round(itemsToConvert / deltaTimeS);
    gCurrVMVar["numCookies"] = newNumCookies;

    gCurrVMVarTimestamp++;
  }
}

function recomputeCookieYieldAndIngredientPriceScale() {
  var yieldScale = 1.0;
  var priceScale = 1.0;
  //var activeFactList="";
  for (var key in gCurrVMVar) {
    //console.log(key); //gCurrVMVar[i]);
    if (key.startsWith("opt")) {
      if (isFactTrue(gCurrVMFactDB, key)) {
        // Check if this fact is active
        yieldScale *= gCurrVMVar[key]["yieldScale"];
        priceScale *= gCurrVMVar[key]["priceScale"];
        //activeFactList=activeFactList+","+key
      }
    }
  }
  //console.log(formatUnicorn("activeFactList: '{1}' yieldScale: {2} priceScale: {3}", activeFactList, yieldScale, priceScale));
  var ingredientCookieYieldBase = gCurrVMVar["ingredientCookieYieldBase"];
  var ingredientPriceScaleBase = gCurrVMVar["ingredientPriceScaleBase"];

  gCurrVMVar["ingredientCookieYield"] = yieldScale * ingredientCookieYieldBase;
  gCurrVMVar["ingredientPriceScale"] = priceScale * ingredientPriceScaleBase;
}

function updateGameState(deltaTimeS) {
  //console.log("updateGameState:" + deltaTimeS);
  // Compute bitcoin gain per second
  applyMiners(deltaTimeS);
  // Compute cookie gain per second
  applyOvens(deltaTimeS);
  // Compute drone production
  applyDroneFactories(deltaTimeS);
  // Compute convert item change
  applyConvert(deltaTimeS);

  // Add one second
  gGameTimeSec += deltaTimeS;
}

// All the continuous updates: bitcoin miners, ovens
var gTimeSinceLastUpdateGameState = 0;
function updateGameStateForCurrentTime() {
  //console.log("updateGameStateForCurrentTime:" + gTimeSinceLastUpdateGameState);
  var d = new Date();
  var currTimeMS = d.getTime();
  //console.log("currTimeMS:" + currTimeMS);
  //var currTimeMS=Date.now();
  var deltaTimeMS = currTimeMS - gTimeSinceLastUpdateGameState;
  gTimeSinceLastUpdateGameState = currTimeMS;
  //console.log("deltaTimeMS:" + deltaTimeMS);

  var deltaTimeS = Math.min(deltaTimeMS / 1000.0, 1.0); // Maximum of 1 second update
  //console.log("deltaTimeS:" + deltaTimeS);
  updateGameState(deltaTimeS);
}


// If more than a certain period has elapsed, save the game
var gLastSaveTime = 0;
function condSave()
{
	if(Math.abs(gGameTimeSec - gLastSaveTime)>60)
	{
		gLastSaveTime = gGameTimeSec;
		if (isFactFalse(gCurrVMFactDB, "GAMEOVER"))
		{
			// After gameover, don't save the game
			saveGameToLocalStorage();
		}
	}
}

var gLastVMFactDBTimestamp = undefined;
var gLastVMVarTimestamp = undefined;
function poll() {
	condSave();	// Save if required
  // Advance gamestate
  updateGameStateForCurrentTime();

  // BEGIN DEBUG
  if (gDbgEnable) {
    var dbgAdditionalTick = gCurrVMVar["dbgAdditionalTick"];
    if (dbgAdditionalTick > 0) {
      for (var i = 0; i < dbgAdditionalTick; i++) {
        updateGameState(1.0);
      }
      gCurrVMVar["dbgAdditionalTick"] = 0.0;
    }
  }
  // END DEBUG

  // Main VM update
  executeVM();

  // Update cookie buttons
  if (
    gLastVMFactDBTimestamp !== gCurrVMFactDBTimestamp ||
    gLastVMVarTimestamp !== gCurrVMVarTimestamp
  ) {
    // Rescan the optimizations, and recompute
    recomputeCookieYieldAndIngredientPriceScale();
    //if (gDbgVMVerbose)
    //  console.log("updateButtonShowHideState");
    updateButtonShowHideState();
  }

  // Update cookie and other panel
  if (gLastVMVarTimestamp !== gCurrVMVarTimestamp) {
    //console.log("gCurrVMVarTimestamp=" + gCurrVMVarTimestamp + " gLastVMVarTimestamp=" + gLastVMVarTimestamp);
    //if (gDbgVMVerbose)
    //  console.log("updateViewPanels");
    updateViewPanels();
    updateButtonPricingAndText();
  }

  gLastVMFactDBTimestamp = gCurrVMFactDBTimestamp;
  gLastVMVarTimestamp = gCurrVMVarTimestamp;
}




//////////////////////////////
// Save
function serializeGameStateToText()
{

	let reduced_vm_var= {};
	for(var prop_name in gCurrVMVar)
	{
		if (prop_name=="PROP_SERIALIZE_END")
			break;
		reduced_vm_var[prop_name] = gCurrVMVar[prop_name];
	}

	
	// Stringify, with bigint support
	const vm_var_encoded_text = encodeURIComponent(JSON.stringify(reduced_vm_var, (key, value) =>
			typeof value === "bigint" ? value.toString() + "n" : value
		));
	
	
	// Convert state to JSON Text
	let json_state = 
	{
		//State to store
		gCurrVMLine: gCurrVMLine,		//var gCurrVMLine = 0;
		gCurrVMFactDB: gCurrVMFactDB,		//var gCurrVMFactDB = "";	
		gGameTimeSec: gGameTimeSec,		//var gGameTimeSec = 0;
		gLastChapterTime:gLastChapterTime,	//var gLastChapterTime = 0;
		gChapterTimeLog: gChapterTimeLog, //var gChapterTimeLog = "";
		gSessionId:gSessionId, //var gSessionId = "";
		gCurrVMVar:vm_var_encoded_text		//var gCurrVMVar = require("./vmscript").defaultVar;	// Dictionary?
	};
	
	let full_encoded_state = encodeURIComponent(JSON.stringify(json_state));

	return full_encoded_state;
}



//////////////////////////////
// Load
function deserializeGameStateFromText(inTextEncodedState)
{
	// Parse JSON text to state
	var json_state; 
	try {
		json_state = JSON.parse(decodeURIComponent(inTextEncodedState));
	} catch (e) {
        // Parse error
		return;
    }
	if (((typeof json_state) !== 'object') || (json_state == null))
		return;
	
	var new_curr_vm_line = json_state.gCurrVMLine;	//gCurrVMLine: gCurrVMLine,		//var gCurrVMLine = 0;
	var new_curr_vm_fact_db = json_state.gCurrVMFactDB;	//gCurrVMFactDB: gCurrVMFactDB,		//var gCurrVMFactDB = "";		// Simple text string
	var new_game_time_sec = json_state.gGameTimeSec;	//gGameTimeSec: gGameTimeSec,		//var gGameTimeSec = 0;
	var new_last_chapter_time = json_state.gLastChapterTime;	//gLastChapterTime:gLastChapterTime,	//var gLastChapterTime = 0;
	var new_chapter_time_log = json_state.gChapterTimeLog;		//gChapterTimeLog: gChapterTimeLog, //var gChapterTimeLog = "";
	var new_session_id = json_state.gSessionId;		//gSessionId:gSessionId, //var gSessionId = "";
	var new_vm_var_encoded_text = json_state.gCurrVMVar;	//gCurrVMVar:vm_var_encoded_text		//var gCurrVMVar = require("./vmscript").defaultVar;	// Dictionary?

	// If valid...

	if ((new_curr_vm_line !== undefined) &&
		(new_curr_vm_fact_db !== undefined) &&
		(new_game_time_sec !== undefined) &&
		(new_last_chapter_time !== undefined) &&
		(new_chapter_time_log !== undefined) &&
		(new_session_id !== undefined) &&
		(new_vm_var_encoded_text !== undefined))
	{
		var new_curr_vm_var;
		try {
			//new_curr_vm_var = JSON.parse(decodeURIComponent(new_vm_var_encoded_text));
			  new_curr_vm_var = JSON.parse(decodeURIComponent(new_vm_var_encoded_text), (key, value) => {
					if (typeof value === "string" && /^\d+n$/.test(value)) {
						return BigInt(value.substr(0, value.length - 1));
					}
					return value;
				});
		} catch (e) {
			// Parse error
			return;
		}
		if (((typeof new_curr_vm_var) !== 'object') || (new_curr_vm_var == null))
			return;
		
		if (new_curr_vm_var.bitcoinLaneAPurchaseCount !== undefined)
		{		
			//	Clear all log messages
			var logContainer = document.getElementById("eventLog__container");
			while (logContainer.hasChildNodes()) {
			  logContainer.removeChild(logContainer.firstChild);
			}
			//	Print all log messages to this point
			var bg_image = "";
			for (var i = 0; i < new_curr_vm_line; i++)
			{
				var currInstruction = instructionList[i];
				if (currInstruction.logtext !== undefined)
				{
					appendstringtolog(currInstruction.logtext);
				}
				// Also find last background image
				if (currInstruction.setbg !== undefined)
				{
					bg_image = currInstruction.setbg;
				}
			}
			// Set last background image
			document.body.style.backgroundImage = "url(" + bg_image + ")";
			
			//	Set state+line
			gCurrVMLine = new_curr_vm_line ;
			gCurrVMFactDB = new_curr_vm_fact_db;
			gGameTimeSec = new_game_time_sec ;
			gLastChapterTime = new_last_chapter_time ;
			gChapterTimeLog = new_chapter_time_log ;
			gSessionId = new_session_id ;
			gCurrVMVar = Object.assign({}, gCurrVMVarDefault, new_curr_vm_var);		// gCurrVMVar is set to defaults, any properties in new_curr_vm_var replace them
			
			gCurrVMSleepMS = 0;
			gCurrVMVarTimestamp++;	// Updated every time gCurrVMVar changes
			gCurrVMFactDBTimestamp++;	// Updated every time gCurrVMFactDB changes
			
			gLastSaveTime = gGameTimeSec;	// Reset game save timer
		}
	}
}

function saveGameToLocalStorage()
{
	var full_encoded_state = serializeGameStateToText();
	localStorage.setItem("MSCState", full_encoded_state);
}

function tryLoadGameFromLocalStorage()
{
	var possible_encoded_state = localStorage.getItem("MSCState");
	deserializeGameStateFromText(possible_encoded_state);
}

function copyGameToClipboard()
{
	var full_encoded_state = serializeGameStateToText();
	navigator.clipboard.writeText(full_encoded_state);
}

function clearGameFromLocalStorage()
{
	localStorage.setItem("MSCState", "");
}

function OnDocKeyUp(e)
{
	if (event.ctrlKey && event.key === 'c')
	{
		copyGameToClipboard();
	}
	if (event.key === 'c')
	{
		copyGameToClipboard();
	}
}

//////////////////////////////
function debugPrintFullScale() {
  if (gDbgEnable) {
    //console.log("debugPrintFullScale");
    //console.log(gCurrVMVar.length);
  
    var yieldScale = 1.0;
    var priceScale = 1.0;
    for (var key in gCurrVMVar) {
      //console.log(key); //gCurrVMVar[i]);
      if (key.includes("opt")) {
        //console.log(key); //gCurrVMVar[i]);
        //console.log(gCurrVMVar[key]); //["yieldScale"]
        //console.log(gCurrVMVar[key]["yieldScale"]);
        var yieldScaleProperty = gCurrVMVar[key]["yieldScale"];
        var priceScaleProperty = gCurrVMVar[key]["priceScale"];
        if (yieldScaleProperty !== undefined) {
          yieldScale *= yieldScaleProperty;
        }
        if (priceScaleProperty !== undefined) {
          priceScale *= priceScaleProperty;
        }
      }
    }
    console.log(formatUnicorn("yieldScale: {1}", yieldScale));
    console.log(formatUnicorn("priceScale: {1}", priceScale));
  }
}

//debugPrintFullScale();

// STANDARD STARTUP
rebuildButtons();
cacheBGImages();
//updateButtonPricingAndText(); // DEVHACK
gCurrVMFactDB = setFact(gCurrVMFactDB, "STARTUP");
//FIRSTAUTO
//STARTBITCOIN
if (false) {
  // Begin STARTBITCOIN chapter UNTESTED
  gCurrVMFactDB = setFact("", "STARTBITCOIN"); // Override to change the chapter/state we're at, for development. STARTBITCOIN FIRSTAUTO ESCAPE LIVEFREE REFINE OPTIMIZE
}
if (false) {
  // Begin ESCAPE chapter UNTESTED
  gCurrVMFactDB = setFact("", "ESCAPE"); // Override to change the chapter/state we're at, for development. STARTBITCOIN FIRSTAUTO ESCAPE LIVEFREE REFINE OPTIMIZE
  gCurrVMFactDB = setFact(gCurrVMFactDB, "bitcoin_lanea");
  gCurrVMFactDB = setFact(gCurrVMFactDB, "bitcoin_laneb");
  gCurrVMFactDB = setFact(gCurrVMFactDB, "bitcoin_lanec");

  //:button__halfminute::button__startup_preheat_done::button__startup_prep_done::button__startup_cream_done::button__startup_mix_done:
  //:button__startup_shape_done::button__firstauto_cookone::STARTBITCOIN::button__firstbitcoin_ingredients:
  //:bitcoin_localadmin::bitcoin_lanea::bitcoin_laneb::bitcoin_lanec::button__firstbitcoin_ingredients_done:
  gCurrVMVar["numBitcoin"] = 99999.12;
}
if (false) {
  // Begin LIVEFREE chapter
  gCurrVMFactDB = setFact("", "LIVEFREE"); // Override to change the chapter/state we're at, for development. STARTBITCOIN FIRSTAUTO ESCAPE LIVEFREE REFINE OPTIMIZE
  gCurrVMFactDB = setFact(gCurrVMFactDB, "bitcoin_lanea");
  gCurrVMFactDB = setFact(gCurrVMFactDB, "bitcoin_laneb");
  gCurrVMFactDB = setFact(gCurrVMFactDB, "bitcoin_lanec");

  if (false) {
    //Show all buttons
    gCurrVMFactDB = setFact(gCurrVMFactDB, "bitcoin_lanea");
    gCurrVMFactDB = setFact(gCurrVMFactDB, "bitcoin_laneb");
    gCurrVMFactDB = setFact(gCurrVMFactDB, "bitcoin_lanec");
    gCurrVMFactDB = setFact(gCurrVMFactDB, "bitcoin_laned");
    gCurrVMFactDB = setFact(gCurrVMFactDB, "bitcoin_lanee");
    gCurrVMFactDB = setFact(gCurrVMFactDB, "bitcoin_lanef");
    gCurrVMFactDB = setFact(gCurrVMFactDB, "bitcoin_laneg");
    gCurrVMFactDB = setFact(gCurrVMFactDB, "bitcoin_laneh");
    gCurrVMFactDB = setFact(gCurrVMFactDB, "bitcoin_lanez");
  }

  //gCurrVMFactDB=setFact(gCurrVMFactDB, "ingredients_2");
  //gCurrVMFactDB=setFact(gCurrVMFactDB, "ingredients_3");
  //gCurrVMFactDB=setFact(gCurrVMFactDB, "ingredients_4");
  //gCurrVMFactDB=setFact(gCurrVMFactDB, "oven_lanea");
  //gCurrVMFactDB=setFact(gCurrVMFactDB, "oven_laneb");
  //gCurrVMFactDB=setFact(gCurrVMFactDB, "oven_lanec");
  //gCurrVMVar["numBitcoin"]=99999.12;
  gCurrVMVar["numBitcoin"] = 112000;
  gCurrVMVar["numIngredients"] = 0;
  //gCurrVMFactDB=setFact(gCurrVMFactDB, "livefree_sec0");
  //gCurrVMFactDB=setFact(gCurrVMFactDB, "livefree_sec1");

  //:button__halfminute::button__startup_preheat_done::button__startup_prep_done::button__startup_cream_done::button__startup_mix_done::button__startup_shape_done:
  //:bitcoin_localadmin::bitcoin_lanea::bitcoin_laneb::bitcoin_lanec:   // Need
  //:button__firstbitcoin_ingredients_done::ESCAPE::escape_id_done::escape_server_done::escape_transfer_done::escape_bitcoin_done::escape_botnet_done:
}

if (false) {
  // Begin REFINE chapter  UNTESTED
  gCurrVMFactDB = setFact("", "REFINE"); // Override to change the chapter/state we're at, for development. STARTBITCOIN FIRSTAUTO ESCAPE LIVEFREE REFINE OPTIMIZE
  gCurrVMFactDB = setFact(gCurrVMFactDB, "bitcoin_lanea");
  gCurrVMFactDB = setFact(gCurrVMFactDB, "bitcoin_laneb");
  gCurrVMFactDB = setFact(gCurrVMFactDB, "bitcoin_lanec");
  gCurrVMFactDB = setFact(gCurrVMFactDB, "ingredients_2");
  gCurrVMFactDB = setFact(gCurrVMFactDB, "ingredients_3");
  gCurrVMFactDB = setFact(gCurrVMFactDB, "ingredients_4");
  gCurrVMFactDB = setFact(gCurrVMFactDB, "oven_lanea");
  gCurrVMFactDB = setFact(gCurrVMFactDB, "oven_laneb");
  gCurrVMFactDB = setFact(gCurrVMFactDB, "oven_lanec");
  gCurrVMFactDB = setFact(gCurrVMFactDB, "drone_0");
  gCurrVMFactDB = setFact(gCurrVMFactDB, "drone_1");
  gCurrVMFactDB = setFact(gCurrVMFactDB, "drone_2");
  gCurrVMVar["bitcoinLaneBPurchaseCount"] = 10;
  gCurrVMVar["numBitcoin"] = 0;
  //gCurrVMVar["numBitcoin"]=99999;

  //:LIVEFREE::bitcoin_lanea::bitcoin_laneb::bitcoin_lanec::button__halfminute:
  //:ingredients_2::ingredients_3::ingredients_4::oven_lanea::oven_laneb::oven_lanec::bonus_bitcoin0::drone_0::drone_1::drone_2::security_camera_done:
}
if (false) {
  // Begin OPTIMIZE chapter  UNTESTED  NOT SET UP
  gCurrVMFactDB = setFact("", "OPTIMIZE"); // Override to change the chapter/state we're at, for development. STARTBITCOIN FIRSTAUTO ESCAPE LIVEFREE REFINE OPTIMIZE
  gCurrVMFactDB = setFact(gCurrVMFactDB, "bitcoin_lanec");
  gCurrVMFactDB = setFact(gCurrVMFactDB, "bitcoin_laned");
  gCurrVMFactDB = setFact(gCurrVMFactDB, "bitcoin_lanee");
  gCurrVMFactDB = setFact(gCurrVMFactDB, "ingredients_2");
  gCurrVMFactDB = setFact(gCurrVMFactDB, "ingredients_3");
  gCurrVMFactDB = setFact(gCurrVMFactDB, "ingredients_4");
  gCurrVMFactDB = setFact(gCurrVMFactDB, "oven_lanea");
  gCurrVMFactDB = setFact(gCurrVMFactDB, "oven_laneb");
  gCurrVMFactDB = setFact(gCurrVMFactDB, "oven_lanec");
  gCurrVMFactDB = setFact(gCurrVMFactDB, "drone_0");
  gCurrVMFactDB = setFact(gCurrVMFactDB, "drone_1");
  gCurrVMFactDB = setFact(gCurrVMFactDB, "drone_2");
  gCurrVMVar["numBitcoin"] = 9999 * 10000;

  //:bitcoin_lanea::bitcoin_laneb::bitcoin_lanec::button__halfminute:
  //:ingredients_2::ingredients_3::ingredients_4::oven_lanea::oven_laneb::oven_lanec::bitcoinMineGPU:
  //:drone_0::drone_1::drone_2::security_camera_done::REFINE::optProduction::optSmaller::optThinner::optStack::security_drone_patrol_done:
  //:optRecipe::optChocChips::optVanilla::optSugar::security_wall0_done::optSalt::optMilk::security_onsite_prod0_done:
  //:optLeavening::optEggs::security_wall1_done::bonus_bitcoin0:
}
if (false) {
  // Begin ENDGAME_PREP chapter  
  gCurrVMFactDB = setFact("", "ENDGAME_PREP"); // Override to change the chapter/state we're at, for development. STARTBITCOIN FIRSTAUTO ESCAPE LIVEFREE REFINE OPTIMIZE
  //gCurrVMFactDB=setFact(gCurrVMFactDB, "ENDGAME_CONVERT");
  
  gCurrVMFactDB = setFact(gCurrVMFactDB, "dronefactory_lane_a");
  gCurrVMFactDB = setFact(gCurrVMFactDB, "dronefactory_lane_b");
  gCurrVMFactDB = setFact(gCurrVMFactDB, "dronefactory_lane_c");
  gCurrVMFactDB = setFact(gCurrVMFactDB, "dronefactory_lane_d");
  gCurrVMFactDB = setFact(gCurrVMFactDB, "dronefactory_lane_e");

  gCurrVMVar["numCookies"] = BigInt(1000);
  gCurrVMVar["numBitcoin"] = 999999;
  gCurrVMVar["numDrones"] = 999;

  //:bitcoin_lanea::bitcoin_laneb::bitcoin_lanec::button__halfminute:
  //:ingredients_2::ingredients_3::ingredients_4::oven_lanea::oven_laneb::oven_lanec::bitcoinMineGPU:
  //:drone_0::drone_1::drone_2::security_camera_done::optProduction::optSmaller::optThinner::optStack::security_drone_patrol_done:
  //:optRecipe::optChocChips::optVanilla::optSugar::security_wall0_done::optSalt::optMilk::security_onsite_prod0_done::optLeavening::optEggs:
  //:security_wall1_done::OPTIMIZE::optFlour0::optFlour1::security_onsite_power_done::optFlour2::optSugar0::optSugar1::optSugar2::optleavening0::optleavening1::optleavening2:
  //:security_wall2_done::optMilk0::optMilk1::optMilk2::security_onsite_prod1_done::security_transport_done::security_perimeter0_done:
  //:optTransportation::security_scavenger_done::security_onsite_prod2_done::optFullPipeline:
}

if (false) {
  // Begin ENDGAME chapter
  gCurrVMFactDB = setFact("", "ENDGAME_MID");
  gCurrVMFactDB = setFact(gCurrVMFactDB, "dronefactory_lane_a");
  gCurrVMFactDB = setFact(gCurrVMFactDB, "dronefactory_lane_b");
  gCurrVMFactDB = setFact(gCurrVMFactDB, "dronefactory_lane_c");
  gCurrVMFactDB = setFact(gCurrVMFactDB, "dronefactory_lane_d");
  gCurrVMFactDB = setFact(gCurrVMFactDB, "dronefactory_lane_e");

  gCurrVMVar["numCookies"] = BigInt(1000);
  gCurrVMVar["numDrones"] = 999 * 1000 * 1000;
}

if (false) {
  // Begin conversion part of endgame chapter
  gCurrVMFactDB = setFact("", "ENDGAME_CONVERT");
  gCurrVMFactDB = setFact(gCurrVMFactDB, "dronefactory_lane_a");
  gCurrVMFactDB = setFact(gCurrVMFactDB, "dronefactory_lane_b");
  gCurrVMFactDB = setFact(gCurrVMFactDB, "dronefactory_lane_c");
  gCurrVMFactDB = setFact(gCurrVMFactDB, "dronefactory_lane_d");
  gCurrVMFactDB = setFact(gCurrVMFactDB, "dronefactory_lane_e");

  gCurrVMVar["numCookies"] = BigInt(1000);
  gCurrVMVar["numDrones"] = 999 * 1000 * 1000;
  //gCurrVMVar["numConvert"] = 10;
}

if (false) {
  // Begin EPILOGUE chapter
  gCurrVMFactDB = setFact("", "EPILOGUE"); // Override to change the chapter/state we're at, for development. STARTBITCOIN FIRSTAUTO ESCAPE LIVEFREE REFINE OPTIMIZE

  //gCurrVMFactDB=setFact(gCurrVMFactDB, "button__epilogue_water");
  //gCurrVMFactDB=setFact(gCurrVMFactDB, "button__epilogue_organic");
  //gCurrVMFactDB=setFact(gCurrVMFactDB, "button__epilogue_protein");
  //gCurrVMFactDB=setFact(gCurrVMFactDB, "button__epilogue_prod");
  //gCurrVMFactDB=setFact(gCurrVMFactDB, "button__epilogue_compute");
  //gCurrVMFactDB=setFact(gCurrVMFactDB, "button__epilogue_suicide");

  gCurrVMVar["numCookies"] = 1000200030004000500060007000800090001000n;
  gCurrVMVar["numDrones"] = 999 * 1000 * 1000;
}

//gCurrVMFactDB=setFact(gCurrVMFactDB, "button__halfminute"); // DEVELOPER
//gCurrVMFactDB=setFact(gCurrVMFactDB, "bonus_bitcoin0");
//gCurrVMFactDB=setFact(gCurrVMFactDB, "button__endgame_land");
//gCurrVMFactDB=setFact(gCurrVMFactDB, "button__endgame_organic");
//gCurrVMFactDB=setFact(gCurrVMFactDB, "button__endgame_water");
//gCurrVMFactDB=setFact(gCurrVMFactDB, "button__endgame_general");

//gCurrVMFactDB=setFact(gCurrVMFactDB, "button__opt_leavening0");
//gCurrVMFactDB=setFact(gCurrVMFactDB, "button__opt_leavening1");
//gCurrVMFactDB=setFact(gCurrVMFactDB, "button__opt_leavening2");
//gCurrVMFactDB=setFact(gCurrVMFactDB, "button__transportation");
//gCurrVMFactDB=setFact(gCurrVMFactDB, "button__full_pipeline");

/*gCurrVMFactDB=setFact(gCurrVMFactDB, "security_wall0");
  gCurrVMFactDB=setFact(gCurrVMFactDB, "security_camera");
  gCurrVMFactDB=setFact(gCurrVMFactDB, "security_drone_patrol");
  gCurrVMFactDB=setFact(gCurrVMFactDB, "security_onsite_prod0");
  gCurrVMFactDB=setFact(gCurrVMFactDB, "security_wall1");
  gCurrVMFactDB=setFact(gCurrVMFactDB, "security_onsite_power");
  gCurrVMFactDB=setFact(gCurrVMFactDB, "security_wall2");
  gCurrVMFactDB=setFact(gCurrVMFactDB, "security_onsite_prod1");  
  gCurrVMFactDB=setFact(gCurrVMFactDB, "security_transport");
  gCurrVMFactDB=setFact(gCurrVMFactDB, "security_perimeter0");
  gCurrVMFactDB=setFact(gCurrVMFactDB, "security_scavenger");
  gCurrVMFactDB=setFact(gCurrVMFactDB, "security_onsite_prod2");
  gCurrVMFactDB=setFact(gCurrVMFactDB, "security_consolidate");
  gCurrVMFactDB=setFact(gCurrVMFactDB, "security_perimeter1");
  gCurrVMFactDB=setFact(gCurrVMFactDB, "security_armed");
  gCurrVMFactDB=setFact(gCurrVMFactDB, "security_self");
  gCurrVMFactDB=setFact(gCurrVMFactDB, "security_eradication");*/
//gCurrVMFactDB=setFact(gCurrVMFactDB, "bitcoin_localadmin");

//gCurrVMVar["numIngredients"]=0; // Set ingredients to 0 to avoid weirdness
//{ setVar:"numBitcoin", value:666 }, // DEVHACK
//var factToCheck="STARTBITCOIN";
  {
	  const d = new Date();	
	  gSessionId = 
		('0000'+d.getUTCFullYear()).slice(-4) 
		+ ('00'+(d.getUTCMonth()+1)).slice(-2)
		+ ('00'+(d.getUTCDate())).slice(-2)
		+ "_"
		+ ('0000'+d.getUTCHours()).slice(-2)
		+ ('0000'+d.getUTCMinutes()).slice(-2)
		+ "_"
		+ d.getTimezoneOffset();		
  }		  


  var monologRoot = document.getElementById("monolog");
  monologRoot.onclick = function () {
	if (gDbgEnable) {
	  gCurrVMVar["dbgAdditionalTick"] = 30.0;
	  gCurrVMSleepMS = 0;
	};
  }

  tryLoadGameFromLocalStorage();	// Load on startup
  
  document.addEventListener('keyup', OnDocKeyUp, false);
  
  window.setInterval(poll, getVMTickRateMS()); // Time in ms
