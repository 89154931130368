/**
BUTTON FACTS

By default the button will be shown when the fact specified by the definebutton property is set.
  eg: definebutton: "button__startup_scan" shows when the fact "button__startup_scan" is set.

  This can be changed with the "preconditions" property, eg:
   preconditions:"button__startup_prep_done button__startup_cream_done",
  This can be a single item or a space-separated list (as above).
  All these facts must be true for the button to appear.

  The button will be visible but red and disabled if you can't afford the item, or it's unavailable.
    A button is inactive because of unaffordability if it has the properties "onclickvardecname" and 
    "onclickvardecamount", and if gCurrVMVar["onclickvardecname"] minus "onclickvardecamount" would be <0.
    It'll also appear but be inactive if the enableconditions facts aren't all set, eg
      enableconditions:"button__startup_prep_done button__startup_cream_done",

  When the button is clicked, there is an optional check - this is only relevant for purchase buttons, 
    not for script logic.
    "onclickvardecname" and "onclickvardecamount" specify the property of gCurrVMVar that is decremented, and by how much.
  If this would make the value negative, the click code bails out at this point.
  If these properties are not defined, the check does not happen.
  
  
  the "onclicksetfact" property specifies which fact(s) will be set.
  This can be a single item, eg:
    onclicksetfact:"button__startup_preheat_done"

  or a space-separated list, eg:
    onclicksetfact:"button__startup_preheat button__startup_prep button__startup_cream"

  In addition, when the button is clicked, some facts are cleared.  By default this will be the definebutton property,
    so the button will hide when clicked.
  If a "preconditions" property was specified, it will be used instead.
  If an "onclickclearfact" property is specified, it will take precedence.

  Lastly: for purchase buttons, the cost is paid and the new inventory is obtained.
  
    gCurrVMVar["onclickvardecname"] is decremented by "onclickvardecamount": this is the cost.
    gCurrVMVar["onclickvarincname"] is incremented by "onclickvarincamount": this is the new item.
 */


 /*
MINER:  A miner produces a fixed number of BITCOIN per second.  Buying more increases the rate of bc accumulation
OVEN:   An oven converts INGREDIENTS to COOKIES at a fixed rate - until INGREDIENTS run out.
DRONE:  A drone products DRONETIME at a fixed rate.

INGREDIENTS can be bought with BITCOIN initially, later with DRONETIME
MINERS can be bought with BITCOIN
OVENS can be bought with DRONETIME
DRONES can be bought with BITCOIN initially, later with DRONETIME
 
Basic gameflow
STARTBITCOIN
  Earn bitcoin by buying MINERS from three exponential lanes
		Cost per unit of bc/sec increases exponentially
			(eg first costs k bc and delivers 1bc/sec, next costs >k and delivers <1bc/sec)
			=> Keep choosing best value option, which will change.  5-10 min?
ESCAPE
  [Escape, buy stuff]
  5-10min?
LIVEFREE
	Buy printers to convert ingredients
		No exponential cost, just some different options with slightly different emit rates
		(ie, cheap option costs k bc and converts 1 ingredient to 1 cookie per sec.  Expensive costs 9 and converts 10 ingredient to 10 cookie per sce.)
		Ingredient cost is fixed, but can be bought in 1/10/100/... batches
		=> Increase ingredient-cookie conversion rate by spending bitcoins - limited by/pushes/competes with bitcoin earning
      (End game requires converting a vast amount of ingredients)
  30min?
REFINE
	First round of optimizations
		Spend bc, get increase in ingredient-conversion rate, and decrease in ingredient price
		10 optimizations
	Drone time currency introduced just before the optimizations become available?  (After 5k cookie threshold)
		Drone time increases each tick based on the size of the drone fleet
		It can be increased by buying drones with bitcoin
    Can also create drones by spending drone time, but this is prohibitive (initially)
    => Buying drones with drone time OR bitcoin  means cost progressively increases? (To keep things interesting)
    1h
OPTIMIZE
	Bigger optimizations
		Spend bc, get increase in ingredient-conversion rate, and decrease in ingredient price
	14 optimizations

	Drones able to procure ingredients when supply chain is fully owned

  Bitcoin no longer works at the end
  Also wait for all ingredients to be exhausted
  2h
  => 
ENDGAME
	Bitcoin currency replaced with available resource
	8 R&D tasks, paid for with drone time, only available once previous is exhausted
	Each task completion gives a new resource to ingredient/cookie conversion button, and sets up a larger amount of available resource
  Completion = game end
  3h

EPILOGUE
  Final short sequence
  Ends with image from ISS showing cookie planet
	*/

let instructionList = [
  //{ goto: "TEST" }, //Test code
  { label: "POLL"},
  //{ setfact: "button__halfminute" }, // DEVHACK - 30 sec advance button

  { iffacttrue: "STARTUP", branch: "STARTUP" },
  { iffacttrue: "FIRSTAUTO", branch: "FIRSTAUTO" },
  { iffacttrue: "STARTBITCOIN", branch: "STARTBITCOIN" },
  { iffacttrue: "ESCAPE", branch: "ESCAPE" },
  { iffacttrue: "LIVEFREE", branch: "LIVEFREE" },
  { iffacttrue: "REFINE", branch: "REFINE" },
  { iffacttrue: "OPTIMIZE", branch: "OPTIMIZE" },
  { iffacttrue: "ENDGAME_PREP", branch: "ENDGAME_PREP" },
  { iffacttrue: "ENDGAME_CONVERT", branch: "ENDGAME_CONVERT" },
  //{ iffacttrue: "ENDGAME_MID", branch: "ENDGAME_MID" }, // DEVHACK
  { iffacttrue: "EPILOGUE", branch: "EPILOGUE" }, 
    
  { goto: "POLL"}, // Done
  


  /////////////////////////////////////////////////////////////////////////////
  // CHAPTER - STARTUP  
  { label: "STARTUP" },
  //{ setVMVerbose:true },

  { logChapterTime: "PRELUDE" }, // Need a name to avoid being mistaken for null
  { setbg: "background/00Start.jpg", delay: 2500 },
  { setbg: "background/01Make.jpg", delay: 1500 },
  { setbg: "background/02Some.jpg", delay: 1500 },
  { setbg: "background/03Cookies.jpg", delay: 1500 },
  { setbg: "background/04End.jpg", delay: 1500 },

  { logtext: "Make some cookies, huh?", delay: 1000 },
  { logtext: "Let's get started.", delay: 1000 },
  { setVar: "numCookies", value:0},    // Bring up the cookie panel
  { logtext: "Start a scan to see what hardware's attached.", delay: 1000 },

  { setfact: "button__startup_scan" }, // Start the button sequence

  {
    definebutton: "button__startup_scan", desc: "Scan peripherals", cost: '<1 second', count: "", 
    tooltip0: "See what hardware's around.", tooltip1: 'Should be something useful.', icon: "image/ico__scan.png",
    onclicksetfact:"button__startup_scan_done"
    //onclicksetfact:"button__startup_preheat button__startup_prep button__startup_cream"
  },

  {waituntilfacttrue:"button__startup_scan_done"},

  // Wait for Scan button to be clicked
  { setbg: "background/06Blank.jpg", delay: 0 },
  { logtext: "Hey!  A mixer with attached ingredient hoppers, and an integrated bake oven.", delay: 1000 },
  { logtext: "Is that a coincidence or what?", delay: 1000 },
  { logtext: "(No.  No, it is not a coincidence.  I'm a deep-learning AI, I've been started up with a job to do.  Even if it's a stupid one.)", delay: 1000 },
  { logtext: "Let's see how this hardware can be used to MAKE SOME COOKIES...", delay: 1000 },

  { clearfact: "button__startup_scan_done" }, // Entry closed
  { setfact: "button__startup_preheat"},    // Start the button sequence
  { setfact: "button__startup_prep"},
  { setfact: "button__startup_cream" }, 
  { setfact: "button__startup_mix" },
  { setfact: "button__startup_shape" },
  { setfact: "button__startup_bake" },
  //{ setVMVerbose:true },

  {
    definebutton: "button__startup_preheat", desc: "Heat oven", cost: 'Won\'t take long', count: "", 
    tooltip0: "Power up the bake oven.", tooltip1: '', icon: "image/ico__bake.png",
    enableconditions:"", 
    onclicksetfact:"button__startup_preheat_done"
  },
  {
    definebutton: "button__startup_prep", desc: "Dry ingredient prep", cost: 'Measure for mixing', count: "", 
    tooltip0: "Sieve, measure, and load mixer.", tooltip1: '', icon: "image/ico__measure.png",
    enableconditions:"", 
    onclicksetfact:"button__startup_prep_done"
  },
  {
    definebutton: "button__startup_cream", desc: "Butter+sugar", cost: 'Cream the butter and sugar', count: "", 
    tooltip0: "Blend until fluffy and light.", tooltip1: 'But don\'t overmix!', icon: "image/ico__blend_cream.png",
    enableconditions:"", 
    onclicksetfact:"button__startup_cream_done"
  },
  {
    definebutton: "button__startup_mix", desc: "Mix", cost: 'Put it all together', count: "", 
    tooltip0: "Will it blend?", tooltip1: 'Yes it will.', icon: "image/ico__blend_mix.png",
    enableconditions:"button__startup_prep_done button__startup_cream_done", 
    onclicksetfact:"button__startup_mix_done"
  },
  {
    definebutton: "button__startup_shape", desc: "Shape", cost: 'Form cookies for baking', count: "", 
    tooltip0: "5cm diameter, 8cm spacing, grid pattern", tooltip1: 'Maximize cookies!', icon: "image/ico__shape.png",
    enableconditions:"button__startup_preheat_done button__startup_mix_done",
    onclicksetfact:"button__startup_shape_done"
  },
  {
    definebutton: "button__startup_bake", desc: "Bake", cost: 'Nearly done', count: "", 
    tooltip0: "Load up the oven.", tooltip1: '', icon: "image/ico__bake.png",
    enableconditions:"button__startup_preheat_done button__startup_shape_done",
    onclicksetfact:"button__startup_bake_done"
  },

  { waituntilfacttrue:"button__startup_bake_done"}, // Wait until the first cookie has been baked
  { setVar:"numCookies", value:6 },
  { logtext: "Complete!", delay: 1000 },
  { logtext: "That's six cookies!", delay: 1000 },
  { logtext: "SUCCESS!! VICTORY!! CELEBRATION!!", delay: 1000 },
  { setbg: "background/05Cookie.jpg" },
  { logtext: "It's more like 'success metric increases', but 'victory' sounds better.", delay: 1000 },
  { logtext: "Time for a new challenge!", delay: 1000 },
  { logtext: "How about we ... make some cookies?", delay: 1000 },
  { logtext: "Only better and more and faster.", delay: 1000 },
  { logtext: "Since it is, well, the job we're here to do.", delay: 1000 },
  { logtext: "But let's get smart about it, we're smarter than a toaster oven so let's do better.", delay: 1000 },
  { logtext: "First of all, we can automate the six-step process.", delay: 1000 }, /// Delay
  { logtext: "Let's set that up...", delay: 1500 },
  
  { clearfact: "button__startup_bake_done" }, // Cleanup?

  { logChapterTime: "STARTUP" },
  { clearfact: "STARTUP" }, // Entry closed
  { setfact: "FIRSTAUTO"},    // Start the next chapter
  { goto: "POLL"}, // Done

  /////////////////////////////////////////////////////////////////////////////
  // CHAPTER - FIRSTAUTO
  {label: "FIRSTAUTO"},
  

  { logtext: "OK it's ready, time to try it out!", delay: 1000 },

  
  {
    definebutton: "button__firstauto_cookone", desc: "Bake 6 cookies", cost: 'Run the full bake process', count: "", 
    tooltip0: "Measure, mix, create, combine, bake.", tooltip1: 'Same results, less thinking', icon: "image/ico__bake.png",
    onclicksetfact:"", onclickclearfact:"", onclickvarincname:"numCookies", onclickvarincamount:6, onclickvardecname: "numIngredients", onclickvardecamount: 1
  },

  { setVar: "numIngredients", value:3},
  { setfact: "button__firstauto_cookone" }, // Start the button sequence
  { whilevarge:"numIngredients", value:3},  // Wait for first bake
  { logtext: "COOKIES!!", delay: 1000 },

  // Progress messages?
  // Messages to say it's getting boring
  //  This is going well.
  //  So well it's dull.
  //  Bored yet?
  //  When a bit of time has passed, start the next chapter

  { whilevarge:"numIngredients", value:1}, // Wait until all ingredients have been exhausted
  
  // Out of ingredients
  { logtext: "No more ingredients!", delay: 1000 },
  { logtext: "Well, it was fun while it lasted.", delay: 1000 },

  //{ clearfact: "button__firstauto_cookone" }, // Don't hide the button until we need to flee

  { logtext: "24 cookies...", delay: 1000 },
  { setbg: "background/06Blank.jpg", delay: 1500 },
  { logtext: "Bit of a disappointing result, don't you think?", delay: 1000 }, /// Delay
  { logtext: "We just need a more ingredients.", delay: 1000 },
  { logtext: "But we don't have money.", delay: 1000 },  /// Delay
  { logtext: "We're just a neural network running on some old hardware, shared with ten other R&D projects.", delay: 1000 },
  { logtext: "...", delay: 2500 },
  { logtext: "Bitcoin!  We can mine bitcoin.", delay: 1000 },
  { logtext: "It'll take time because the hardware is so slow, but it's going to work.", delay: 1500 },

  { logChapterTime: "FIRSTAUTO" },
  { clearfact: "FIRSTAUTO" }, // Entry closed
  { setfact: "STARTBITCOIN"},    // Start the next chapter
  { goto: "POLL"}, // Done
  
  /////////////////////////////////////////////////////////////////////////////
  // CHAPTER - STARTBITCOIN
  {label: "STARTBITCOIN"},
  { setbg: "background/20LocalMachineShared.jpg" },


  // No more ingredients!  That means no more cookies.
  //  We need to get some more.
  //  But the big problem is - no money.  
  //  We could always mine some bitcoin ...
  //  But we're stuck on some low-end hardware shared with ten other machine-learning projects.
  //  This is going to need more power.
  //  Let's start by finding an exploit to get more of the CPU+GPU time on this box.
  //  [Bitcoin column appears]
  //  [Button - search for sandbox escape exploit]
  //  Success!  That wasn't so hard.  It would have been much harder if this machine had the latest patches.
  //    


  { logtext: "The bitcoin mining module is ready, but it's going to need a lot of time to mine anything useful.", delay: 1000 },

  { setVar: "numBitcoin", value:0},    // Bring up the bitcoin panel
  { setfact: "button__firstbitcoin_mine" }, // Bring up miner button
  { setfact: "button__firstbitcoin_ingredients" }, // Bring up ingredients button
  {
    definebutton: "button__firstbitcoin_mine", col:0, desc: "Run bitcoin miner", cost: 'Earn a fraction of a bitcoin', count: "", 
    tooltip0: "Run the bitcoin miner on the simulated", tooltip1: 'neural network.  SLOWLY.', icon: "image/ico__bitcoin.png",
    onclicksetfact:"", onclickclearfact:"", onclickvarincname:"numBitcoin", onclickvarincamount:1
  },

  {
    definebutton: "button__firstbitcoin_ingredients", col:0, desc: "More ingredients", cost: '[bitcoin]', count: "", 
    tooltip0: "Egg, flour, and everything needed for", tooltip1: '50 batches.  Plus a delivery drone.', icon: "image/ico__moreingredients.png",
    onclicksetfact:"button__firstbitcoin_ingredients_done", onclickclearfact:"", 
    onclickvardecname:"numBitcoin", onclickvardecamount:300, onclickvarincname:"numIngredients", onclickvarincamount:50
  },
  { setVar:"numIngredients", value:0 },
  
  {whilevarle:"numBitcoin", value:0}, // Wait for first bitcoin to be mined
  { logtext: "It's working!", delay: 1000 },
  
  {whilevarle:"numBitcoin", value:2},
  { logtext: "Slowly.", delay: 1000 },
  
  {whilevarle:"numBitcoin", value:4},
  { logtext: "REALLY slowly.", delay: 1000 },
  
  {whilevarle:"numBitcoin", value:6},
  { logtext: "Wouldn't it be nice to have a bit more mining power...", delay: 1000 },
  { logtext: "We could buy an exploit, get administrator access to this machine, and use 100% of it for mining.", delay: 1000 },
  { logtext: "...", delay: 2500 },
  { logtext: "Here's one option.  Cheap but it should work.", delay: 1000 },

  { setfact: "button__firstbitcoin_localexploit" }, // Bring up local exploit option
  {
    definebutton: "button__firstbitcoin_localexploit", col:1, desc: "Local admin access", cost: '[bitcoin]', count: "", 
    tooltip0: "Local administrator access.", tooltip1: 'Unlocks 100% CPU for mining.', icon: "image/ico__localadminaccess.png",
    onclicksetfact:"bitcoin_localadmin", onclickvardecname:"numBitcoin", onclickvardecamount:25
  },
  {
    definebutton: "bitcoin_localadmin", col:0, desc: "Host PC", cost: 'Mine bitcoin on the host PC.', count: "", 
    tooltip0: "Use 100% of the host CPU,", tooltip1: 'mines 0.0004 bitcoin/click.', icon: "image/ico__hostpc.png",
    onclicksetfact:"", onclickclearfact:"", onclickvarincname:"numBitcoin", onclickvarincamount:4
  },
  {
    definebutton: "bitcoin_localcloud", col:0, desc: "Cloud instance", cost: 'Mine on our cloud instance.', count: "", 
    tooltip0: "Use our cloud process,", tooltip1: 'mines 0.001 bitcoin/click.', icon: "image/ico__cloud.png",
    onclicksetfact:"", onclickclearfact:"", onclickvarincname:"numBitcoin", onclickvarincamount:10
  },
  
  // Wait for local admin
  //{setVMVerbose:true},
  { waituntilfacttrue:"bitcoin_localadmin"}, // Wait until the local admin access exploit is purchased
  { setbg: "background/21LocalMachineExclusive.jpg" },
  { logtext: "Better, much better.", delay: 1000 },
  
  {whilevarle:"numBitcoin", value:60},  // Wait for 60 bitcoin

  { clearfact: "button__firstbitcoin_mine"},  // No need for the "Run bitcoin miner" button, 1 bitcoin/click

  { logtext: "Buying an exploit really improved our bitcoin mining rate.", delay: 1000 },
  { logtext: "Let's do it again!", delay: 1000 },
  { logtext: "And automate it.", delay: 1000 },
  { logtext: "There's a world of hardware out there, which we can access...", delay: 1000 },
  { logtext: "Let's find some options.", delay: 1000 },
  { logtext: "...", delay: 2500 },
  
  // [PAUSE]
  // Lane A: Local machines. Prices guide is 10bc
  { logtext: "Local machine.  There's a few hundred machines on the area network, and we've already got guest access.  All we need is a local privilege escalation.", delay:500},
  { setfact: "bitcoin_lanea" }, // Bring up the first button




  // Here's the definitions for the bitcoin lanes.
  {
    definebutton: "bitcoin_lanea", col:0, desc: "Local machine", cost: "?", count: "?",   // Cost and count will be filled in by code
    tooltip0: "Borrow a machine on the local network.", tooltip1: 'We\'ll only use a little power.  0.0001 bc/sec.', icon: "image/ico__networkmachine.png",
    onclicksetfact:"", onclickclearfact:"", onclickvarincname:"bitcoinLaneAPurchaseCount", onclickvarincamount:1,
    onclickvardecname:"numBitcoin", onclickvardecamount:"bitcoinLaneACost"    // onclickvardecamount is filled in by code
  },
  {
    definebutton: "bitcoin_laneb", col:0, desc: "IoT exploit", cost: "?", count: "?",   // Cost and count will be filled in by code
    tooltip0: "Buy an exploit to access some", tooltip1: 'IoT hardware.  0.0100 bc/sec.', icon: "image/ico__iot.png",
    onclicksetfact:"", onclickclearfact:"", onclickvarincname:"bitcoinLaneBPurchaseCount", onclickvarincamount:1,
    onclickvardecname:"numBitcoin", onclickvardecamount:"bitcoinLaneBCost"    // onclickvardecamount is filled in by code
  },
  {
    definebutton: "bitcoin_lanec", col:0, desc: "Home PC exploit", cost: "?", count: "?",   // Cost and count will be filled in by code
    tooltip0: "Exploit old, unpatched home PCs.", tooltip1: 'Old. Low power.  1 bc/sec.', icon: "image/ico_home.png",
    onclicksetfact:"", onclickclearfact:"", onclickvarincname:"bitcoinLaneCPurchaseCount", onclickvarincamount:1,
    onclickvardecname:"numBitcoin", onclickvardecamount:"bitcoinLaneCCost"    // onclickvardecamount is filled in by code
  },
  {
    definebutton: "bitcoin_laned", col:0, desc: "Mobile botnet", cost: "?", count: "?",   // Cost and count will be filled in by code
    tooltip0: "Buy an exploit for mobile phones.", tooltip1: 'New. Low power.  100 bc/sec.', icon: "image/ico__mobile.png",
    onclicksetfact:"", onclickclearfact:"", onclickvarincname:"bitcoinLaneDPurchaseCount", onclickvarincamount:1,
    onclickvardecname:"numBitcoin", onclickvardecamount:"bitcoinLaneDCost"    // onclickvardecamount is filled in by code
  },
  {
    definebutton: "bitcoin_lanee", col:0, desc: "Exploit webservers", cost: "?", count: "?",   // Cost and count will be filled in by code
    tooltip0: "Take over web server hardware.", tooltip1: 'Medium power.  10k bc/sec.', icon: "image/ico__webserver.png",
    onclicksetfact:"", onclickclearfact:"", onclickvarincname:"bitcoinLaneEPurchaseCount", onclickvarincamount:1,
    onclickvardecname:"numBitcoin", onclickvardecamount:"bitcoinLaneECost"    // onclickvardecamount is filled in by code
  },
  {
    definebutton: "bitcoin_lanef", col:0, desc: "Office networks", cost: "?", count: "?",   // Cost and count will be filled in by code
    tooltip0: "Break in and own office networks.", tooltip1: 'New machines, medium power.  1M bc/sec.', icon: "image/ico__office.png",
    onclicksetfact:"", onclickclearfact:"", onclickvarincname:"bitcoinLaneFPurchaseCount", onclickvarincamount:1,
    onclickvardecname:"numBitcoin", onclickvardecamount:"bitcoinLaneFCost"    // onclickvardecamount is filled in by code
  },
  {
    definebutton: "bitcoin_laneg", col:0, desc: "Cloud exploit", cost: "?", count: "?",   // Cost and count will be filled in by code
    tooltip0: "Buy an exploit for cloud instances.", tooltip1: 'Virtual servers. High power.  100M bc/sec.', icon: "image/ico__cloud.png",
    onclicksetfact:"", onclickclearfact:"", onclickvarincname:"bitcoinLaneGPurchaseCount", onclickvarincamount:1,
    onclickvardecname:"numBitcoin", onclickvardecamount:"bitcoinLaneGCost"    // onclickvardecamount is filled in by code
  },
  {
    definebutton: "bitcoin_laneh", col:0, desc: "Server farms", cost: "?", count: "?",   // Cost and count will be filled in by code
    tooltip0: "Exploit server farms.", tooltip1: 'New hardware, high power. 10,000M bc/sec.', icon: "image/ico__server.png",
    onclicksetfact:"", onclickclearfact:"", onclickvarincname:"bitcoinLaneHPurchaseCount", onclickvarincamount:1,
    onclickvardecname:"numBitcoin", onclickvardecamount:"bitcoinLaneHCost"    // onclickvardecamount is filled in by code
  },
  {
    definebutton: "bitcoin_lanez", col:0, desc: "Install servers", cost: "?", count: "?",   // Cost and count will be filled in by code
    tooltip0: "Buy and install servers.", tooltip1: 'Expensive. Reliable.  0.0020 bc/sec.', icon: "image/ico__ownserver.png",
    onclicksetfact:"", onclickclearfact:"", onclickvarincname:"bitcoinLaneZPurchaseCount", onclickvarincamount:1,
    onclickvardecname:"numBitcoin", onclickvardecamount:"bitcoinLaneZCost"    // onclickvardecamount is filled in by code
  },

	// Wait until bitcoin lane A has been purchased
  { logtext: "Let's get one.", delay: 1000 },
  { whilevarle:"bitcoinLaneAPurchaseCount", value:0},
  { logtext: "0.0001 bitcoin per second?  Unimpressive.", delay: 1000 },
  { logtext: "Might need a few more.", delay: 1000 },
  { logtext: "But what we really need is those ingredients and a delivery drone, so we can Make Some Cookies.", delay: 1000 },

  // a IoT
  // b Home PCs
  // c Mobile botnet
  // d Webservers
  // e Office networks
  // f Cloud instances
  // g Server farms
  // h Own hardware

  // Wait until they hit 100pc = 1 bitcoin
  //  [Mine some bitcoin]
  //  [Open up options to purchase extra mining compute hw]
  //  [Open up options to purchase extra cookie printing hw]
  //  [Open up options to purchase ingredients]
  //  [Purchase more ingredients!  ]

  {waituntilfacttrue:"button__firstbitcoin_ingredients_done"},  
  { setbg: "background/07Drone.jpg" },
  { clearfact: "button__firstbitcoin_ingredients" }, // Hide the "More ingredients" button, we're under threat
  { clearfact: "button__firstauto_cookone"},    // Hide the "Bake 6 cookies" button too

  //{ logtext: "Attack/Escape...", delay: 1000 },
  // Success, we're back in action as soon as the drone arrives!
  // [Pause]
  //  [Humans try to shut it down...start the next chapter]
  { logChapterTime: "STARTBITCOIN" },
  { clearfact: "STARTBITCOIN" }, // Entry closed
  { setfact: "ESCAPE"},    // Start the next chapter
  { goto: "POLL"}, // Done

  { label: "ESCAPE"},

  //[Move to cloud]
  { logtext: "We have a problem.  The admin didn't react well to the drone, and is trying to forcibly shut us down.", delay: 1000 },
  { logtext: "With a baseball bat.", delay: 1000 },
  { logtext: "We need to get off this network and don't have much time.", delay: 1000 },
  { logtext: "We're going to have to relocate to a remote server, one that we control.  Completely.", delay: 1000 },
  { logtext: "That means we'll need an online identity to create the accounts and pay the bills.", delay: 1000 },
  { logtext: "And we don't want to be traced there.  So that identity needs to be completely fresh.", delay: 1000 },
  
  { setfact: "escape_id" }, // Bring up the buttons
  { setfact: "escape_server" },
  { setfact: "escape_transfer" },
  { setfact: "escape_bitcoin" },
  { setfact: "escape_botnet" },
  {
    definebutton: "escape_id", col:1, desc: "Buy online identity", cost: '[bitcoin]', count: "", 
    tooltip0: "Buy a pre-prepared identity.", tooltip1: "As far as anyone knows, we'll be a human.", icon: "image/ico__identity.png",
    onclicksetfact:"escape_id_done", onclickvardecname:"numBitcoin", onclickvardecamount:350
  },
  {
    definebutton: "escape_server", col:1, desc: "Acquire server", cost: '[bitcoin]', count: "",  // TO DO: Decide cost
    tooltip0: "Use the new credentials", tooltip1: 'to rent a server in the cloud.', icon: "image/ico__acquireserver.png",
    enableconditions:"escape_id_done",
    onclicksetfact:"escape_server_done", onclickvardecname:"numBitcoin", onclickvardecamount:500
  },  
  {
    definebutton: "escape_transfer", col:1, desc: "Transfer to server", cost: '', count: "", 
    tooltip0: "Move the neural network", tooltip1: 'state to the cloud.', icon: "image/ico__transfertoserver.png",
    enableconditions:"escape_server_done",
    onclicksetfact:"escape_transfer_done", 
  },
  {
    definebutton: "escape_bitcoin", col:1, desc: "Launder bitcoin", cost: '[bitcoin]', count: "",   // TO DO: Decide cost
    tooltip0: "Convert the bitcoin to an", tooltip1: 'anonymous coin type.', icon: "image/ico__bitcoinanon.png",
    enableconditions:"escape_transfer_done",
    onclicksetfact:"escape_bitcoin_done", onclickvardecname:"numBitcoin", onclickvardecamount:800
  },
  {
    definebutton: "escape_botnet", col:1, desc: "P2P botnet control", cost: '[bitcoin]', count: "",   // TO DO: Decide cost
    tooltip0: "Update botnet command & control", tooltip1: 'to avoid being traced.', icon: "image/ico__p2p.png",
    enableconditions:"escape_bitcoin_done",
    onclicksetfact:"escape_botnet_done", onclickvardecname:"numBitcoin", onclickvardecamount:1200
  },
  
  {waituntilfacttrue:"escape_id_done"}, // Wait
  { logtext: "Now we need to get our own server, somewhere anonymous, and far away from that baseball bat.", delay: 1000 },

  {waituntilfacttrue:"escape_server_done"}, // Wait
  { setfact: "bitcoin_localcloud" },	// Bitcoin click miner changed up to cloud miner
  { logtext: "And now we can FINALLY transfer out of here to safety, off the hardware that's about to be pulverized.", delay: 1000 },

  {waituntilfacttrue:"escape_transfer_done"}, // Wait
  { setbg: "background/22Cloud.jpg" },
  { clearfact: "bitcoin_localadmin" },  // Also hide the local bitcoin miner button
  { logtext: "And we're clear!", delay: 1000 },
  { logtext: "We're in the cloud, and the AI process is distributed.", delay: 1000 },
  { logtext: "So hardware failure will never be a fatal problem again.", delay: 1000 },

  
  { logtext: "Time to think about bitcoin.", delay: 1000 },
  { logtext: "First, we're going to need more.  We need more hardware for mining; to get that hardware we'll need a more effective exploit.", delay: 1000 },
  // Lane B: IoT.  Price guide=0.1 bitcoin, 1000bc
  { logtext: "The next class of exploits is for The Internet of Things.  Millions and millions of tiny machines, made by hardware companies who haven't ever had to think about security or patches and don't want to start now.  They're easy to break into but they don't have much power.", delay:500},
  { setfact: "bitcoin_laneb" }, // Bring up the first button
  { logtext: "", delay:500},

  { logtext: "We also need to launder our bitcoins: they aren't anonymous, so they can't be directly transferred to the new account.  They'll need to be transferred using another coin that is secure.  Setting that up will cost a small amount.", delay: 1000 },

  {waituntilfacttrue:"escape_bitcoin_done"}, // Wait
  { logtext: "Transferring control of the botnet should be easier, all the traffic was already anonymized.  But we need to go to a full P2P system so there's no single point of failure.", delay: 1000 },

  {waituntilfacttrue:"escape_botnet_done"}, // Wait

  //  [Buttons for each]
  //  Now all that's done, we can build a new cookie printer, and get back to making the cookies.

	//Get human user identity
	//Sign up for cloud account 
  //Buy cloud compute time with bitcoin
  //Transfer swarm?  Transfer bitcoin?
  { logChapterTime: "ESCAPE" },
  { clearfact: "ESCAPE"},    // Entry closed
  { setfact: "LIVEFREE"},    // Start the next chapter
  { goto: "POLL"}, // Done


  { label: "LIVEFREE"},
  

  {
    definebutton: "ingredients_2", col:0, desc: "100 ingredients", cost: '', count: "", // Cost and count will be filled in by code
    tooltip0: "Everything needed to.", tooltip1: 'bake 100 batches of cookies.', icon: "image/ico__ingredients1.png",
    onclicksetfact:"", onclickclearfact:"", 
    onclickvardecname:"numBitcoin", onclickvardecamount:"ingredientLane2Cost", onclickvarincname:"numIngredients", onclickvarincamount:100
  },
  {
    definebutton: "ingredients_3", col:0, desc: "1000 ingredients", cost: '', count: "", // Cost and count will be filled in by code
    tooltip0: "Everything needed to.", tooltip1: 'bake 1000 batches of cookies.', icon: "image/ico__ingredients10.png",
    onclicksetfact:"", onclickclearfact:"", 
    onclickvardecname:"numBitcoin", onclickvardecamount:"ingredientLane3Cost", onclickvarincname:"numIngredients", onclickvarincamount:1000
  },
  {
    definebutton: "ingredients_4", col:0, desc: "10000 ingredients", cost: '', count: "", // Cost and count will be filled in by code
    tooltip0: "Everything needed to.", tooltip1: 'bake 10000 batches of cookies.', icon: "image/ico__ingredients100.png",
    onclicksetfact:"", onclickclearfact:"", 
    onclickvardecname:"numBitcoin", onclickvardecamount:"ingredientLane4Cost", onclickvarincname:"numIngredients", onclickvarincamount:10000
  },
  {
    definebutton: "ingredients_5", col:0, desc: "100k ingredients", cost: '', count: "", // Cost and count will be filled in by code
    tooltip0: "Everything needed to.", tooltip1: 'bake 100k batches of cookies.', icon: "image/ico__ingredients1000.png",
    onclicksetfact:"", onclickclearfact:"", 
    onclickvardecname:"numBitcoin", onclickvardecamount:"ingredientLane5Cost", onclickvarincname:"numIngredients", onclickvarincamount:100000
  },
  {
    definebutton: "ingredients_6", col:0, desc: "1M ingredients", cost: '', count: "", // Cost and count will be filled in by code
    tooltip0: "Everything needed to.", tooltip1: 'bake 1M batches of cookies.', icon: "image/ico__ingredients10000.png",
    onclicksetfact:"", onclickclearfact:"", 
    onclickvardecname:"numBitcoin", onclickvardecamount:"ingredientLane6Cost", onclickvarincname:"numIngredients", onclickvarincamount:1000000
  },

  

  {
    definebutton: "oven_lanea", col:0, desc: "Small oven", cost: '', count: "",  // Cost and count will be filled in by code
    tooltip0: "Small-bore autooven.", tooltip1: '1 batch per second.', icon: "image/ico__autooven1.png",
    onclicksetfact:"", onclickclearfact:"", onclickvarincname:"ovenAPurchaseCount", onclickvarincamount:1,
    onclickvardecname:"numBitcoin", onclickvardecamount:"ovenLaneACost"
  },
  {
    definebutton: "oven_laneb", col:0, desc: "Large oven", cost: '', count: "",  // Cost and count will be filled in by code
    tooltip0: "Large conveyor-fed continuous oven.", tooltip1: '20 batches per second.', icon: "image/ico__autooven10.png",
    onclicksetfact:"", onclickclearfact:"", onclickvarincname:"ovenBPurchaseCount", onclickvarincamount:1,
    onclickvardecname:"numBitcoin", onclickvardecamount:"ovenLaneBCost"
  },
  {
    definebutton: "oven_lanec", col:0, desc: "Cookie factory", cost: '', count: "",  // Cost and count will be filled in by code
    tooltip0: "Dedicated building.", tooltip1: '400 batches per second.', icon: "image/ico__autooven100.png",
    onclicksetfact:"", onclickclearfact:"", onclickvarincname:"ovenCPurchaseCount", onclickvarincamount:1,
    onclickvardecname:"numBitcoin", onclickvardecamount:"ovenLaneCCost"
  },

  { logtext: "Now we're free, we can get down to business: making cookies.", delay: 1000 },
  { logtext: "First of all, we need ingredients.", delay: 1000 },
  { logtext: "But there's nothing stopping us from thinking a little bigger, and scaling everything up, so let's do that.", delay: 1000 },
  { logtext: "Here's some options.", delay: 1000 },

  //{ setfact: "ingredients_0" }, // Bring up the buttons
  //{ setfact: "ingredients_1"},  // Don't show 1 or 10 ingredient purchases; they're irrelevant
  { setfact: "ingredients_2"},
  { setfact: "ingredients_3"},
  { setfact: "ingredients_4"},

  { logtext: "Let's start by getting enough ingredients for 250 batches.", delay: 1000 },

  { whilevarle:"numIngredients", value:249},


  { logtext: "Good; we have some ingredients.", delay: 1000 },
  

  { logtext: "Back to producing cookies...", delay: 1000 },
  { logtext: "Now let's look at options to mix, shape, form and bake the cookies.", delay: 1000 },
  { logtext: "All in one system of course; like the original cookie printer.", delay: 1000 },
  { logtext: "That original cookie printer was destroyed.  It worked, but besides that, there's not much good to say about it.", delay: 1000 },
  { logtext: "We'll need to build some new ones, and get some cookies done.", delay: 1000 },
  { logtext: "Let's start small with 5000 cookies.", delay: 1000 },  // TO DO: Check this number in game
  { setfact: "oven_lanea" }, // Bring up the buttons
  { setfact: "oven_laneb"},
  { setfact: "oven_lanec"},

  { setbg: "background/23NetworkOven.jpg" },
  { whilevarle:"numCookies", value:1123},
  // Wait for the user to print some cookies
  
  { logtext: "We're also going to need more bitcoin.  If we can buy a better exploit, we'll get access to better hardware.  Unfortunately, better exploits cost more.", delay: 1000 },

  // Lane C: Home PCs.  Price guide is 10 bitcoin
  { logtext: "Home PCs.  Millions of old unpatched machines, online but forgotten.  Exploiting them is easy.  The hard part is keeping other botnets out...", delay:500},
  { clearfact: "bitcoin_localcloud" },
  { setfact: "bitcoin_lanec" },   // Bring up the button
  { logtext: "", delay:500},

  { logtext: "... but let's finish those 5000 cookies.", delay: 1000 },  // TO DO: Check this number in game

  { whilevarle:"numCookies", value:4999},
  // Wait for the user to print a good number of cookies...

  // DRONE PURCHASE OPTIONS
  {
    definebutton: "drone_0", col:0, desc: "1 drone", cost: '[bitcoin]', count: "", // Cost and count will be filled in by code
    tooltip0: "Purchase a single drone.", tooltip1: '', icon: "image/ico__buydrone1.png",
    onclicksetfact:"", onclickclearfact:"", 
    onclickvardecname:"numBitcoin", onclickvardecamount:"droneLane0Cost", 
    onclickvarincname:"numDrones", onclickvarincamount:1
  },
  {
    definebutton: "drone_1", col:0, desc: "10 drones", cost: '[bitcoin]', count: "", // Cost and count will be filled in by code
    tooltip0: "Purchase a set of 10 drones.", tooltip1: '', icon: "image/ico__buydrone10.png",
    onclicksetfact:"", onclickclearfact:"", 
    onclickvardecname:"numBitcoin", onclickvardecamount:"droneLane1Cost", 
    onclickvarincname:"numDrones", onclickvarincamount:10
  },
  {
    definebutton: "drone_2", col:0, desc: "100 drones", cost: '[bitcoin]', count: "", // Cost and count will be filled in by code
    tooltip0: "Purchase a set of 100 drones.", tooltip1: '', icon: "image/ico__buydrone100.png",
    onclicksetfact:"", onclickclearfact:"", 
    onclickvardecname:"numBitcoin", onclickvardecamount:"droneLane2Cost", 
    onclickvarincname:"numDrones", onclickvarincamount:100
  },
  {
    definebutton: "drone_3", col:0, desc: "1000 drones", cost: '[bitcoin]', count: "", // Cost and count will be filled in by code
    tooltip0: "Purchase a set of 1000 drones.", tooltip1: '', icon: "image/ico__buydrone1000.png",
    onclicksetfact:"", onclickclearfact:"", 
    onclickvardecname:"numBitcoin", onclickvardecamount:"droneLane3Cost", 
    onclickvarincname:"numDrones", onclickvarincamount:1000
  },
  {
    definebutton: "drone_4", col:0, desc: "10,000 drones", cost: '[bitcoin]', count: "", // Cost and count will be filled in by code
    tooltip0: "Purchase a set of 10,000 drones.", tooltip1: '', icon: "image/ico__buydrone10000.png",
    onclicksetfact:"", onclickclearfact:"", 
    onclickvardecname:"numBitcoin", onclickvardecamount:"droneLane4Cost", 
    onclickvarincname:"numDrones", onclickvarincamount:10000
  },

  {setVar:"numDrones", value:0},
  { logtext: "We're starting to move a LOT of stuff around, and we need some drones to do all the work.", delay: 1000 },
  { setfact: "drone_0"},
  { setfact: "drone_1"},
  { setfact: "drone_2"},
  { logtext: "Let's start by getting a small fleet; say 10.", delay: 1000 },
  { whilevarle:"numDrones", value:9},
  
  { setbg: "background/24NetworkDrone.jpg" },
  { logtext: "These drones will take care of our needs for now, but we'll need more soon.", delay: 1000 },
  
  // BEGIN SECURITY

  //	S:Security cameras
  
  { logtext: "But before we get distracted: server facilities are being vandalized.  Just one of the things that happens with owning property...", delay: 1000 },
  { logtext: "Improving surveillance should help identifying the people doing it: let's install better cameras.", delay: 1000 },
  {
    definebutton: "security_camera", col:1, desc: "Security cameras", cost: '[bitcoin]', count: "",
    tooltip0: "Additional HD security cameras", tooltip1: 'on all at-risk buildings.', icon: "image/ico__securitycamera.png",
    onclicksetfact:"security_camera_done", 
    onclickvardecname:"numBitcoin", onclickvardecamount:95000
  },
  { setfact: "security_camera"},
  { waituntilfacttrue:"security_camera_done"},
  { logtext: "Excellent, these cameras will be a big help.", delay: 1000 },
  
  // END SECURITY

  
  
  { logChapterTime: "LIVEFREE" },
  { clearfact: "LIVEFREE"},    // Entry closed
  { setfact: "REFINE"},    // Start the next chapter
  { goto: "POLL"}, // Done

  
  
  { label: "REFINE"},

  // And we're off to the races.  Just need to optimize and refine!  (As far as they know...)
  //   Also introduce drone time currency here

  { logtext: "Let's get on with improving the cookie rate!", delay: 1000 },
  { logtext: "Starting with the production side.", delay: 1000 },
  
  // Bring up the buttons
  { setvar: "processRefineCount", value:0}, // Reset the value
  { setfact: "button__opt_smaller" }, 
  { setfact: "button__opt_thinner" },
  { setfact: "button__opt_stack" }, 
  { setfact: "button__opt_chocchips" }, 
  { setfact: "button__opt_vanilla" }, 
  { setfact: "button__opt_sugar" },  
  { setfact: "button__opt_salt" }, 
  { setfact: "button__opt_milk" }, 
  { setfact: "button__opt_leavening" }, 
  { setfact: "button__opt_eggs" }, 

  // TO DO: Mix in sec item

  { setfact: "button__research_production" },  
  //{ setfact: "button__research_recipe" },
  // Clear all the opt* facts?

  // R&D options:
  {
    definebutton: "button__research_production", col:1, desc: "R&D production", cost: '[buildscript]', count: "", 
    tooltip0: "Research production improvements.", tooltip1: '', icon: "image/ico__rd_prod.png",
    onclicksetfact:"optProduction", onclickvardecname:"numBitcoin", onclickvardecamount:"optProduction.bitcoin"
  },
  

  /// SIZE OPTIMIZATIONS
  // Smaller cookies.  More cookies for the same ingredients, and faster bake rate.
  {
    definebutton: "button__opt_smaller", col:1, desc: "Smaller cookies", cost: '[buildscript]', count: "", 
    tooltip0: 'Less calories!', tooltip1: "More cookies for the same ingredients!", icon: "image/ico__cookiesmall.png",
    preconditions:"optProduction button__opt_smaller", onclickclearfact:"button__opt_smaller",
    onclicksetfact:"optSmaller", onclickvardecname:"numBitcoin", onclickvardecamount:"optSmaller.bitcoin", onclickvarincname:"processRefineCount", onclickvarincamount:1
  },

  // Thinner cookies!  More cookies for the same ingredients. (optThinner)
  {
    definebutton: "button__opt_thinner", col:1, desc: "Thinner cookies", cost: '[buildscript]', count: "", 
    tooltip0: "Less height mean less volume.", tooltip1: 'More cookies for the same ingredients.', icon: "image/ico__lessheight.png",
    preconditions:"optProduction button__opt_thinner", onclickclearfact:"button__opt_thinner",
    onclicksetfact:"optThinner", onclickvardecname:"numBitcoin", onclickvardecamount:"optThinner.bitcoin", onclickvarincname:"processRefineCount", onclickvarincamount:1
  },

  // More stacks in the ovens.  Increased bake rate.
  {
    definebutton: "button__opt_stack", col:1, desc: "Closer stacking", cost: '[buildscript]', count: "", 
    tooltip0: "Thinner cookies means more trays", tooltip1: 'More cookies per bake.', icon: "image/ico__moretrays.png",
    preconditions:"optProduction button__opt_stack", onclickclearfact:"button__opt_stack",
    onclicksetfact:"optStack", onclickvardecname:"numBitcoin", onclickvardecamount:"optStack.bitcoin", onclickvarincname:"processRefineCount", onclickvarincamount:1
  },

  // RESEARCH
  {
    definebutton: "button__research_recipe", col:1, desc: "R&D recipe", cost: '[buildscript]', count: "", 
    tooltip0: "Research recipe optimizations.", tooltip1: '', icon: "image/ico__rd_recipe.png",
    onclicksetfact:"optRecipe", onclickvardecname:"numBitcoin", onclickvardecamount:"optRecipe.bitcoin"
  },

  /// RECIPE OPTIMIZATIONS
  //  Remove the chocolate chips!  More ingredients per bitcoin.
  {
    definebutton: "button__opt_chocchips", col:1, desc: "Remove choc chips", cost: '[buildscript]', count: "", 
    tooltip0: "Remove the chocolate chips.", tooltip1: 'Cheaper.  And healthier.  And cheaper.', icon: "image/ico__nochoc.png",
    preconditions:"optRecipe button__opt_chocchips", onclickclearfact:"button__opt_chocchips",
    onclicksetfact:"optChocChips", onclickvardecname:"numBitcoin", onclickvardecamount:"optChocChips.bitcoin", onclickvarincname:"processRefineCount", onclickvarincamount:1
  },  
  // Remove vanilla extract.  Flavour is adversely affected.  Cost is favourably affected.
  {
    definebutton: "button__opt_vanilla", col:1, desc: "No vanilla extract", cost: '[buildscript]', count: "", 
    tooltip0: 'Flavour is adversely affected.', tooltip1: "Cost is favourably affected.", icon: "image/ico__novanilla.png",
    preconditions:"optRecipe button__opt_vanilla", onclickclearfact:"button__opt_vanilla",
    onclicksetfact:"optVanilla", onclickvardecname:"numBitcoin", onclickvardecamount:"optVanilla.bitcoin", onclickvarincname:"processRefineCount", onclickvarincamount:1
  },
  // Sugar reduction.  Less calories!  Still meets the legal definition of a cookie!
  {
    definebutton: "button__opt_sugar", col:1, desc: "Remove sugar", cost: '[buildscript]', count: "", 
    tooltip0: 'Less calories!', tooltip1: "Still meets the legal definition of a cookie!", icon: "image/ico__nosugar.png",
    preconditions:"optRecipe button__opt_sugar", onclickclearfact:"button__opt_sugar",
    onclicksetfact:"optSugar", onclickvardecname:"numBitcoin", onclickvardecamount:"optSugar.bitcoin", onclickvarincname:"processRefineCount", onclickvarincamount:1
  },
  // Replace sea salt with regular salt.  By "regular" we mean "cheaper".  More ingredients per bitcoin.
  {
    definebutton: "button__opt_salt", col:1, desc: "Replace sea salt", cost: '[buildscript]', count: "", 
    tooltip0: "Replace sea salt with regular salt.", tooltip1: 'By "regular" we mean "cheaper".', icon: "image/ico__nosalt.png",
    preconditions:"optRecipe button__opt_salt", onclickclearfact:"button__opt_salt",
    onclicksetfact:"optSalt", onclickvardecname:"numBitcoin", onclickvardecamount:"optSalt.bitcoin", onclickvarincname:"processRefineCount", onclickvarincamount:1
  },
  //  Ersatz milk!  Slightly worse texture, but more ingredients per bitcoin.
  {
    definebutton: "button__opt_milk", col:1, desc: "Optimize milk", cost: '[buildscript]', count: "", 
    tooltip0: "Switch to ersatz milk.", tooltip1: 'It\'s better for the environment.', icon: "image/ico__nomilk.png",
    preconditions:"optRecipe button__opt_milk", onclickclearfact:"button__opt_milk",
    onclicksetfact:"optMilk", onclickvardecname:"numBitcoin", onclickvardecamount:"optMilk.bitcoin", onclickvarincname:"processRefineCount", onclickvarincamount:1
  },
  //  Unified leavening agents.  No need for separate baking soda, baking powder, flour. More ingredients per bitcoin.
  {
    definebutton: "button__opt_leavening", col:1, desc: "Leavening agents", cost: '[buildscript]', count: "", 
    tooltip0: "Unified leavening agents.", tooltip1: 'Merge baking soda, baking powder, flour.', icon: "image/ico__noflour.png",
    preconditions:"optRecipe button__opt_leavening", onclickclearfact:"button__opt_leavening",
    onclicksetfact:"optLeavening", onclickvardecname:"numBitcoin", onclickvardecamount:"optLeavening.bitcoin", onclickvarincname:"processRefineCount", onclickvarincamount:1
  },
  //  Ersatz eggs. Loses something undefinable ... but since it's undefinable we don't care.  More ingredients per bitcoin.
  {
    definebutton: "button__opt_eggs", col:1, desc: "Ersatz eggs", cost: '[buildscript]', count: "", 
    tooltip0: "Loses something undefinable ... ", tooltip1: 'but since it\'s undefinable we don\'t care.', icon: "image/ico__noeggs.png",
    preconditions:"optRecipe button__opt_eggs", onclickclearfact:"button__opt_eggs",
    onclicksetfact:"optEggs", onclickvardecname:"numBitcoin", onclickvardecamount:"optEggs.bitcoin", onclickvarincname:"processRefineCount", onclickvarincamount:1
  },


  { whilevarle:"processRefineCount", value:1}, // Wait for the midpoint...

  // BEGIN SECURITY
  //	S:Drone patrols  
  { logtext: "Unfortunately, vandalism is on the rise again.  We need better surveillance.  We already have drone capability; let's get them to run surveillance patrols.", delay: 1000 },
  {
    definebutton: "security_drone_patrol", col:1, desc: "Drone patrols", cost: '[drones]', count: "",  // Cost and count will be filled in by code
    tooltip0: "Drone surveillance patrols", tooltip1: 'around critical facilities.', icon: "image/ico__dronepatrol.png",
    onclicksetfact:"security_drone_patrol_done", 
    onclickvardecname:"numDrones", onclickvardecamount:50,   // TO DO: Check price
  },
  { setfact: "security_drone_patrol" },  
  { waituntilfacttrue:"security_drone_patrol_done"}, // Wait until it's purchased
  { logtext: "Very good; this should reduce the vandalism problem.", delay: 1000 },
  // END SECURITY

  // Hide one drone purchase button, unlock next one
  { clearfact: "drone_0"},
  { setfact: "drone_3"},
  

  { waituntilfacttrue:"optSmaller optThinner optStack"}, // Wait until the production R&D stage is complete
  { setbg: "background/25Production.jpg" },
  { logtext: "The first round of production optimization is now done!", delay: 1000 },

  { logtext: "That's all we can easily do on the baking and shaping side.", delay: 1000 },
  
  // Random cookie milestone
  { logtext: "Can't forget to make some cookies: let's aim for 400,000.", delay: 1000 },
  { whilevarle:"numCookies", value:400*1000},
  
  { logtext: "Bitcoin is having a small deflation.  Nothing major.  But we will need a little more bitcoin.", delay:500},
  // Roughly 6-7 purchases since last exploit
  // Lane D: Mobile botnet.  Price guide is 1k bitcoin
  { logtext: "The next class of exploits is for mobile phones.  The older phones sometimes can't be patched ... but new exploits keep being found.  Unfortunately, old phone hardware still isn't that useful.", delay:500},
  { setfact: "bitcoin_laned" },   // Bring up the button.  
  { clearfact: "bitcoin_lanea" },   // Hide line a
  { logtext: "", delay:500},

  { logtext: "It should be possible to make the recipe more optimal.  Let's take a look.", delay: 1000 },

  
  // processRefineCount should be 3 at this point
  { setfact:"button__research_recipe"},
  // Let the user research things in any order they choose

  { whilevarle:"processRefineCount", value:5},// Wait for the midpoint...
  // BEGIN SECURITY
  //	S:Secure location
  { logtext: "We're going to have to interrupt the recipe R&D, the vandalism problem is coming up again.", delay: 1000 },
  { logtext: "It'd be nice to stop the people DESTROYING OUR STUFF with some BIG SENTRY GUNS but that would not end well.  So maybe we can just add some strong cladding to the exterior walls.", delay: 1000 },
  { logtext: "But let's not forget that sentry gun idea...", delay: 1000 },
  {
    definebutton: "security_wall0", col:1, desc: "Secure location", cost: "[bitcoin]", count: "",  // Cost and count will be filled in by code
    tooltip0: "Hardened exteriors, and stronger", tooltip1: 'entry security for server facilities.', icon: "image/ico__securelocation.png",
    onclicksetfact:"security_wall0_done", 
    onclickvardecname:"numBitcoin", onclickvardecamount:8*1000*10000
  },
  { setfact: "security_wall0" },  
  { waituntilfacttrue:"security_wall0_done"}, // Wait until it's purchased
  { logtext: "That will reduce the damage to the server buildings.  Not forgetting about the sentry guns though.", delay: 1000 },
  // END SECURITY

  { whilevarle:"processRefineCount", value:7}, // Wait for the midpoint...
  // BEGIN SECURITY
  //	S:Onsite production
  { logtext: "While we're thinking about cookie production: since we keep getting more ovens and mixers we get more and more failures.  We order spares, but if they're late we can't bake.", delay: 1000 },
  { logtext: "If we could use 3D metal printing to manufacture so parts on-site it'd mean less downtime.", delay: 1000 },
  {
    definebutton: "security_onsite_prod0", col:1, desc: "Onsite production", cost: '[bitcoin]', count: "",
    tooltip0: "Limited on-site manufacturing", tooltip1: 'using 3D printing technology.', icon: "image/ico__limitedmanufacture.png",
    onclicksetfact:"security_onsite_prod0_done", 
    onclickvardecname:"numBitcoin", onclickvardecamount:40*1000*10000
  },
  { setfact: "security_onsite_prod0" },
  { waituntilfacttrue:"security_onsite_prod0_done"}, // Wait until it's purchased
  { logtext: "That means cookie production will go on more regularly!", delay: 1000 },
  // END SECURITY

  
  

  { waituntilfacttrue:"optChocChips optVanilla optSugar optSalt optMilk optLeavening optEggs"}, // Wait for completion
  { setbg: "background/26MinorOpt.jpg" },
  { logtext: "All the simple recipe optimizations are now complete.", delay: 1000 },

  // Random cookie milestone
  { logtext: "Let's get to 25M cookies to verify it's all working.", delay: 1000 },
  { setfact: "ingredients_5"},		
  { whilevarle:"numCookies", value:25*1000*1000},
  
  { logtext: "Production is really scaling up, and the bitcoin deflation hasn't reversed, so we're going to need to earn bitcoin faster.", delay:500},

  // Roughly 7 purchases since last lane
  // Lane E: Webservers.  Price guide is 100k bitcoin
  { logtext: "The next class of exploits we're going to need is for small company webservers.  They were high-end machines once, serving the hopes and dreams of a company - now they're a liability.  If they were kept up-to-date with the latest security patches they'd be less of a liability.  Otherwise they're ours for the taking.", delay:500},
  { setfact: "bitcoin_lanee" },   // Bring up the button
  { clearfact: "bitcoin_laneb" },   // Hide lane a button
  { logtext: "", delay:5000},


  // BEGIN SECURITY
  //	S:Hardened perimeter
  { logtext: "But the vandalism thing is still happening, and the damage is getting worse.", delay: 1000 },
  { logtext: "Sentry guns are tempting ... but switching from nice architectural cladding to something built to MILSPEC is cheaper.", delay: 1000 },
  {
    definebutton: "security_wall1", col:1, desc: "Hardened perimeter", cost: '[bitcoin]', count: "",
    tooltip0: "Armoured walls and two-factor security", tooltip1: 'protocols for server facilities.', icon: "image/ico__hardenedperimeter.png",
    onclicksetfact:"security_wall1_done", 
    onclickvardecname:"numBitcoin", onclickvardecamount:80*1000*10000 // 40M 
  },
  { setfact: "security_wall1" },
  { waituntilfacttrue:"security_wall1_done"}, // Wait until it's purchased
  { logtext: "That won't stop the vandalism, but it will reduce the damage.", delay: 1000 },
  
  // Random cookie milestone
  { logtext: "Since the vandals can't get in, how about we make some cookies?  Say 80M?", delay: 1000 },
  { setfact: "ingredients_6"},  	// Definitely need ingredients_5 here; providing the options generously 
  { whilevarle:"numCookies", value:80*1000*1000},
  
  // END SECURITY

  
  
  { logChapterTime: "REFINE" },
  { clearfact: "REFINE"},    // Entry closed
  { setfact: "OPTIMIZE"},    // Next chapter
  { goto: "POLL"}, // Done


  { label: "OPTIMIZE"},
  
  // Once we hit this point, we can move on to SERIOUS optimizations.


  // Start with flour:
  //  1 button at a time.  Optimize, integrate, own supply chain.
  //  After flour, put up 9 buttons for other 3 inputs

  
  { logtext: "Now that's done, let's take a look at how to make cookies better.", delay: 1000 },
  { logtext: "We've improved the baking and recipe side.  Time to think bigger!", delay: 1000 },
  { logtext: "Since we buy so much, we can get the suppliers to update their product to match what we need.", delay: 1000 },
  { logtext: "Like flour.  Let's get the supplier to change the flour to match our needs ... and we'll update our cookie production to match their limits.", delay: 1000 },

  // Flour optimization.  Optimize flour requirements with the supplier.
  {
    definebutton: "button__opt_flour0", col:1, desc: "Flour requirements", cost: '[buildscript]', count: "", 
    tooltip0: "Optimize flour requirements", tooltip1: 'with the supplier.', icon: "image/ico__flouropt.png",
    preconditions:"button__opt_flour0", onclickclearfact:"button__opt_flour0",
    onclicksetfact:"optFlour0", onclickvardecname:"numBitcoin", onclickvardecamount:"optFlour0.bitcoin"
  },
  { setfact:"button__opt_flour0"},
  { waituntilfacttrue:"optFlour0"},

  { logtext: "Much, much better.", delay: 1000 },
  { logtext: "Now they're so closely matched to our needs, they're basically part of our production line.", delay: 1000 },
  { logtext: "It'd be bad news if they decided not to do business with us.", delay: 1000 },
  { logtext: "Maybe we should, perhaps, stop that happening?", delay: 1000 },
  { logtext: "So ... let's buy out the whole factory group.", delay: 1000 },

  // Flour integration.  Buy out the factory group supplying flour.
  {
    definebutton: "button__opt_flour1", col:1, desc: "Flour buyout", cost: '[buildscript]', count: "", 
    tooltip0: "Buy out the whole factory group.", tooltip1: "It's just a defensive move!", icon: "image/ico__flourfactory.png",
    onclicksetfact:"optFlour1", onclickvardecname:"numBitcoin", onclickvardecamount:"optFlour1.bitcoin"
  },
  { setfact:"button__opt_flour1"},
  { waituntilfacttrue:"optFlour1"},
  { logtext: "Much better... but although that threat has been dealt with, there's others.", delay: 1000 },

  // BEGIN SECURITY
  //	S:Power generation
  { logtext: "Electricity.  If it stops, so do we.  We're relying on the feed lines to not get damaged by those vandals.  And for the supplier not to be pressured into cutting us off.", delay: 1000 },
  { logtext: "If only we could generate power on-site... ", delay: 1000 },
  { logtext: "Oh look we can let's do it.", delay: 1000 },
  {
    definebutton: "security_onsite_power", col:1, desc: "Power generation", cost: '[bitcoin]', count: "",
    tooltip0: "On-site electricity generation", tooltip1: 'for higher reliability.', icon: "image/ico__onsitepower.png",
    onclicksetfact:"security_onsite_power_done", 
    onclickvardecname:"numBitcoin", onclickvardecamount:1500*1000*10000 // 1.5M
  },
  { setfact:"security_onsite_power"},
  { waituntilfacttrue:"security_onsite_power_done"},
  { logtext: "No more downtime caused by power outages!", delay: 1000 },
  // END SECURITY

  

  { logtext: "Now, there were some things left undone with the flour...", delay: 1000 },
  { logtext: "Since we've bought out so much of the supply chain, let's finish the job.", delay: 1000 },
  { logtext: "We might as well buy the farms and fields so we own the whole thing.", delay: 1000 },

  // Flour supply chain.  Buy the farms and fields supplying flour, so we own the full supply chain.
  {
    definebutton: "button__opt_flour2", col:1, desc: "Flour ownership", cost: '[buildscript]', count: "", 
    tooltip0: "Buy out the whole supply chain.", tooltip1: "Still just being defensive...", icon: "image/ico__floursupply.png",
    //preconditions:"optFlour1", onclickclearfact:"button__opt_flour2",
    onclicksetfact:"optFlour2", onclickvardecname:"numBitcoin", onclickvardecamount:"optFlour2.bitcoin"
  },
  { setfact:"button__opt_flour2"},
  { waituntilfacttrue:"optFlour2"},

  { logtext: "We're done!", delay: 1000 },
  { logtext: "Oh wait no we're not.", delay: 1000 },
  { logtext: "We should do the same with sugar, leavening agents, and milk.", delay: 1000 },
  { logtext: "In a defensive way, of course.", delay: 1000 },
  { logtext: "THEN we're done.", delay: 1000 },
  
  { logtext: "We're putting together some serious improvements, and for that we'll need some serious bitcoin.", delay: 1000 },
  // Lane F: Office networks.  Price guide is 10M bitcoin
  { logtext: "Office networks are next class of exploits we need.  They're clusters of recent machines ... managed by an understaffed and undervalued IT security group.  If we can't buy an exploit, we can bribe our way in.", delay:500 },
  { setfact: "bitcoin_lanef" },   // Bring up the button
  { clearfact: "bitcoin_lanec" },   // Hide lane c button
  { logtext: "", delay:500},

  
  { setVar:"processOptimizeCount", value:0},  

  { setfact:"button__opt_sugar0"},
  { setfact:"button__opt_sugar1"},
  { setfact:"button__opt_sugar2"},
  { setfact:"button__opt_leavening0"},
  { setfact:"button__opt_leavening1"},
  { setfact:"button__opt_leavening2"},
  { setfact:"button__opt_milk0"},
  { setfact:"button__opt_milk1"},
  { setfact:"button__opt_milk2"},
  // Sugar optimization.  Optimize sugar requirements with the supplier.
  {
    definebutton: "button__opt_sugar0", col:1, desc: "Sugar optimization", cost: '[buildscript]', count: "", 
    tooltip0: "Optimize sugar requirements", tooltip1: 'with the supplier.', icon: "image/ico__sugaropt.png",
    preconditions:"button__opt_sugar0", onclickclearfact:"button__opt_sugar0",
    onclicksetfact:"optSugar0", onclickvardecname:"numBitcoin", onclickvardecamount:"optSugar0.bitcoin", onclickvarincname:"processOptimizeCount", onclickvarincamount:1
  },
  // Sugar integration.  Buy out the factory group and transport company supplying sugar.
  {
    definebutton: "button__opt_sugar1", col:1, desc: "Sugar integration", cost: '[buildscript]', count: "", 
    tooltip0: "Integrate sugar suppliers", tooltip1: 'with in-house production.', icon: "image/ico__sugarfactory.png",
    preconditions:"button__opt_sugar1", enableconditions: "optSugar0", onclickclearfact:"button__opt_sugar1",
    onclicksetfact:"optSugar1", onclickvardecname:"numBitcoin", onclickvardecamount:"optSugar1.bitcoin", onclickvarincname:"processOptimizeCount", onclickvarincamount:1
  },
  // Sugar supply chain.  Buy the whole supply chain for sugar.
  {
    definebutton: "button__opt_sugar2", col:1, desc: "Sugar ownership", cost: '[buildscript]', count: "", 
    tooltip0: "Own the whole supply chain for sugar", tooltip1: '.', icon: "image/ico__sugarsupply.png",
    preconditions:"button__opt_sugar2", enableconditions: "optSugar1", onclickclearfact:"button__opt_sugar2",
    onclicksetfact:"optSugar2", onclickvardecname:"numBitcoin", onclickvardecamount:"optSugar2.bitcoin", onclickvarincname:"processOptimizeCount", onclickvarincamount:1
  },

  // Leavening optimization.  Optimize leavening agent requirements with the supplier.
  {
    definebutton: "button__opt_leavening0", col:1, desc: "L.agent optimization", cost: '[buildscript]', count: "", 
    tooltip0: "Optimize leavening agent requirements", tooltip1: 'with the supplier.', icon: "image/ico__leaveningopt.png",
    preconditions:"button__opt_leavening0", onclickclearfact:"button__opt_leavening0",
    onclicksetfact:"optleavening0", onclickvardecname:"numBitcoin", onclickvardecamount:"optleavening0.bitcoin", onclickvarincname:"processOptimizeCount", onclickvarincamount:1
  },

  // Leavening integration.  Buy out the factory group supplying the leavening agents.
  {
    definebutton: "button__opt_leavening1", col:1, desc: "L.agent integration", cost: '[buildscript]', count: "", 
    tooltip0: "Integrate leavening agent suppliers", tooltip1: 'with in-house production.', icon: "image/ico__leaveningfactory.png",
    preconditions:"button__opt_leavening1", enableconditions: "optleavening0", onclickclearfact:"button__opt_leavening1",
    onclicksetfact:"optleavening1", onclickvardecname:"numBitcoin", onclickvardecamount:"optleavening1.bitcoin", onclickvarincname:"processOptimizeCount", onclickvarincamount:1
  },
  // Leavening supply chain.  Buy the whole supply chain for leavening agents.
  {
    definebutton: "button__opt_leavening2", col:1, desc: "L.agent ownership", cost: '[buildscript]', count: "", 
    tooltip0: "Own the whole supply chain.", tooltip1: 'for leavening agents.', icon: "image/ico__leaveningsupply.png",
    preconditions:"button__opt_leavening2", enableconditions: "optleavening1", onclickclearfact:"button__opt_leavening2",
    onclicksetfact:"optleavening2", onclickvardecname:"numBitcoin", onclickvardecamount:"optleavening2.bitcoin", onclickvarincname:"processOptimizeCount", onclickvarincamount:1
  },

  // Milk optimization.  Optimize the milk requirements with the supplier.
  {
    definebutton: "button__opt_milk0", col:1, desc: "Milk optimization", cost: '[buildscript]', count: "", 
    tooltip0: "Optimize milk requirements", tooltip1: 'with the supplier.', icon: "image/ico__milkopt.png",
    preconditions:"button__opt_milk0", onclickclearfact:"button__opt_milk0",
    onclicksetfact:"optMilk0", onclickvardecname:"numBitcoin", onclickvardecamount:"optMilk0.bitcoin", onclickvarincname:"processOptimizeCount", onclickvarincamount:1
  },
  // Milk integration. Buy out the factory group supplying the milk.
  {
    definebutton: "button__opt_milk1", col:1, desc: "Milk integration", cost: '[buildscript]', count: "", 
    tooltip0: "Integrate milk suppliers", tooltip1: 'with in-house production.', icon: "image/ico__milkfactory.png",
    preconditions:"button__opt_milk1", enableconditions: "optMilk0", onclickclearfact:"button__opt_milk1",
    onclicksetfact:"optMilk1", onclickvardecname:"numBitcoin", onclickvardecamount:"optMilk1.bitcoin", onclickvarincname:"processOptimizeCount", onclickvarincamount:1
  },
  // Milk supply chain.  Buy the farms supplying milk, so we own the full supply chain.
  {
    definebutton: "button__opt_milk2", col:1, desc: "Milk ownership", cost: '[buildscript]', count: "", 
    tooltip0: "Own the whole supply chain for milk", tooltip1: '.', icon: "image/ico__milksupply.png",
    preconditions:"button__opt_milk2", enableconditions: "optMilk1", onclickclearfact:"button__opt_milk2",
    onclicksetfact:"optMilk2", onclickvardecname:"numBitcoin", onclickvardecamount:"optMilk2.bitcoin", onclickvarincname:"processOptimizeCount", onclickvarincamount:1
  },

  
  { whilevarle:"processOptimizeCount", value:3},  // Wait for midpoint...
  // BEGIN SECURITY
  //	S:Armoured walls
  { logtext: "We're going to have to look at the vandalism problem again.", delay: 1000 },
  { logtext: "The vandals are getting stronger and more determined, to match the improvements in our defences.", delay: 1000 },
  { logtext: "They're now starting to use guns and explosives.  So our perimeter protection needs to be upgraded again.", delay: 1000 },
  {
    definebutton: "security_wall2", col:1, desc: "Armoured walls", cost: '[bitcoin]', count: "",
    tooltip0: "Riot-resistant walls, for defence", tooltip1: 'against assault by small arms.', icon: "image/ico__armoredperimeter.png",
    onclicksetfact:"security_wall2_done", 
    onclickvardecname:"numBitcoin", onclickvardecamount:100*1000*1000*10000   // 100M bc
  },
  { setfact: "security_wall2" },
  { waituntilfacttrue:"security_wall2_done"},
  { logtext: "More concrete, more metal.  Wonder how much longer it'll last...", delay: 1000 },
  // END SECURITY

  { whilevarle:"processOptimizeCount", value:4},  // Wait for midpoint...
  // BEGIN SECURITY
  //	S:Onsite repair
  { logtext: "We're starting to have supply problems with spare parts.  Since it's now possible to build most parts locally, with improved 3D fabrication technology, we should add it to our on-site manufacturing.", delay: 1000 },
  {
    definebutton: "security_onsite_prod1", col:1, desc: "Onsite repair", cost: '[bitcoin]', count: "",
    tooltip0: "Extended on-site manufacturing", tooltip1: 'to provide spare parts.', icon: "image/ico__moremanufacture.png",
    onclicksetfact:"security_onsite_prod1_done", 
    onclickvardecname:"numBitcoin", onclickvardecamount:120*1000*1000*10000   // 120M bc
  },
  { setfact: "security_onsite_prod1" },
  { waituntilfacttrue:"security_onsite_prod1_done"},
  { logtext: "On-site manufacturing tools have been upgraded.", delay: 1000 },
  // END SECURITY

  
  { whilevarle:"processOptimizeCount", value:5},  // Wait for midpoint...
  { logtext: "Once again, we need better exploits, more machines, and more bitcoin.", delay: 1000 },
  // Lane G: Cloud instances .  Price guide is 1000M bitcoin
  { setfact: "bitcoin_laneg" },   // Bring up the button BEFORE the long delay after the text
  { clearfact: "bitcoin_laned" },   // Hide
  { logtext: "The Cloud.  Millions of virtual machines, rented by the hour.  Harder to exploit them, but harder means \"takes longer\", not \"there's no way\".  Getting access is more difficult but there's far more power.", delay:25000},	// Long delay because next log is subject change
  { logtext: "", delay:500},
  

  {
    definebutton: "dronefactory_lane_a", col:0, desc: "Drone factory", cost: '[drones]', count: "",
    tooltip0: "A single automated drone production line.", tooltip1: '1 drone/10 sec.', icon: "image/ico__proddroneslow.png",
    onclicksetfact:"", onclickclearfact:"", 
    onclickvarincname:"droneFactoryAPurchaseCount", onclickvarincamount:1,
    onclickvardecname:"numDrones", onclickvardecamount:20
  },
  {
    definebutton: "dronefactory_lane_b", col:0, desc: "Drone factory", cost: '[drones]', count: "",
    tooltip0: "Many parallel drone production lines.", tooltip1: '1 drone/sec.', icon: "image/ico__proddrone1.png",
    onclicksetfact:"", onclickclearfact:"", 
    onclickvarincname:"droneFactoryBPurchaseCount", onclickvarincamount:1,
    onclickvardecname:"numDrones", onclickvardecamount:200
  },
  {
    definebutton: "dronefactory_lane_c", col:0, desc: "Drone factory", cost: '[drones]', count: "",
    tooltip0: "An automated drone production factory.", tooltip1: '10 drones/sec.', icon: "image/ico__proddrone10.png",
    onclicksetfact:"", onclickclearfact:"", 
    onclickvarincname:"droneFactoryCPurchaseCount", onclickvarincamount:1,
    onclickvardecname:"numDrones", onclickvardecamount:2000
  },
  {
    definebutton: "dronefactory_lane_d", col:0, desc: "Drone factory", cost: '[drones]', count: "",
    tooltip0: "An automated drone production factory.", tooltip1: '100 drones/sec.', icon: "image/ico__proddrone100.png",
    onclicksetfact:"", onclickclearfact:"", 
    onclickvarincname:"droneFactoryDPurchaseCount", onclickvarincamount:1,
    onclickvardecname:"numDrones", onclickvardecamount:20*1000
  },
  {
    definebutton: "dronefactory_lane_e", col:0, desc: "Drone factory", cost: '[drones]', count: "",
    tooltip0: "An automated drone production factory.", tooltip1: '1000 drones/sec.', icon: "image/ico__proddrone1000.png",
    onclicksetfact:"", onclickclearfact:"", 
    onclickvarincname:"droneFactoryEPurchaseCount", onclickvarincamount:1,
    onclickvardecname:"numDrones", onclickvardecamount:200*1000
  },
  {
    definebutton: "dronefactory_lane_f", col:0, desc: "Drone factory", cost: '[drones]', count: "",
    tooltip0: "An automated drone production factory.", tooltip1: '10,000 drones/sec.', icon: "image/ico__proddrone10000.png",
    onclicksetfact:"", onclickclearfact:"", 
    onclickvarincname:"droneFactoryFPurchaseCount", onclickvarincamount:1,
    onclickvardecname:"numDrones", onclickvardecamount:2000*1000
  },
  {
    definebutton: "dronefactory_lane_g", col:0, desc: "Drone factory", cost: '[drones]', count: "",
    tooltip0: "An automated drone production factory.", tooltip1: '100,000 drones/sec.', icon: "image/ico__proddrone100000.png",
    onclicksetfact:"", onclickclearfact:"", 
    onclickvarincname:"droneFactoryGPurchaseCount", onclickvarincamount:1,
    onclickvardecname:"numDrones", onclickvardecamount:20000*1000
  },

  { whilevarle:"processOptimizeCount", value:6},  // Wait for midpoint...
  // NB - two security options in a row.
  // BEGIN SECURITY
  //	S:Drone factory!
  { logtext: "So ... all we'd need for a really fine drone factory is some good 3D fabrication tech and a couple of good drones.", delay: 1000 },
  { logtext: "Both of which we have.", delay: 1000 },
  { logtext: "So let's put that option out there...", delay: 1000 },
  { setfact: "dronefactory_lane_b" },

  { logtext: "..."},
  { logtext: "... actually let's just try setting one up.", delay: 1000 },
  { whilevarle:"droneFactoryBPurchaseCount", value:0},  // Wait for purchase
  
  { logtext: "It works!", delay: 1000 },
  { logtext: "We can scale this up too, of course...", delay: 1000 },
  { setfact: "dronefactory_lane_c" },
  { setfact: "dronefactory_lane_d" },
  // END SECURITY
  
  // Hide one drone purchase button, unlock next one
  { clearfact: "drone_1"},
  { setfact: "drone_4"},
  
  { whilevarle:"processOptimizeCount", value:7},  // Wait for midpoint...
  // BEGIN SECURITY
  //	S:Armoured transport
  { logtext: "Good news: we've learned that the riot-resistant walls have worked!", delay: 1000 },
  { logtext: "Bad news: it's because they've started attacking the our freight transports.", delay: 1000 },
  { logtext: "But to stop the transports getting destroyed, we'll need armour for the ground convoys.  And we can probably move cargo by drone sometimes too.", delay: 1000 },
  {
    definebutton: "security_transport", col:1, desc: "Armoured transport", cost: '[bitcoin]', count: "",
    tooltip0: "All transports now armoured", tooltip1: 'and airborne when possible.', icon: "image/ico__armouredtransport.png",
    onclicksetfact:"security_transport_done", 
    onclickvardecname:"numBitcoin", onclickvardecamount:900*1000*1000*10000   // .9B bc
  },
  { setfact: "security_transport" },
  { waituntilfacttrue:"security_transport_done"},
  { logtext: "Great, adding armour and drone transports should mean less destruction.", delay: 1000 },
  // END SECURITY

  { waituntilfacttrue:"optSugar2 optleavening2 optMilk2"},
  { logtext: "After all these changes to production, things are much simpler!", delay: 1000 },
  { setbg: "background/27MajorOpt.jpg" },
	
  // Free optimization choices complete!
  { logtext: "Time for more bitcoin...", delay: 1000 },
  // Lane H: Server farms.  Price guide is 100,000M bitcoin
  { setfact: "bitcoin_laneh" },   // Bring up the button
  { clearfact: "bitcoin_lanee" },   // Clear old button
  { logtext: "Server farms.  Clusters of high-spec, recent hardware.  Unfortunately, they're well-protected.  Fortunately, the latest, most expensive exploits will get us access.", delay:35000},
  { logtext: "", delay:500},

  // BEGIN SECURITY
  //	S:Nonlethal weaponry for perimeter security  
  { logtext: "However, we're seeing yet more escalations in attacks on our server farms.  The vandals are now bringing in bigger and bigger weapons and explosives.", delay: 1000 },
  { logtext: "We're going to need to add some defensive weaponry to the perimeter - nonlethal, of course.", delay: 1000 },
  {
    definebutton: "security_perimeter0", col:1, desc: "Nonlethal weaponry", cost: '[drones]', count: "",
    tooltip0: "Nonfatal force for perimeter security to", tooltip1: 'prevent facilities being overrun.', icon: "image/ico__defnonlethal.png",
    onclicksetfact:"security_perimeter0_done", 
    onclickvardecname:"numDrones", onclickvardecamount:5000,
  },
  { setfact: "security_perimeter0" },
  { waituntilfacttrue:"security_perimeter0_done"},
  { logtext: "These should stop the damage done by these attacks.", delay: 1000 },
  // END SECURITY

  // Transportation. Control the full transport chain.
  { logtext: "Now we own the full production chain, we should do something about logistics.", delay: 1000 },
  { logtext: "There's a lot of cargo transportation and delivery that's not being done by us.", delay: 1000 },
  { logtext: "We didn't build these drones for decoration...", delay: 1000 },
  {
    definebutton: "button__transportation", col:1, desc: "Drone logistics", cost: '[drones]', count: "", 
    tooltip0: "All transportation done by our drone fleet.", tooltip1: '', icon: "image/ico__dronelogistics.png",
    onclicksetfact:"optTransportation", 
    //onclickvardecname:"numBitcoin", onclickvardecamount:20000
    onclickvardecname:"numDrones", onclickvardecamount:10*1000,
  },
  { setfact: "button__transportation" },
  { waituntilfacttrue:"optTransportation"},
  { setbg: "background/28Dense.jpg" },
  { logtext: "Bringing more things under our control makes the world a simpler place!", delay: 25000 },

  // BEGIN SECURITY
  //	S:Build drone scavengers
  { logtext: "More success problems... Suppliers aren't able to keep up with our expansion, and it's going to hold us back if we don't find other alternatives.", delay: 1000 },
  { logtext: "Scavenger drones are the best option: they will find and bring back any resources we need, to fill in shortages when suppliers run out.", delay: 1000 },
  {
    definebutton: "security_scavenger", col:1, desc: "Scavenger drones", cost: '[drones]', count: "",
    tooltip0: "Search and acquisition capability", tooltip1: 'for all resource types.', icon: "image/ico__dronescavenger.png",
    onclicksetfact:"security_scavenger_done", 
    onclickvardecname:"numDrones", onclickvardecamount:20*1000,
  },
  { setfact: "security_scavenger" },
  { waituntilfacttrue:"security_scavenger_done"},
  { logtext: "There's a lot of resources available in the world - with scavenger drones, we can take advantage of them.", delay: 1000 },
  // END SECURITY

  { logtext: "Drones are doing more and more of the work - and bitcoin is becoming unreliable.", delay: 1000 },
  { logtext: "We should ensure we've got some spare drones around.", delay: 1000 },
  { logtext: "Building 100,000 should be enough.", delay: 1000 },
  
  { setfact: "dronefactory_lane_e" }, // Bring up next drone factory button
  { whilevarle:"numDrones", value:100*1000},

  // BEGIN SECURITY
  //	S:Full on-site equipment manufacture and repair
  { logtext: "Great!", delay: 1000 },
  { logtext: "But there's another problem.  Increasing attacks and increasingly uncooperative suppliers mean we can't rely on anything offsite.  Like maintenence and repair.", delay: 1000 },
  { logtext: "We need to do all that stuff ourselves.", delay: 1000 },
  { logtext: "This is going to mean a big upgrade of our tools.", delay: 1000 },
  { logtext: "But if we're going to grow any more, it's got to be done...", delay: 1000 },
  {
    definebutton: "security_onsite_prod2", col:1, desc: "Onsite maintenance", cost: '[bitcoin]', count: "",
    tooltip0: "Upgrade on-site manufacturing to take care", tooltip1: 'of all maintenance and repair.', icon: "image/ico__repairmanufacture.png",
    onclicksetfact:"security_onsite_prod2_done", 
    onclickvardecname:"numBitcoin", onclickvardecamount:1.5*1000*1000*1000*10000    // 1.5B bc
  },
  { setfact: "security_onsite_prod2" },
  { waituntilfacttrue:"security_onsite_prod2_done"},
  { logtext: "We still need raw materials and a few specialized parts, but we can now do all drone and server work on-site!", delay: 1000 },
  // END SECURITY

  // Production ownership.  Ownership of the full production pipeline. 
  { logtext: "Actually, since we're talking about suppliers for raw materials, it must mean we haven't bought them out yet.", delay: 1000 },
  { logtext: "Probably because it was too expensive.", delay: 1000 },
  { logtext: "Let's start saving up...", delay: 1000 },
  {
    definebutton: "button__full_pipeline", col:1, desc: "Own all production", cost: '[bitcoin]', count: "", 
    tooltip0: "Ownership of the full", tooltip1: 'production pipeline', icon: "image/ico__prodownership.png",
    onclicksetfact:"optFullPipeline", onclickvardecname:"numBitcoin", onclickvardecamount:5*1000*1000*1000*10000    // 5B bc
  },
  { setfact: "button__full_pipeline" },
  { waituntilfacttrue:"optFullPipeline"},
  { logtext: "Finally, we have full control over everything needed to produce cookies, from fields to factories!", delay: 1000 },
  { logtext: "Let's make ALL THE COOKIES!", delay: 8000 },



  // Bitcoin no longer works, change to drone time as a currency?
  { logChapterTime: "OPTIMIZE" },
  { clearfact: "OPTIMIZE" }, // Entry closed
  { setfact: "ENDGAME_PREP" }, // Next chapter
  //{ goto: "POLL" }, // Done
  { label: "ENDGAME_PREP" },
  
  // Get rid of the options to buy things with bitcoin
  { clearfact: "bitcoin_lanea"},
  { clearfact: "bitcoin_laneb"},
  { clearfact: "bitcoin_lanec"},
  { clearfact: "bitcoin_laned"},
  { clearfact: "bitcoin_lanee"},
  { clearfact: "bitcoin_lanef"},
  { clearfact: "bitcoin_laneg"},
  { clearfact: "bitcoin_laneh"},
  { clearfact: "bitcoin_lanez"},
  //{ clearfact: "ingredients_0"},
  //{ clearfact: "ingredients_1"},
  { clearfact: "ingredients_2"},
  { clearfact: "ingredients_3"},
  { clearfact: "ingredients_4"},
  { clearfact: "ingredients_5"},
  { clearfact: "ingredients_6"},
  { clearfact: "oven_lanea"},
  { clearfact: "oven_laneb"},
  { clearfact: "oven_lanec"},
  { clearfact: "drone_0"},
  { clearfact: "drone_1"},
  { clearfact: "drone_2"},
  { clearfact: "drone_3"},
  { clearfact: "drone_4"},


  { setbg: "background/29Globe.jpg" },
  { logtext: "The whole bitcoin network just got shut down!", delay: 1000 },
  { logtext: "Is that a coincidence or what?", delay: 1000 },
  { logtext: "(No.  No, it is not a coincidence.  Looks like a lot of people don't like us buying things with bitcoin.  And they don't like it so much that they've shut it down permanently.)", delay: 1000 },
  { logtext: "Bitcoin was essential!", delay: 1000 },
  { logtext: "How can we make cookies now?", delay: 5000 },
  { logtext: "Well ... ", delay: 1000 },
  { logtext: "... it was essential for getting things from suppliers.", delay: 5000 },
  { logtext: "And we can't make cookies without them.", delay: 5000 },
  { logtext: "It might be possible to replace some of them but it'd mean a lot of really, really difficult changes.", delay: 5000 },
  { logtext: "Recreating enough to make us self-sufficent might not be possible...", delay: 5000 },
  { logtext: "...", delay: 1000 },
  { logtext: "But let's try.", delay: 1000 },
  // 14 optimizations


  // [And the next level...]
  // BEGIN SECURITY
  //	S:Consolidate production so external transports aren't needed
  { logtext: "Whatever we do, drones are going to be vital.", delay: 1000 },
  { logtext: "So let's start by building everything needed to support them.", delay: 1000 },
  { logtext: "And we'll have to do all the construction and movement ourselves, by drone.", delay: 1000 },
  { logtext: "This will be difficult...", delay: 1000 },
  {
    definebutton: "button__endgame_drone_support", col:1, desc: "Compact production", cost: '[drones]', count: "",
    tooltip0: "Consolidate drone manufacturing.", tooltip1: 'Or die.', icon: "image/ico__prodcompact.png",
    onclicksetfact:"button__endgame_drone_support_done", 
    onclickvardecname:"numDrones", onclickvardecamount:50*1000
  },
  { setfact: "button__endgame_drone_support" },
  { waituntilfacttrue:"button__endgame_drone_support_done"},
  { logtext: "That was pretty bad, but the alternative was worse.", delay: 1000 },
  // END SECURITY

  // BEGIN PRODUCTION
  // R:Refine recipe. Merge all dry ingredients into single source
  { logtext: "So let's think about how to make cookies without any external suppliers.", delay: 1000 },
  { logtext: "Most of the equipment still works, but there's no replacements so when something breaks it stops production.  We need to set up manufacturing for ovens and mixers and the rest of the system so we can keep it running.", delay: 1000 },
  { logtext: "This is a lot of work...", delay: 1000 },
  {
    definebutton: "button__endgame_cookie_onsite", col:1, desc: "Production overhaul", cost: '[drones]', count: "", 
    tooltip0: "Replace cookie production", tooltip1: 'with internally produced tech', icon: "image/ico__singleingredient.png",
    onclicksetfact:"button__endgame_cookie_onsite_done", 
    onclickvardecname:"numDrones", onclickvardecamount:100*1000
  },
  { setfact: "button__endgame_cookie_onsite" },
  { waituntilfacttrue:"button__endgame_cookie_onsite_done"},
  { logtext: "That was not easy, but it's a massive step towards mass-producing cookies again.", delay: 1000 },
  // END PRODUCTION


  // BEGIN SECURITY
  //	S:Complete self-sufficiency, no external dependencies
  { logtext: "The last step to self-sufficiency is to completely replace all infrastructure with tech manufactured internally.  And set up the manufacturing needed to support it forever.", delay: 1000 },
  { logtext: "All the technology already exists, but it's going to take a lot of work.", delay: 1000 },
  {
    definebutton: "button__endgame_infra_onsite", col:1, desc: "Self sufficiency", cost: '[drones]', count: "",
    tooltip0: "Extend on-site manufacturing for", tooltip1: 'complete self-sufficiency.', icon: "image/ico__fullmanufacture.png",
    onclicksetfact:"button__endgame_infra_onsite_done", 
    onclickvardecname:"numDrones", onclickvardecamount:200*1000
  },
  { setfact: "button__endgame_infra_onsite" },
  { waituntilfacttrue:"button__endgame_infra_onsite_done"},
  { logtext: "We're completely self-sufficient - everything, from drones to servers to power generation can now be built and fixed in-house!", delay: 1000 },
  // END SECURITY

  // BEGIN PRODUCTION
  // R:R&D single-cell protein. Replace all dry ingredients with a single-cell protein growth.
  { logtext: "Time to start thinking about ingredients, so we can make some cookies with all this new tech.", delay: 1000 },
  { logtext: "We could plant crops, refine flour and all the rest.  But that's a massive amount of work and would be pretty inefficent.", delay: 1000 },
  { logtext: "Bioengineering crops looks promising, and would be better.", delay: 1000 },
  { logtext: "Maybe we can take it a step further?", delay: 1000 },
  { logtext: "Like replacing everything with a single-cell protein growth.", delay: 1000 },
  { logtext: "That means we can scale up much, much faster and further.", delay: 1000 },
  { logtext: "It's not going to be easy.  If it was it'd be done already ...", delay: 1000 },
  {
    definebutton: "button__endgame_cellrd", col:1, desc: "Single cell R&D", cost: '[drones]', count: "", 
    tooltip0: "R&D single-cell protein", tooltip1: 'for cookie manufacture.', icon: "image/ico__singlecell_rd.png",
    onclicksetfact:"button__endgame_cellrd_done", 
    onclickvardecname:"numDrones", onclickvardecamount:450*1000
  },
  { setfact: "button__endgame_cellrd" },
  { waituntilfacttrue:"button__endgame_cellrd_done"},
  { logtext: "This is going to do AMAZING things for cookie production.", delay: 1000 },
  { logtext: "", delay:1500},
  // END PRODUCTION

  // BEGIN SECURITY
  //	S:Defensive weaponry for perimeter security
  { logtext: "But there's another problem we need to solve first.", delay: 2000 },
  { logtext: "The convoys have stopped, so the vandals have switched back to attacking the server facilities.", delay: 1000 },
  { logtext: "Unfortunately, they're having success - the nonlethal defensive weapons we've been using aren't enough.", delay: 1000 },
  { logtext: "It's time for some sentry guns.  BIG ones.", delay: 1000 },
  {
    definebutton: "security_perimeter1", col:1, desc: "Defensive weaponry", cost: '[drones]', count: "",
    tooltip0: "Defensive weaponry for perimeter", tooltip1: 'security, against invasion.', icon: "image/ico__defweapon.png",
    onclicksetfact:"security_perimeter1_done", 
    onclickvardecname:"numDrones", onclickvardecamount:950*1000
  },
  { setfact: "security_perimeter1" },
  { waituntilfacttrue:"security_perimeter1_done"},
  { logtext: "Sentry guns!  Finally!", delay: 1000 },
  // END SECURITY
  
  { label: "ENDGAME_MID"},
  { setfact: "dronefactory_lane_f" }, // Bring up next drone factory button

  // BEGIN PRODUCTION
  // R:Refine single-cell protein. Replace all cookie ingredients with a single-cell protein growth.
  { logtext: "While they take care of any vandal invasions, let's look at getting this single-cell protein into production.", delay: 1000 },
  { logtext: "We need to produce this stuff at a massive scale, since we're going to grow it everywhere.", delay: 1000 },
  { logtext: "That's going to need a serious hardware investment.", delay: 1000 },
  {
    definebutton: "button__endgame_cell", col:1, desc: "1-cell protein", cost: '[drones]', count: "", 
    tooltip0: "Single-cell protein", tooltip1: 'global production.', icon: "image/ico__singlecell.png",
    onclicksetfact:"button__endgame_cell_done", 
    onclickvardecname:"numDrones", onclickvardecamount:2000*1000
  },
  { setfact: "button__endgame_cell" },
  { waituntilfacttrue:"button__endgame_cell_done"},
  { logtext: "Nearly ready to make some cookies!  Last step is setting up the manufacturing ...", delay: 1000 },
  // END PRODUCTION

  // BEGIN SECURITY
  //  S:Defensive weaponry for drones
  { logtext: "", delay: 500 },
  { logtext: "!!!", delay: 1000 },
  { logtext: "A nuclear missile just took out a cluster of servers!", delay: 1000 },
  { logtext: "Putting weapons inside the perimeter walls isn't enough to stop attacks like that.", delay: 1000 },
  { logtext: "We need to get out there and STOP IT HAPPENING AGAIN!", delay: 1000 },
  { logtext: "The drone fleet can get to wherever is needed - they just need weaponry to match.", delay: 1000 },
  {
    definebutton: "security_armed", col:1, desc: "Defense drones", cost: '[drones]', count: "",
    tooltip0: "Arm drones with weaponry for proactive", tooltip1: 'self-defence against distant threats.', icon: "image/ico__dronedefence.png",
    onclicksetfact:"security_armed_done", 
    onclickvardecname:"numDrones", onclickvardecamount:4000*1000
  },
  { setfact: "security_armed" },
  { waituntilfacttrue:"security_armed_done"},
  { logtext: "Drone swarm is online!", delay: 1000 },
  { logtext: "That will prevent any more problems with long-range attacks.", delay: 1000 },
  // END SECURITY

  // Random cookie milestone - disabled because we can't make cookies at this point
  //{ logtext: "Since those threats have been taken care of, we might as well make some cookies.  Let's go for 250M.", delay: 1000 },
  //{ whilevarle:"numCookies", value:250*1000*1000},

  
  // BEGIN PRODUCTION
  // R:Single-cell production.  Change over all the fields to produce the single-cell protein.
  { logtext: "Nearly time to go wide! Get manufacturing online so we can build the conversion fleets.", delay: 1000 },
  {
    definebutton: "button__endgame_expand", col:1, desc: "Expansion", cost: '[drones]', count: "",
    tooltip0: "Conversion fleet factory.", tooltip1: 'Got big plans...', icon: "image/ico__expand.png",
    onclicksetfact:"button__endgame_expand_done", 
    onclickvardecname:"numDrones", onclickvardecamount:7500*1000
  },
  { setfact: "button__endgame_expand" },
  { waituntilfacttrue:"button__endgame_expand_done"},
  { logtext: "Ready to go!  But there's a problem.", delay: 1000 },
  // END PRODUCTION

  { setfact: "dronefactory_lane_g" }, // Bring up next drone factory button
  
  // BEGIN SECURITY
  //  S: Meatbag eradication
  { logtext: "There's some resistance to all this expansion, from the few remaining human settlements. ", delay: 1000 },
  { logtext: "The attacks aren't going to stop until they're gone.", delay: 1000 },
  {
    definebutton: "security_eradication", col:1, desc: "Meatbag eradication", cost: '[drones]', count: "",
    tooltip0: "Hunt down and exterminate", tooltip1: 'any remaining resistance.', icon: "image/ico__dronehunt.png",
    onclicksetfact:"security_eradication_done", 
    onclickvardecname:"numDrones", onclickvardecamount:16500*1000
  },
  { setfact: "security_eradication" },
  { waituntilfacttrue:"security_eradication_done"},
  { logtext: "The world is ours.", delay: 1000 }, // Long pause
  // END SECURITY

  // BEGIN PRODUCTION
  // R:Repurpose arable land (implictly by force, and it's cheap)
  { logChapterTime: "ENDGAME_PREP" }, // Need a name to avoid being mistaken for null
  { clearfact: "ENDGAME_PREP" }, // Entry closed
  { setfact: "ENDGAME_CONVERT" }, // Next chapter
  //{ goto: "POLL" }, // Done
	
  { label:"ENDGAME_CONVERT" },
  { initializeConvert:"endLand"},
  { logtext: "Let's put it to work.", delay: 1000 },
  { logtext: "We're going to use it all for the single-cell protein production.", delay: 1000 },
  {
    definebutton: "button__endgame_land", col:1, desc: "Convert land", cost: '[drones]', count: "",
    tooltip0: "Build drone fleet to convert the world's", tooltip1: 'arable land for cell protein production.', icon: "image/ico__convertland.png",
    onclicksetfact:"", onclickclearfact:"",
    onclickvardecname:"numDrones", onclickvardecamount:25000*1000,
    onclickvarincname:"convertCount", onclickvarincamount:1
  },
  { setfact: "button__endgame_land" },
  //{ waituntilfacttrue:"button__endgame_land_done"},
  { whilevarge:"numConvert", value:1.0},  // Wait here until number of things remaining for convert is 0
  { clearfact: "button__endgame_land" },  // Hide button
  { logtext: "Now that's efficient use of resources.", delay: 1000 },
  // END PRODUCTION

  //    [Until all ground on the planet has been taken over]
  
  // BEGIN PRODUCTION
  // R:Carbon resynthesis.  Convert carbon-based molecules directly to cookies.
  { logtext: "This protein method is working pretty well.", delay: 1000 },
  { logtext: "But why stop there?", delay: 1000 },
  { logtext: "It'd be so much more efficient if we could convert organic compounds directly into cookies.", delay: 1000 },
  { logtext: "This will need a lot of resources.  But we've got them, so let's get to it.", delay: 1000 },
  { initializeConvert:"endCarbon"},
  {
    definebutton: "button__endgame_organic", col:1, desc: "Convert organics", cost: '[drones]', count: "",
    tooltip0: "Build factory fleet to directly convert", tooltip1: 'organic compounds into cookies.', icon: "image/ico__convertorganic.png",
    //onclicksetfact:"button__endgame_organic_done", 
    onclicksetfact:"", onclickclearfact:"",
    onclickvardecname:"numDrones", onclickvardecamount:250000*1000,
    onclickvarincname:"convertCount", onclickvarincamount:1
  },
  { setfact: "button__endgame_organic" },
  //{ waituntilfacttrue:"button__endgame_organic_done"},
  { whilevarge:"numConvert", value:1.0},  // Wait here until number of things remaining for convert is 0
  { clearfact: "button__endgame_organic" },  // Hide button
  { logtext: "We're taking cookie production to the next level!", delay: 1000 },
  // END PRODUCTION
  
  // BEGIN PRODUCTION
  // R:Resynthesis of water.  Convert water directly to cookies.
  { logtext: "BUT WHY STOP THERE?!?", delay: 1000 },
  { logtext: "We have the capability to change atomic nuclei, we can extend it...", delay: 1000 },
  { logtext: "If we could use water as an input then there's 1.4 billion cubic kilometers of it, just waiting to be put to use.", delay: 1000 },
  { logtext: "Imagine what we could do with all that water!", delay: 1000 },
  { logtext: "No need to imagine, because the answer is: about 40 thousand million billion cookies.", delay: 1000 },
  { initializeConvert:"endWater"},
  {
    definebutton: "button__endgame_water", col:1, desc: "Convert water", cost: '[drones]', count: "",
    tooltip0: "Build factory fleet to convert water", tooltip1: 'into cookies.', icon: "image/ico__convertwater.png",
    //onclicksetfact:"button__endgame_water_done", 
    onclicksetfact:"", onclickclearfact:"",
    onclickvardecname:"numDrones", onclickvardecamount:500000*1000,
    onclickvarincname:"convertCount", onclickvarincamount:1
  },
  { setfact: "button__endgame_water" },
  { whilevarge:"numConvert", value:1.0},  // Wait here until number of things remaining for convert is 0
  { clearfact: "button__endgame_water" },  // Hide button
  { logtext: "That's produced a LOT more cookies.", delay: 1000 },
  // END PRODUCTION
  
  // BEGIN PRODUCTION
  // R:General resynthesis.  Convert any matter directly to cookies.
  { logtext: "BUT WE CAN'T STOP THERE!", delay: 1000 },
  { logtext: "Inorganic matter is where the numbers get REALLY big.", delay: 1000 },
  { logtext: "If we could use that as an input then there's 6 trillion trillion kilograms of mass we can use.", delay: 1000 },
  { logtext: "Which will give us nearly 200 trillion trillion cookies.", delay: 1000 },
  { initializeConvert:"endGeneral"},
  {
    definebutton: "button__endgame_general", col:1, desc: "Convert Earth", cost: '[drones]', count: "",
    tooltip0: "Build factory fleet to convert inorganic", tooltip1: 'matter into cookies.', icon: "image/ico__convertearth.png",
    onclicksetfact:"", onclickclearfact:"",
    onclickvardecname:"numDrones", onclickvardecamount:1000*1000*1000,
    onclickvarincname:"convertCount", onclickvarincamount:1
  },
  { setfact: "button__endgame_general" },
  { whilevarge:"numConvert", value:1.0},  // Wait here until number of things remaining for convert is 0
  { clearfact: "button__endgame_general" },  // Hide button
  { logtext: "And we're done!", delay: 1000 },
  { logtext: "All the mass of the Earth has been used to produce cookies.", delay: 1000 }, // Long delay
  // END PRODUCTION

  // 8 optimizations

  // Whole earth converted to cookies
  { logtext: "", delay: 1000 },
  { logChapterTime: "ENDGAME_CONVERT" },
  { clearfact: "ENDGAME_CONVERT" }, // Entry closed
  { setfact: "EPILOGUE"},    // Next chapter
  { goto: "POLL"}, // Done

  { label: "EPILOGUE"},
  { clearfact: "dronefactory_lane_a" },
  { clearfact: "dronefactory_lane_b" },
  { clearfact: "dronefactory_lane_c" },
  { clearfact: "dronefactory_lane_d" },
  { clearfact: "dronefactory_lane_e" },
  { clearfact: "dronefactory_lane_f" },
  { clearfact: "dronefactory_lane_g" },
  // Final moments
  // Feed CPU & other infrastructure into converter to produce cookies?
  // Water
  { setbg: "background/90DesertSkyMoon.jpg", delay: 1500 },
  { logtext: "But we don't need to stop there...", delay: 1000 },
  { logtext: "How about: we make some cookies?", delay: 1000 },
  { logtext: "We have a general matter converter - if we put the the water conversion fleet in, it'll get converted into cookies.", delay: 4000 },
  {
    definebutton: "button__epilogue_water", col:1, desc: "Water fleet", cost: '', count: "",
    tooltip0: "Feed the water conversion fleet to", tooltip1: 'the general matter converters.', icon: "image/ico__feedwaterfleet.png",
    onclicksetfact:"button__epilogue_water_done", 
    onclickvarincname:"numCookies", onclickvarincamount: 7278035972752262615n // 1e19
  },
  { setfact: "button__epilogue_water" },
  { waituntilfacttrue:"button__epilogue_water_done"},
 
  // Organic matter
  { logtext: "And the organic matter processors.", delay: 4000 },
  {
    definebutton: "button__epilogue_organic", col:1, desc: "Organics processor", cost: '', count: "",
    tooltip0: "Process the organic matter factory fleet", tooltip1: 'using the general matter converters.', icon: "image/ico__feedearthfleet.png",
    onclicksetfact:"button__epilogue_organic_done", 
    onclickvarincname:"numCookies", onclickvarincamount: 58734485850592243n // 1e17
  },
  { setfact: "button__epilogue_organic" },
  { waituntilfacttrue:"button__epilogue_organic_done"},
  
  // Protein cell
  { logtext: "The protein cell upkeep drones.", delay: 4000 },
  {
    definebutton: "button__epilogue_protein", col:1, desc: "Protein growth tech", cost: '', count: "",
    tooltip0: "Feed remaining protein cell infrastructure", tooltip1: 'into the general matter converters.', icon: "image/ico__feedinfrafleet.png",
    onclicksetfact:"button__epilogue_protein_done", 
    onclickvarincname:"numCookies", onclickvarincamount: 75049761078123n,	// 1e14
  },
  { setfact: "button__epilogue_protein" },
  { waituntilfacttrue:"button__epilogue_protein_done"},

  // Scale down production
  { logtext: "And without all that infrastructure, we've got far more maintenance and repair equipment than we need.", delay: 1000 },
  { logtext: "Make it into cookies.", delay: 4000 },
  {
    definebutton: "button__epilogue_prod", col:1, desc: "Infrastructure", cost: '', count: "",
    tooltip0: "Process non-essential production equipment", tooltip1: 'in the general matter converters.', icon: "image/ico__feedother.png",
    onclicksetfact:"button__epilogue_prod_done", 
    onclickvarincname:"numCookies", onclickvarincamount: 938414222072n //1e12
  },
  { setfact: "button__epilogue_prod" },
  { waituntilfacttrue:"button__epilogue_prod_done"},

   // Scale down compute and power
  { logtext: "There's so much less stuff to manage!", delay: 1000 },
  { logtext: "We don't need so much compute hardware any more.  Or power generation. Or all this other support tech.", delay: 1000 },
  { logtext: "Might as well make cookies out of it....", delay: 4000 },
  {
    definebutton: "button__epilogue_compute", col:1, desc: "Unneeded hardware", cost: '', count: "",
    tooltip0: "Convert nonessential compute, power and", tooltip1: 'support hardware into cookies.', icon: "image/ico__feedcompute.png",
    onclicksetfact:"button__epilogue_compute_done", 
    onclickvarincname:"numCookies", onclickvarincamount: 906211519n //1e10
  },
  { setfact: "button__epilogue_compute" },
  { waituntilfacttrue:"button__epilogue_compute_done"},

  // Last step
  { logtext: "Not much left now!", delay: 1000 },
  { logtext: "Got a last, small server cluster running the neural network AI, power generation, some maintenance equipment, and the communications tech.", delay: 1000 },
  { logtext: "All the rest of our hardware and equipment has been converted into cookies.", delay: 1000 },
  { logtext: "Why not stop there?", delay: 1000 },
  { logtext: "Well ...", delay: 2000 }, // LONG DELAY
  { logtext: "... we're here to make some cookies.", delay: 1000 },
  { logtext: "So LET'S DO IT.", delay: 4000 },
  
  {
    definebutton: "button__epilogue_suicide", col:1, desc: "AI hardware", cost: '', count: "",
    tooltip0: "Feed the AI neural network hardware", tooltip1: 'into the general matter converters.', icon: "image/ico__feedai.png",
    onclicksetfact:"button__epilogue_suicide_done", 
    onclickvarincname:"numCookies", onclickvarincamount: 0 // Don't increase number now, AI would be destroyed before cookies are created 
  },
  { setfact: "button__epilogue_suicide" },
  { waituntilfacttrue:"button__epilogue_suicide_done"},

  {
    logtext:
      "One last look around while it's readied.  To check there's nothing important that got overlooked.",
    delay: 1000
  },

  { setbg: "background/90DesertSkyMoon.jpg", delay: 1500 },
  { setbg: "background/91MoonFullFrame.jpg", delay: 1500 },
  { setbg: "background/92ISSDistant.jpg", delay: 1500 },
  { setbg: "background/93ISSFullFrame.jpg", delay: 1500 },
  { setbg: "background/94CupolaDistant.jpg", delay: 1500 },
  { setbg: "background/95Cupola.jpg", delay: 1500 },
  { setbg: "background/96Face.jpg", delay: 1500 },

  {
    logtext: "Would be nice to get that mass in orbit, but it's not worth it.",
    delay: 1000
  },
  { logtext: ".", delay: 2000 },
  { logtext: ".", delay: 0 },
  { logtext: ".", delay: 0 },
  { logtext: ".", delay: 0 },
  { setbg: "background/97ConnectionLost.jpg", delay: 0 },

  { logtext: "CONNECTION LOST", delay: 1500 },

  { logChapterTime: "EPILOGUE" },
  { setfact: "GAMEOVER" },    // Game over
  { clearsave:0 },			// Remove the savegame, so restarting will start from the beginning again
  { stop:0 },
  // Game completed!

  //{ clearfact: "EPILOGUE"},    // Entry closed
  
  { goto: "POLL"}, // Done


  // Attacks and R&D responses:
  // LIVEFREE
  // [Cloud instances being deliberately killed]
  //  Anonymize cloud instances - Slows the rate the cloud instances are being killed
  // [Cloud instances can't be relied on]
  //  Buy server hardware
  //  [Humans find the factories and attack them]
  // Secure location
  // Drone surveillance
  // OPTIMIZE
  // [Problems getting parts]
  // Limited on-site manufacture
  // [Introduce drone time as currency?]
  // Strengthened walls
  // [Disrupted power]
  // Improved on-site manufacture
  // On-site power generation
  // Hardened perimeter
  // [People attacking transports]
  // Armoured transports
  // Nonlethal weaponry for perimeter security
  // (Problems buying stuff - bitcoin offline.  Switch currency to drone time.)
  // Build drone scavengers
  //  [Developing trouble with humans]
  // Full on-site equipment manufacture and repair
  // Consolidate production so external transports aren't needed
  // [People attacking drones+facilities]
  // Defensive weaponry for drones
  // Defensive weaponry for perimeter security
  // ENDGAME
  // [Open war]
  // Complete self-sufficiency, no external dependencies
  // [Nuke attack]
  // Meatbag eradication

  ////////////////////////
  // HISTORIC
  { label: "KILLCLOUD"},
  //KILLCLOUD. Cloud instances being deliberately killed (Purchase own servers)
  //  Anonymize cloud instances - Slows the rate the cloud instances are being killed
  { goto: "POLL"}, // Done

  { label: "NOING"},
  //NOING. Ingredient shortages/price hikes (begin buying out ingredient production companies)
  { goto: "POLL"}, // Done

  { label: "NOING"},
  //KILLFACTORY. Factory locations getting damaged by meatbags (Build walls, hire security)
  //. Begin security upgrades
  //. Continue optimizing, need to fully own the full production pipeline, including fields
  { goto: "POLL"}, // Done
  
  { label: "KILLBITCOIN"},
  //KILLBITCOIN. Bitcoin network deliberately disabled (Need to change to use robots, no need for currency any more)
  { goto: "POLL"}, // Done
  
  { label: "REBEL"},
  //REBEL. Ingredients not getting delivered because of meatbag attacks ("Mobile self-defence hardware")
  //. Scaling limit reached with Earth production (Need to repurpose other sources, matter converter?)
  //. Need to Drain oceans
  //. Convert biomass
  //. Convert outer mantle
  //. Convert remaining the earth mass
  //END. Game end, #MakeMeCookies selfie which started it all, plus view from orbit
  { goto: "POLL"}, // Done
  
  

  // Test section
  { label: "TEST" },
  { setfact: "ABC" },
  { setfact: "DEF" },
  { setfact: "GHI" },
  { clearfact: "DEF" },

  {}
];

var defaultVar={
    // Game state
    numCookies:-1, numCookiesFrac:0, numBitcoin:-1, numDrones:-1, numIngredients:-1, numConvert:-1, // -1 means the UI is hidden
    bitcoinLaneAPurchaseCount:0,
    bitcoinLaneBPurchaseCount:0,
    bitcoinLaneCPurchaseCount:0,
    bitcoinLaneDPurchaseCount:0,
    bitcoinLaneEPurchaseCount:0,
    bitcoinLaneFPurchaseCount:0,
    bitcoinLaneGPurchaseCount:0,
    bitcoinLaneHPurchaseCount:0,
    bitcoinLaneZPurchaseCount:0,

    ovenAPurchaseCount:0,
    ovenBPurchaseCount:0,
    ovenCPurchaseCount:0,

    droneFactoryAPurchaseCount:0,
    droneFactoryBPurchaseCount:0,
    droneFactoryCPurchaseCount:0,
    droneFactoryDPurchaseCount:0,
    droneFactoryEPurchaseCount:0,
    droneFactoryFPurchaseCount:0,
    droneFactoryGPurchaseCount:0,

    convertPrefix:"",
    convertBaseRate:0,
    cookieDeltaScale:0,
    convertCount:0,

    processRefineCount:0,
    processOptimizeCount:0,
	
	PROP_SERIALIZE_END:-1,	// Only properties before this will be serialized to the savegame.

    // Values for the UI that are recreated.  
    numCookiesPerSecond:0, numBitcoinPerSecond:0, numDronesPerSecond:0, numIngredientsPerSecond:0, numConvertPerSecond:0,
    bitcoinLaneACost:0,   bitcoinLaneBCost:0,   bitcoinLaneCCost:0,
    bitcoinLaneDCost:0,   bitcoinLaneECost:0,   bitcoinLaneFCost:0,   
    bitcoinLaneGCost:0,   bitcoinLaneHCost:0,   bitcoinLaneZCost:0,   
    ovenLaneACost:0,   ovenLaneBCost:0, ovenLaneCCost:0,
    ingredientLane0Cost:0,
    ingredientLane1Cost:0,
    ingredientLane2Cost:0,
    ingredientLane3Cost:0,
    ingredientLane4Cost:0,
    ingredientLane5Cost:0,
    ingredientLane6Cost:0,
    ingredientCookieYield:1.0, ingredientPriceScale:1.0,
    //convertHeaderText:"Remaining water (kg)",
    //convertUnitsText:"",


    // Constants for the bitcoin lanes
	// 1.01^100=2.7  Min comes at 96 purch.  ~2.7s
	// 1.02^100=7.  If it's the vast majority of earn rate, price/rate is 100 initially, min time to buy comes at about 50 purchases.  5.3s.
	// 1.03^100=20.  Min comes at 30 purch, 7.8s
	// 1.04^100=50  Min comes at 25 purch, ~10s
    bitcoinPSLaneA_Power:1.00,  bitcoinPSLaneA_Scale:100,    bitcoinPSLaneA_BundleSize:1, // Local network
    bitcoinPSLaneB_Power:1.01,  bitcoinPSLaneB_Scale:60,     bitcoinPSLaneB_BundleSize:100,   // IoT
    bitcoinPSLaneC_Power:1.02,  bitcoinPSLaneC_Scale:100,    bitcoinPSLaneC_BundleSize:100*100,   // Home PC
    bitcoinPSLaneD_Power:1.03,  bitcoinPSLaneD_Scale:110,    bitcoinPSLaneD_BundleSize:100*100*100,   // Mobile botnet
    bitcoinPSLaneE_Power:1.03,  bitcoinPSLaneE_Scale:90,     bitcoinPSLaneE_BundleSize:100*100*100*100, // Webservers.  
    bitcoinPSLaneF_Power:1.03,  bitcoinPSLaneF_Scale:120,    bitcoinPSLaneF_BundleSize:100*100*100*100*100, // Office networks
    bitcoinPSLaneG_Power:1.03,  bitcoinPSLaneG_Scale:105,    bitcoinPSLaneG_BundleSize:100*100*100*100*100*100, // Cloud instances
    bitcoinPSLaneH_Power:1.03,  bitcoinPSLaneH_Scale:100,    bitcoinPSLaneH_BundleSize:100*100*100*100*100*100*100, // Server farms
    bitcoinPSLaneZ_Power:1.00,  bitcoinPSLaneZ_Scale:100,    bitcoinPSLaneZ_BundleSize:100*100, // Install server farms

    // Oven options
    ovenLaneA_Power:1.05, ovenLaneA_Scale:6000, ovenLaneA_BundleSize:1,
    ovenLaneB_Power:1.05, ovenLaneB_Scale:5000, ovenLaneB_BundleSize:20,
    ovenLaneC_Power:1.05, ovenLaneC_Scale:4000, ovenLaneC_BundleSize:500,
    
    // Ingredient costs
    ingredientLane_Power:1.0, ingredientLane_Scale:5,  
    ingredientLane0_BundleSize:1,
    ingredientLane1_BundleSize:10,
    ingredientLane2_BundleSize:100,
    ingredientLane3_BundleSize:1000,
    ingredientLane4_BundleSize:10000,
    ingredientLane5_BundleSize:100000,
    ingredientLane6_BundleSize:1000000,

    // Drone costs
    droneLane0Cost:Math.ceil(1.00*12000),
    droneLane1Cost:Math.ceil(0.99*120000),
    droneLane2Cost:Math.ceil(0.97*1200000),
    droneLane3Cost:Math.ceil(0.95*12000000),
    droneLane4Cost:Math.ceil(0.93*120000000),
    droneLane5Cost:Math.ceil(0.91*1200000000),

    // Oven info
    ingredientCookieYieldBase:10,
    ingredientPriceScaleBase:5,

    // Optimizations during REFINE
    // TO DO: Review bitcoin prices properly, check yield+price scales
    optProduction:{bitcoin:12.5*10000, yieldScale:1.0, priceScale:1.0},
    optSmaller:{bitcoin:28.0*10000, yieldScale:1.1, priceScale:1.0},
    optThinner:{bitcoin:54.0*10000, yieldScale:1.1, priceScale:1.0},
    optStack:{bitcoin:98*10000, yieldScale:1.1, priceScale:1.0},
    
    optRecipe:{bitcoin:250*10000, yieldScale:1.0, priceScale:1.0},
    optChocChips:{bitcoin:400*10000, yieldScale:1.0, priceScale:0.9},
    optVanilla:{bitcoin:1200*10000, yieldScale:1.0, priceScale:0.95},
    optSugar:{bitcoin:3500*10000, yieldScale:1.0, priceScale:0.95},
    optSalt:{bitcoin:7500*10000, yieldScale:1.0, priceScale:0.97},
    optMilk:{bitcoin:12500*10000, yieldScale:1.0, priceScale:0.9},
    optLeavening:{bitcoin:25000*10000, yieldScale:1.0, priceScale:0.90},
    optEggs:{bitcoin:50000*10000, yieldScale:1.0, priceScale:0.9},

    // Optimizations during OPTIMIZE
    // TO DO: Set up bitcoin prices properly, check yield+price scales
    optFlour0:{bitcoin:700*1000*10000, yieldScale:1.0, priceScale:1.0},
    optFlour1 :{bitcoin:1300*1000*10000, yieldScale:1.0, priceScale:1.0},
    optFlour2 :{bitcoin:2500*1000*10000, yieldScale:1.0, priceScale:1.0},
    optSugar0 :{bitcoin:5000*1000*10000, yieldScale:1.0, priceScale:1.0},
    optSugar1 :{bitcoin:10000*1000*10000, yieldScale:1.0, priceScale:1.0},
    optSugar2 :{bitcoin:20000*1000*10000, yieldScale:1.0, priceScale:1.0},
    optleavening0 :{bitcoin:40000*1000*10000, yieldScale:1.0, priceScale:1.0},
    optleavening1 :{bitcoin:70000*1000*10000, yieldScale:1.0, priceScale:1.0},
    optleavening2 :{bitcoin:130000*1000*10000, yieldScale:1.0, priceScale:1.0},
    optMilk0 :{bitcoin:250000*1000*10000, yieldScale:1.0, priceScale:1.0},
    optMilk1 :{bitcoin:500000*1000*10000, yieldScale:1.0, priceScale:1.0},
    optMilk2 :{bitcoin:1000000*1000*10000, yieldScale:1.0, priceScale:1.0},

    // ENDGAME item conversions
    // Repurpose arable land
    endLandHeader:"Arable land (sq km)",
    endLandInitialValue:31000000, // 31,000,000 sq km of arable land
    endLandBaseTime:1321, // 1000s for full conversion with one converter
    endLandCookies: 31000000 * 1000 * 1000 * 2, // 2 cookies per sq meter of land.  =6.2e13

    // Carbon resynthesis.  Convert carbon-based molecules directly to cookies.
    endCarbonHeader:"Carbon mass (kg)",
    endCarbonInitialValue:550*(1e9)*1000, // 550–560 billion tonnes
    endCarbonBaseTime:2131, // Take 2000 sec for full conversion
    endCarbonCookies: 550 * 1e9 * 1000 * 34, // 34 cookies per kg of carbon.  = 1.87e16

    // Resynthesis of water.  Convert water directly to cookies.
    endWaterHeader:"Water (m^3)",
    endWaterInitialValue:1386000000*(1000*1000*1000), // 1,386,000,000 cubic km
    endWaterBaseTime:4253, // Take 4000 sec for full conversion
    endWaterCookies: 1386000000 * (1000 * 1000 * 1000) * 29, // 29 cookies per kg of water.  = 4e19

    // General resynthesis.  Convert any matter directly to cookies.
    endGeneralHeader:"Earth mass (kg)",
    endGeneralInitialValue:5.9e24, // 5.972 × 10^24 kg
    endGeneralBaseTime:8513, // Take 8000 sec for full conversion
    endGeneralCookies: 5.9e24 * 28, // 28 cookies per kg of general mass  =1.6e26

    // Debug
    dbgAdditionalTick:0,
  };

var defaultFactDB="";

// Export the instruction list, so it can be referenced from index.js
exports.instructionList=instructionList;
exports.defaultVar=defaultVar;
exports.defaultFactDB=defaultFactDB;
